export default {
  eventMap: new Map(),
  on(eventType, cb) {
    let cbs = this.eventMap.get(eventType)
    if (cbs) {
      cbs.push(cb)
    } else {
      cbs = [cb]
    }
    if (cbs.length > 10) {
      // console.error('eventMap', this.eventMap)
    }
    this.eventMap.set(eventType, cbs)
  },
  once(eventType, cb) {
    this.eventMap.set(eventType, [cb])
  },
  off(eventType, fn) {
    const cbs = this.eventMap.has(eventType)
    if (cbs) {
      if (fn) {
        const cbs = this.eventMap.get(eventType)
        const rIndex = cbs.findIndex((v) => v === fn)
        if (rIndex > -1) {
          cbs.splice(rIndex, 1)
        }
        this.eventMap.set(eventType, cbs)
      } else {
        this.eventMap.delete(eventType)
      }
    }
  },
  offAll() {
    this.eventMap = new Map()
  },
  emit(eventType, val?) {
    // console.log('emit', eventType, val)
    const cbs = this.eventMap.get(eventType)
    if (cbs) {
      cbs.map((cb) => cb(val))
    }
  },
}

/**
 * 暂时定这两个事件
 */
export const EVENT_KEY = {
  // 单击事件
  SINGLE_CLICK: 'SINGLE_CLICK',
  // 双击事件
  DOUBLE_CLICK: 'DOUBLE_CLICK',
  // 播放某个视频
  ITEM_PLAY: 'ITEM_PLAY',
  // 声音
  MUTED_CHANGE: 'MUTED_CHANGE',

  //更新数据
  UPADATE_ITEM: 'UPADATE_ITEM',
}
