
// 商户编码
export const merchantCode = '001'

// 谷歌翻译 web 客户端 ID
export const GoogleTranslationClientID = '911433972844-mh652vj2h8pus58d73o8g1tmmaa56j47.apps.googleusercontent.com'
// 谷歌翻译 web 客户端秘钥
export const GoogleTranslationSecret = 'AIzaSyB9enMt9_DoWVrs0nCxMBSvk-tI4IWWpLE'

// 谷歌登录 webID
export const GoogleLoginClientID = '265694077361-m0f629ff4n6t6a2laqqld9duqf48vuep.apps.googleusercontent.com'

// 秘钥
export const GoogleLoginSecret = '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'

// facebook 登录应用编号
export const FacebookAppId = '1776708092859135'
// 秘钥
export const FacebookSecret = 'b77b46e19fe31e3470ca0b5a91d642a0'
