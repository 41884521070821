// App.vue
import { reactive, computed } from 'vue'
const keepAliveExcludes = reactive(new Set())

const keepAliveExcludesList = computed(() => Array.from(keepAliveExcludes))

export default function useKeepAlive() {
  const addAliveExcludes = (name) => keepAliveExcludes.add(name)
  const removeAliveExcludes = (name) => keepAliveExcludes.delete(name)

  return {
    keepAliveExcludesList,
    addAliveExcludes,
    removeAliveExcludes,
  }
}
