import { deleteTrendsVerifyWrapper } from '@/api/apiWarpper'
import { getTrendsDetails, deleteTrends, shieldTrends } from '@/api/trends'
import { merchantCode } from '@/config/index'
import { showToast } from 'vant'
import {useUserStore} from "@/stores";

export default function useTrends() {
  /**
   * 获取动态详情
   * @param trendsId
   * @returns
   */
  function getTrendsDetailsById(trendsId: string) {
    const userStore = useUserStore()
    const params = {
      merchantCode,
      trendsId,
      aesSecretKey: userStore.backendKey,
    }
    return getTrendsDetails(params).then((res) => {
      if (res?.resultCode === 406) {
        userStore.refreshKeys().then(() => {
          setTimeout(() => {
            // 密钥过期，重新触发页面请求
            getTrendsDetailsById(trendsId)
          }, 1000)
        });
        return
      }
      if (res?.resultCode === 1) {
        return res.data
      } else {
        res?.resultMsg && showToast(res.resultMsg)
      }
    })
  }

  /**
   * 删除动态信息
   * @param trendsId
   * @returns
   */
  function deleteTrendsById(trendsId: string) {
    // deleteTrends()
    return deleteTrendsVerifyWrapper(
      { deleteFn: deleteTrends, trendsId },
      merchantCode,
      trendsId,
    ).then((res) => {
      res?.resultMsg && showToast(res.resultMsg)
      if (res?.resultCode === 1) {
        return res.data || true
      }
    })
  }

  /**
   * 登入人 屏蔽/取消屏蔽 动态
   * @param toUserId 操作的用户
   * @param trendsId 被屏蔽的动态 id
   * @param type true 是屏蔽 false 是移除
   */
  function shield(toUserId: string, trendsId: string, type: boolean) {
    return shieldTrends({
      merchantCode,
      toUserId,
      trendsId,
      type: type ? 0 : 1, // 0 是屏蔽 1 是移除
    }).then((res) => {
      res?.resultMsg && showToast(res.resultMsg)
      if (res?.resultCode === 1) {
        return res.data || true
      }
    })
  }
  return {
    getTrendsDetailsById,
    deleteTrendsById,
    shield,
  }
}
