<template>
    <div ref="avatar" class="relative avatar" @click="onClick">
        <img v-if="loaded && src" ref="img" class="avatar_img" :src="src" alt="avatar" loading="lazy"
            @error="errorHandler" />
        <div v-else class="default-avatar"></div>

    </div>
</template>

<script lang="ts" setup>
/***此处只用于视频详情页 */
import { useUserStore } from "@/stores";
const userStore = useUserStore();

const props = defineProps({

    src: { type: String, default: '' },
    userId: { type: String || Number, default: "" }
});

const emit = defineEmits(["error", "back"]);

const avatar = ref<HTMLDivElement | null>(null);
const loaded = ref(true);

const errorHandler = (error) => {
    loaded.value = false;
    emit("error", error);
};

const onClick = (e) => {
    e.stopPropagation();
    if (userStore.userInfo?.userId == props.userId) {
        (window as any).$router.push({ path: '/mine' })
        return
    }
    (window as any).$router.push(`/user-info/${props.userId}`);
};

watch(
    () => props.src,
    (src) => {
        if (src) {
            loaded.value = true;
            avatar.value.setAttribute("src", src);
        }
    }
);


</script>

<style scoped>
.avatar {
    --avatar-size: 30px;
    --avatar-radius: 50%;
    display: inline-block;
    width: var(--avatar-size);
    height: var(--avatar-size);
    border-radius: var(--avatar-radius);
    background-color: var(--avatar-bgcolor);
    /* overflow: hidden; */
}

.avatar .avatar_img {
    width: 100%;
    height: 100%;
    border-radius: var(--avatar-radius);
    object-fit: cover;
}

.default-avatar {
    display: inline-block;
    background: url("../../assets/image/common/default-avatar.png") no-repeat;
    background-size: 100% 100%;
    border-radius: var(--avatar-radius);
    width: 100%;
    height: 100%;
}

.avatar-icon {
    @apply w-[21px] h-[21px] absolute right-[0px] bottom-[0] bg-[#fff] rounded-[50%];
    border: 2px solid var(--bg_color);
}
</style>
