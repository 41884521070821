import { useUserStore } from '@/stores'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
// import { baseUserStore } from '@/store/user'

export async function getDeviceId() {
  const fingerprint = await FingerprintJS.load().then(fp => fp.get())
  const userStore = useUserStore();
  console.log('fingerprint', fingerprint)
  userStore.setDeviceId(fingerprint.visitorId)
}
