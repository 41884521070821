/**
 * 动态加载本地图片
 * @param {string} path
 * @returns
 */
// import useLocale from '@/hook/useLocale'
import CryptoJS from 'crypto-js'
import i18n from '@/locals'
const $t = i18n.global.t
import { useUserStore } from '@/stores/index'
export function getAssetURL(path) {
  return new URL(`../assets/image/${path}`, import.meta.url).href
}

/**
 * 拼接远程图片或文件 url
 * @param {string} path
 * @returns
 */
export function getRemoreURL(path) {
  const userStore = useUserStore();
  const localKey = userStore.privateKey;
  const ivKey = localKey.slice(0, 16); // Take the first 16 characters of the local RSA-generated key

  if (!path) {
    return '';
  }
  if (path && (path.startsWith('http') || path.startsWith('https'))) {
    return path;
  }

  try {
    const result = CryptoJS.AES.decrypt(path, CryptoJS.enc.Utf8.parse(localKey), {
      iv: CryptoJS.enc.Utf8.parse(ivKey),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const finalPath = result.toString(CryptoJS.enc.Utf8);

    // If the decrypted result is empty or invalid, throw an exception
    if (!finalPath) {
      throw new Error('Decryption failed: Result is empty or invalid');
    }



    return finalPath;
  } catch (error) {
    return path;
  }
}

// 一小时毫秒数
const ONE_HOUR_TIME_LEN = 3600000
/**
 * 格式化时间
 * 时间显示规则：24 小时内显示：**分钟/小时前，
 * 昨天显示：昨天 20:22
 * 再之前的显示具体的日期，例如：今年：09-01；
 * 往年：2022-09-01
 * @param {number} timestamp
 */
export function formatShowTime(timestamp) {
  const now = new Date().getTime()
  const len = now - timestamp
  if (len < 24 * ONE_HOUR_TIME_LEN) {
    const hour = parseInt(len / ONE_HOUR_TIME_LEN)
    const min = parseInt((len / ONE_HOUR_TIME_LEN - hour) * 60)
    if (hour > 0) {
      return hour + $t('trends.hoursAgo')
    } else if (min > 0) {
      return min + $t('trends.minutesAgo')
    } else {
      return $t('trends.onlyJust')
    }
  } else if (len < 48 * ONE_HOUR_TIME_LEN) {
    const yesterday = new Date(timestamp)
    const hour = yesterday.getHours()
    const min = yesterday.getMinutes()
    return $t('trends.yesterday') + hour + ':' + fillZero(min)
  }
  const old = new Date(timestamp)
  // 今年
  if (old.getFullYear() === new Date().getFullYear()) {
    return fillZero(old.getMonth() + 1) + '-' + fillZero(old.getDate())
  } else {
    return (
      old.getFullYear() +
      '-' +
      fillZero(old.getMonth() + 1) +
      '-' +
      fillZero(old.getDate())
    )
  }
}

/**
 * 聊天消息发送时间
 * @param {*} timestamp
 * @returns
 * 当天：显示具体时间，例如 21:02
 * 昨日：显示昨天【时间】，例如 昨天 21:21
 * 在今年内，显示月日，例如 08-05
 * 在今年之前，显示年月日，例如 2022-09-01
 */
export function formatChatTime(timestamp) {
  const now = new Date().getTime()
  const len = now - timestamp
  const old = new Date(timestamp)
  if (isToday(timestamp)) {
    return fillZero(old.getHours()) + ':' + fillZero(old.getMinutes())
  } else if (len < 48 * ONE_HOUR_TIME_LEN) {
    const hour = old.getHours()
    const min = old.getMinutes()
    return $t('trends.yesterday') + hour + ':' + fillZero(min)
  } else if (new Date().getFullYear() === old.getFullYear()) {
    return fillZero(old.getMonth() + 1) + '-' + fillZero(old.getDate())
  } else {
    return (
      old.getFullYear() +
      '-' +
      fillZero(old.getMonth() + 1) +
      '-' +
      fillZero(old.getDate())
    )
  }
}

/**
 * 判断出入的时间戳是否是今天
 * @param {number} timestamp
 * @returns
 */
export function isToday(timestamp) {
  const date = new Date(timestamp)
  const currentDate = new Date()
  // 比较两个日期是否在同一天
  return (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() === currentDate.getDate()
  )
}

/**
 * 自动补 0
 * @param {number|string} val 要补 0 的数
 * @param {number} len 位数
 */
function fillZero(val, len = 2) {
  if (typeof val === 'string') {
    if (val.length < len) {
      return new Array(len - val.length).fill(0).join('') + val
    }
  } else if (typeof val === 'number') {
    const str = new String(val)
    if (str.length < len) {
      return new Array(len - str.length).fill(0).join('') + val
    }
  }
  return val
}
/**
 * 格式化秒数
 * @param {number} seconds 秒数
 * @returns {string} 格式化后的时间字符串 (hh:mm:ss)
 */
export function formatSec(seconds) {
  const fillZero = (num) => (num < 10 ? `0${num}` : num);

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  if (hours > 0) {
    return `${fillZero(hours)}:${fillZero(minutes)}:${fillZero(secs)}`;
  } else {
    return `${fillZero(minutes)}:${fillZero(secs)}`;
  }
}

/**
 * 格式化时间戳
 * @param {number} timestamp 时间戳
 * @param {string} format 格式化字符串
 */
export function formatTime(timestamp, format = 'yyyy-MM-dd HH:mm') {
  const time = new Date(timestamp)
  const year = time.getFullYear()
  const month = time.getMonth() + 1
  const day = time.getDate()
  const hour = time.getHours()
  const minute = time.getMinutes()
  if (format === 'yyyy-MM-dd HH:mm') {
    return `${year}-${fillZero(month)}-${fillZero(day)} ${fillZero(
      hour
    )}:${fillZero(minute)}`
  } else if (format === 'MM-dd') {
    return `${fillZero(month)}-${fillZero(day)}`
  }
  return `${year}-${fillZero(month)}-${fillZero(day)} ${fillZero(
    hour
  )}:${fillZero(minute)}`
}

/**
 * 将当前月份转为时间戳
 * @param {number} timestamp 时间戳
 * @param {string} format 格式化字符串
 */
export function formatToTimestamp(dateStr) {
  // 解析输入的日期字符串，例如 "2024-07"
  const [year, month] = dateStr.split('-').map(Number);
  // 创建一个 Date 对象
  const date = new Date(year, month - 1);
  // 获取时间戳
  const timestamp = date.getTime();
  return timestamp;
}


/**
 * 格式化剩余时间戳
 * @param {*} timestamp 时间戳
 */
export function formatCountTime(timestamp) {
  const end = new Date(timestamp)
  const now = new Date()
  const seconds = parseInt((end - now) / 1000)
  const mins = parseInt((seconds / 60) % 60)
  const hours = parseInt((seconds / 3600) % 24)
  const days = parseInt(seconds / (3600 * 24))
  let time = ''
  if (days) {
    time += `${days}${$t('common.day')}`
  }
  if (hours) {
    time += `${hours}${$t('common.hour')}`
  }
  if (mins) {
    time += `${mins}${$t('common.minute')}`
  }
  return time
}
