import { merchantCode, FacebookAppId } from '@/config/index'
import { userShare } from '@/api/user'


/**
 * 分享动态
 * @returns 
 */
export default function useShare() {

  const route = useRoute()

  /**
   * 用户分享到ZIM接口
   */
  function shareToZIM(data) {
    return userShare({
      merchantCode,
      ...data
    }).then(res => {
      res?.resultMsg && showToast(res.resultMsg)
      if (res?.resultCode === 1) {
        return res.data || true
      }
    })
  }

  /**
   * 分享到Facebook
   * @param shareUrl 分享的链接
   */
  function shareToFacebook(shareUrl: string) {
    // const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`
    const url = `https://www.facebook.com/dialog/feed?redirect_uri=https://www.facebook.com&app_id=${FacebookAppId}&link=${encodeURIComponent(shareUrl)}`
    window.open(url, '_blank')
  }

  /**
   * 分享到Telegram
   * @param shareUrl 分享的链接
   */
  function shareToTelegram(shareUrl: string) {
    const url = `https://t.me/share?url=${encodeURIComponent(shareUrl)}`
    window.open(url, '_blank')
  }

  // onActivated(() => {
  //   console.log('分享 onActivated ', route, this)
  // })

  return {
    shareToZIM,
    shareToFacebook,
    shareToTelegram,
  }
}
