import { trendsCount } from '@/api/trends'
import { useUserStore } from '@/stores'
import { storage } from '@/utils/storageUtil'

type trackTrends = {
  trendsId: string
  flag: number
  userId: string
}

//去掉缓存逻辑
export async function sendTrendsTrack(trackTrends: trackTrends) {
  // const userStore = useUserStore()

  // const cacheId = userStore?.userInfo?.userId || userStore.deviceId

  // if (
  //   trackTrends?.userId &&
  //   userStore?.userInfo?.userId &&
  //   trackTrends.userId === userStore.userInfo.userId // 如果该发帖用户是自己，则不记录
  // ) {
  //   return
  // }

  try {
    //   let viewedCahce: { [x: string]: boolean } = storage.get(cacheId)
    //   if (!viewedCahce) {
    //     viewedCahce = {}
    //     storage.set(cacheId, viewedCahce)
    //   }

    //   if (Reflect.has(viewedCahce, trackTrends.trendsId)) return
    //   Reflect.set(viewedCahce, trackTrends.trendsId, true)

    await trendsCount({
      trendsId: trackTrends?.trendsId,
      flag: trackTrends?.flag,
    })

    // storage.set(cacheId, viewedCahce)
  } catch (error) {
    console.log(error)
  }
}
