import { createI18n } from 'vue-i18n'
import zh from './zh.json'
import en from './en.json'
import hk from './hk.json'

const messages = {
  en,
  'zh-HK': hk,
  zh,
}

const language = (navigator.language || 'zh').toLocaleLowerCase() // 获取浏览器的语言
const i18n = createI18n({
  locale: localStorage.getItem('lang') || language.split('-')[0] || 'zh', // 首先从缓存里拿，没有的话就用浏览器语言
  fallbackLocale: 'zh', // 设置备用语言
  legacy: false, // 如果要支持 compositionAPI，此项必须设置为 fasle
  globalInjection: true, // 全局注册$t方法
  allowComposition: true, // 允许组合式 api
  messages,
})

export default i18n
