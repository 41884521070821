<template>
  <love-confirm-dialog :show="show" title="分享" @onCancel="onCancel" @onComfirm="doSubmit">
    <div class="px-20 w-full">
      <div v-if="shareConfig.type === ShareType.friends || shareConfig.type === ShareType.group" class="flex items-center mb-15">
        <love-avatar :src="getRemoreURL(shareConfig.headSculpture)" :size="34" class="mr-[7.5px] flex-shrink-0" />
        <span class="ml-[7.5px] text-regular text-lg font-w6 truncate">{{ shareConfig.nickname }}</span>
      </div>
      <template v-if="trendsInfo">
        <div class="flex mb-15">
          <love-avatar
            v-if="trendsInfo.flag === 1 && trendsInfo.resources && trendsInfo.resources.length"
            :src="getRemoreURL(trendsInfo.resources[0].url)"
            :size="70"
            :circle="false"
            class="mr-12 flex-shrink-0"
          />
          <love-avatar
            v-else
            :src="getRemoreURL(trendsInfo.headSculpture)"
            :size="70"
            :circle="false"
            class="mr-12 flex-shrink-0"
          />
          <div class="text-primary text-base flex-1 w-0">
            <p class="truncate">{{ trendsInfo.nickname }}</p>
            <p class="line-clamp-2">{{ trendsInfo.body }}</p>
          </div>
        </div>
      </template>
      <template v-else-if="userInfo">
        <div class="flex mb-15">
          <love-avatar :src="getRemoreURL(userInfo.headSculpture)" :size="70" :circle="false" class="mr-12 flex-shrink-0" />
          <div class="text-primary text-base flex-1 w-0">
            <p class="truncate">{{ userInfo.nickname }}</p>
            <p class="truncate">{{ userInfo.description }}</p>
          </div>
        </div>
      </template>
      <div class="mt-12 h-40 bg-secondary">
        <input v-model="data.input" placeholder="输入分享心得" class="h-full px-10 text-primary bg-secondary placeholder:text-disabled" />
      </div>
    </div>
  </love-confirm-dialog>
</template>

<script lang="ts" setup>
/**
 * 分享确认弹窗
 */

import { getRemoreURL } from '@/utils'
import useShare from '@/hook/useShare'
import { ShareType, ZIMConversationType } from '@/types/enums'
import useZimStore from '@/stores/zim'


const share = useShare()
const zimStore = useZimStore()

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  shareConfig: {
    type: Object,
    default: null
  },
  trendsInfo: {
    type: Object,
    default: null
  },
  userInfo: {
    type: Object,
    default: null
  }
})

const emit = defineEmits(['onClose', 'onCancel'])

const data = reactive({
  show: false,
  trendsInfo: null,
  userInfo: null,
  input: null,
  shareMessage: null,
})

const onCancel = () => {
  emit('onClose', false)
}

const doSubmit = () => {
  const params = {
    type: props.shareConfig.type,
    targetId: props.trendsInfo ? props.trendsInfo.trendsId : props.userInfo?.userId,
    shareText: data.input,
    subType: props.trendsInfo ? 102 : 101, // 消息类型101：分享主页；102：分享动态
    userId: null,
    roomId: null,
  }
  if (props.shareConfig.type === ShareType.friends) {
    params.userId = props.shareConfig?.userId
  } else if (props.shareConfig.type === ShareType.group) {
    params.roomId = props.shareConfig.roomId
  }

  console.log('分享', params, props.shareConfig)

  share.shareToZIM(params).then((res) => {
    if (res) {
      emit('onClose', true)
    }
  })
}


const init = () => {
  console.log('确认', props)
}

onMounted(() => {
  init()
})

</script>
