import { App, defineAsyncComponent } from 'vue'
import BasePage from '../layout/base/BasePage.vue'
import SvgIcon from '../SvgIcon/SvgIcon.vue'
import NavBar from '../NavBar/index.vue'

export default {
  install(app: App) {
    // 手动注册
    app.component('BasePage', BasePage)
    app.component('NavBar', NavBar)
    app.component('SvgIcon', SvgIcon)

    const components = import.meta.glob('./*/index.vue')
    // 遍历组件模块实现自动注册
    for (const [key, value] of Object.entries(components)) {
      const componentName = 'Love' + key.replace('./', '').split('/')[0]
      // console.log(componentName)
      // 通过 defineAsyncComponent 异步导入指定路径下的组件
      app.component(componentName, defineAsyncComponent(value))
    }
  }
}