<template>
  <svg aria-hidden="true" class="svg-icon" :fill="color" :width="width||size" :height="height||size">
    <use :href="symbolId" :fill="color" />
  </svg>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'SvgIcon',
  props: {
    prefix: {
      type: String,
      default: 'icon',
    },
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
    size: {
      type: String,
      default: '1em'
    },
    width: {
      type: String
    },
    height: {
      type: String
    }
  },
  setup(props) {
    const symbolId = computed(() => `#${props.prefix}-${props.name}`)
    return { symbolId }
  },
})
</script>

<style scoped>
.svg-icon {
  fill: currentColor;
  outline: none;
}
.svg-icon.default-font-size {
  font-size: 14px;
}
</style>
