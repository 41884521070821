export default function useVConsole() {
  const LOCAL_STORAGE_KEY_VCONSOLE = 'vconsole'
  const SCREAT_KEY = '91love'

  // 打开调试
  function openDebugConsole() {
    // @ts-ignore
    if (!window.VConsole) {
      ;(function (d, s, id) {
        let js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = 'https://unpkg.com/vconsole@latest/dist/vconsole.min.js'
        fjs.parentNode.insertBefore(js, fjs)
        js.onload = js.onreadystatechange = function () {
          // console.log("加载完成", window)
          // @ts-ignore
          new window.VConsole()

          js.onload = js.onreadystatechange = null
        }
      })(document, 'script', 'vconsole')
    }
  }

  // 关闭调试
  function closeDebugger() {
    // @ts-ignore
    if (window.VConsole) {
      const vconsole = document.getElementById('vconsole')
      if (vconsole) {
        // @ts-ignore
        window.VConsole = null
        vconsole.parentNode.removeChild(vconsole)
        // localStorage.removeItem(LOCAL_STORAGE_KEY_VCONSOLE)
        // window.location.reload()
      }
    }
  }

  function init() {
    const vconsole = localStorage.getItem(LOCAL_STORAGE_KEY_VCONSOLE)
    // 打开调试
    if (vconsole === SCREAT_KEY) {
      openDebugConsole()
    } else {
      closeDebugger()
    }
  }

  window.addEventListener('storage', () => {
    init()
  })

  init()

  return {
    openDebugConsole,
    closeDebugger,
  }
}
