<template>

    <div class="bottom-box">
        <!-- 试看结束节点 -->
        <div class="lock-overlay " v-if="unlockSingle">

            <div class="lock-icon">
                <div>
                    <img :src="oss_image_domain_address + 'common/lock-icon.svg'" class="m-auto mb-10 w-[24px]" />
                </div>
                <div>{{ t('translate.tips') }}</div>

            </div>
        </div>
        <!-- 购买节点 -->
        <div class="purchase-box" v-if="unlockSingle">
            <div class="content-box">
                <img class="video-cover" :src="item?.resources[0].videoCoverUrl" />
                <ChargeIcon class="charge-icon"></ChargeIcon>
                <div class="desc-box">
                    <div class="desc">{{ item?.compilationsVo?.title }}
                    </div>
                    <div class="mt-4 truncate point-text" v-if="item?.compilationsVo?.title">{{ t('create.countepisode',
                        {
                            count: item?.compilationsVo?.sort
                        }) }} | {{ item?.body }}</div>
                    <div class="point">{{ item?.price }} <span class="point-text">{{ t("v2.points") }}</span>
                    </div>
                </div>
                <van-icon name="cross" class="point-text" @click="unlockSingle = false" />
            </div>
            <div class="btn-box" v-if="item?.compilationsVo?.paySwitch">
                <div class="btn-item-2" @click="openRecharge">
                    <div class="cost-point">{{ item?.price }}<span>{{ t("v2.points") }}</span></div>
                    <div class="text-xs">{{ t("v2.unlock2") }}</div>
                </div>
                <div class="btn-item" @click="openRecharge">
                    <div class="cost-point">{{ item?.compilationsVo?.price || 0 }}<span>{{ t("v2.points")
                            }}</span>
                    </div>
                    <div class="text-xs">{{ t("v2.unlock3") }}</div>
                </div>
            </div>
            <div class="btn-box" v-else @click="openRecharge">
                <div class="btn-itself" v-if="item?.price > 0">{{ item?.price }}{{ t('v2.unlocktips') }}</div>
                <div class="btn-itself" v-else>{{ t("v2.unlock") }}</div>
            </div>
        </div>

        <!-- 用户信息 -->
        <div class="text-white text-[18px] font-bold  mb-[5px] user-box" @click.stop="toUserInfo(item)">
            <avatar :src="getRemoreURL(item?.headSculpture)" :userId="item?.userId" />
            <span class="user-name pingfang">{{ item?.account }}</span>
            <div v-if="!item?.yourself" class="follow-btn" @click.stop="toFollow(item)">{{
                getFollowText(item?.userFriends) }}</div>
        </div>
        <!-- 动态内容 -->
        <div class="flex items-center w-full">
            <div ref="textEllipsis" class="dynamic-box" style="line-height: 1.1;">
                <div :class="['retract-text', 'retract-text-' + lineNum]">
                    <span class="album-episodes" v-if="item?.compilationsVo?.sort">{{ t('create.countepisode', {
                        count:
                            item?.compilationsVo?.sort
                    }) }} | </span>
                    <span @click.stop="clickContent" v-html="translated ? translatedText : renderBody(item)"></span>
                    <span> &nbsp;&nbsp;{{ lineNum === 1 ? '' : t("trends.retract") }}</span>
                </div>

            </div>
        </div>
        <div v-if="lineNum === 3" class="text-white">

            <p v-if="translated" class="text-[13px] text-black font-semibold" style="text-align: left;"
                @click.stop="translated = false">
                {{ t("translate.original") }}
            </p>
            <div v-else class="text-[13px] text-black font-semibold" style="text-align: left;"
                @click="textTranslate(item)">
                {{ t("translate.show") }}
            </div>
        </div>
        <!-- 音频、地点、标记用户 -->
        <div v-if="item?.audioVo || item?.resources[0].trendsMarkUser.length > 0"
            class="overflow-x-scroll some text-white h-[25px] mt-[5px] flex">
            <!-- 音频 -->
            <div v-if="item?.audioVo" @click.stop="toAudio(item?.audioVo)"
                class="flex shrink-0 items-center h-[25px] rounded-[25px] bg-opacity w-[50%] mr-[6px] text-[12px]">
                <van-notice-bar class="w-full" color="var(--bg_color)" background="transparent" :left-icon="audioIcon">
                    {{ `${item?.audioVo?.account} · ${item?.audioVo?.audioFrequencyName}` }}
                </van-notice-bar>
            </div>
            <!-- 标记用户 -->
            <div @click="showMarkUser(item?.resources[0].trendsMarkUser, index)"
                v-if="item?.resources[0].trendsMarkUser.length > 0"
                class="flex shrink-0 max-w-[100px] items-center h-[25px] rounded-[25px] px-[7px] bg-opacity mr-[6px] text-[12px] truncate">
                <img src="../../assets/image/v2/mark_white.png" class="w-[10px] h-[11px] mr-[5px]">
                <div v-if="item?.resources[0].trendsMarkUser.length > 1">
                    {{ item?.resources[0].trendsMarkUser.length }}{{ t('v2.markUserCount') }}</div>
                <div v-else>{{ item?.resources[0].trendsMarkUser[0].account }}</div>
            </div>

            <!-- 地点 -->
            <div @click="toLocation(item?.locationVo)" v-if="item?.locationVo"
                class="flex shrink-0  items-center h-[25px] rounded-[25px] px-[7px] bg-opacity text-[12px] truncate">
                <img src="../../assets/image/v2/location_white.png" class=" h-[11px] mr-[5px]">
                <div>{{ item?.locationVo?.name }}</div>
            </div>
        </div>
        <div class="albums-info-2" v-if="item?.compilationsVo" @click="toAlbums">
            <div class="flex w-3/4">
                <img class="w-16 mr-5" :src="oss_image_domain_address + 'home/album.svg'" alt="" />
                <div class="truncate text-ellipsis">{{ item?.compilationsVo?.title }} · {{
                    t('create.updates') }}{{ albumInfo?.trendsNum
                    }}{{
                        t('create.episode') }}</div>
            </div>
            <van-icon name="arrow-up" />
        </div>
    </div>
</template>
<script setup>
import { oss_image_domain_address } from "@/constants/oss";
import { useUserStore } from '@/stores/index'
import useUserFollow from '@/hook/useUserFollow'
import { getTranslate } from '@/api/index'
import { ForwardType, } from "@/types/enums";
import { getRemoreURL } from '@/utils/index'
import avatar from "./AvatarInfo.vue"
const t = window.$t;
const props = defineProps({
    /**
  * 数据项
  */
    item: {
        type: Object,
        default: () => {
            return {}
        }
    },
    lineNum: {
        type: Number,
        default: 1
    },
    //试看结束
    tryPlayOverTime: {
        type: Boolean,
        default: false
    }
})

const unlockSingle = ref(props.tryPlayOverTime)
watch(() => props.tryPlayOverTime, (newvalue) => {
    console.log("tryPlayOverTime", newvalue)
    unlockSingle.value = newvalue;
})
const emit = defineEmits(['openRecharge', 'toAlbums', 'showMarkUser', 'changelineNum'])

import {

    checkAlbum,

} from '@/api/user'
// 合集
const albumInfo = ref([]);
// 获取合集
const getAlbumList = () => {
    const params = {
        compilationsId: props.item?.compilationsVo?.compilationsId || 0,
    };
    checkAlbum(params).then((res) => {
        if (res?.data) {
            // 合集信息
            albumInfo.value = res?.data;

        }


    });
}

onMounted(() => {
    getAlbumList()
})
// 充值弹窗
const openRecharge = () => {
    emit("openRecharge")
}

/**
 * 用户主页
 * @param item 
 */
const toUserInfo = (item) => {
    if (userStore.userInfo?.userId == item?.userId) {
        window.$router.push({ path: '/mine' })
        return
    }
    window.$router.push(`/user-info/${item.userId}`)
}
const userStore = useUserStore()

// 未登录的操作，直接跳转到登录页面
const checkLogin = () => {
    if (userStore.isLogin) {
        return true
    } else {
        window.$router.push({
            name: 'login',
            query: {
                redirect: window.$route.fullPath,
                ...window.$route.query
            }
        })
        return false
    }
}



const userFollow = useUserFollow()

// 临时关注字段
const getFollowText = (userFriends) => {
    if (userFriends == -1 || userFriends == 0) {
        return t('user.follow')
    } else if (userFriends == 1 || userFriends == 2) {
        return t('user.Followed')
    }
}

/**
 * 关注
 */
const flag = ref(false)
const toFollow = (item) => {
    if (flag.value) return
    flag.value = true
    if (!checkLogin()) {
        flag.value = false
        return
    }
    if (item.userFriends == -1 || item?.userFriends == 0) {
        userFollow.follow(item?.userId, true)
            .then((res) => {

                item.userFriends = res?.status
            }).finally(() => {
                flag.value = false
                closeToast()
            })
    } else {
        userFollow.follow(item?.userId, false).then((res) => {
            item.userFriends = res?.status

        }).finally(() => {
            flag.value = false
            closeToast()
        })
    }
}

/** 点击内容 */
const clickContent = (e) => {
    if (e.target.nodeName == "A") {
        if (e.target) {
            window.$router.push({ path: '/topic', query: { name: `${e.target?.dataset.subjectName}`, id: e.target?.dataset.subjectId, bottomIndex: 0 } })
        }
    } else {

        const isFastForward = props.item.forward && props.item.forwardType === ForwardType.quick;

        const body = isFastForward ? props.item.trendsForwardVo?.body : props.item.body;
        if (body && body.length > 20) {
            // 超过 20 个字才展开 todo 
            emit("changelineNum", props.lineNum === 1 ? 3 : 1)
        }


    }
}

// 翻译
const translatedText = ref(props.item?.translatedText)
const translated = ref(false)
const textTranslate = (item) => {
    translated.value = !translated.value
    getTranslate({
        text: item?.body,
        targetLang: userStore.translateLanguage
    }).then(res => {
        if (res?.resultCode === 1) {
            translatedText.value = res.data.translatedText || ''
        } else {
            res?.resultMsg && showToast(res.resultMsg)
        }
    })
}

// 话题、@用户 文本替换处理 这里是从 DynamicState 复制过来的，
const renderBody = (item) => {
    // 这个字段，是从 FirendDynamicState 参考到的
    const isFastForward = item.forward && item.forwardType === ForwardType.quick;

    const body = isFastForward ? item.trendsForwardVo?.body : item.body;
    if (body) {
        let htmlStr = body;
        let subjectMap,
            mentionUserVos = null;
        if (isFastForward) {

            subjectMap = item.trendsForwardVo.subjectMap;
            mentionUserVos = item.trendsForwardVo.mentionUserVos;
        } else {
            subjectMap = item.subjectMap;
            mentionUserVos = item.mentionUserVos;
        }
        if (subjectMap) {
            // 话题
            const regex = new RegExp(Object.values(subjectMap).join("|"), "g");
            htmlStr = body.replace(regex, function (match) {
                const obj = Object.keys(subjectMap).find((k) => {
                    return match === subjectMap[k];
                });
                if (obj) {
                    return `<a data-subject-id="${obj}" data-subject-name="${match}" style="color: white">${match}</a>`;
                } else {
                    return `<a>${match}</a>`;
                }
            });
        }
        if (mentionUserVos) {
            // @用户高亮
            const regex = new RegExp(mentionUserVos.map((it) => "@" + it.account).join("|"), "g");
            htmlStr = htmlStr.replace(regex, function (match) {
                const obj = mentionUserVos.find((it) => "@" + it.account === match);
                if (obj) {
                    return `<a data-user-id="${obj.userId}" data-nickname="${obj.account}"  style="color: white">${match}</a>`;
                } else {
                    return `<a  style="color: white">${match}</a>`;
                }
            });
        }
        return htmlStr;
    }
    return body;
};

// 合集
const toAlbums = (item) => {
    emit("toAlbums", item)
}

// 显示标记用户
const showMarkUser = (list) => {
    emit("showMarkUser", list)

}

/** 前往地点详情页 */
const toLocation = (info) => {

    if (info) {
        window.$router.push({ path: '/position', query: { id: info.locationId } })
    }
}

// 前往音频页
const toAudio = (item) => {
    try {
        if (!item) {
            return showToast('暂未获取到音频信息')
        }
        const audioInfo = toRaw(item)
        window.$router.push({ path: '/audio', query: { id: audioInfo.audioFrequencyId }, state: { audioInfo } })
    } catch (e) {
        console.log("error", e)
    }

}
</script>
<style scoped>
.bottom-box {
    @apply absolute bottom-[8px] left-10 right-[10px];
    z-index: 100;


}

.user-box {
    display: flex;
    align-items: center;
}

.user-name {
    /* max-width:  */
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    margin-right: 8px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.follow-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 23px;
    border: 1px solid var(--card_bg_color_07);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    padding: 0 6px;
}

.white-like {
    width: v-bind("likeWidth");
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    transition: 0.2s;
}

.tabbar {
    display: flex;
    width: 100%;
    height: 46px;
    background-color: var(--text_color_01);
    position: fixed;
    bottom: 0;
    left: 0;
}

.some {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.some::-webkit-scrollbar {
    display: none;
}

.bg-opacity {
    background: rgba(53, 53, 53, 0.5);
}

.bottom-box .lock-overlay {
    bottom: 300px;
    height: 100px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    opacity: 0.6;


}

.lock-icon {
    @apply absolute z-50 w-full top-[45%] text-center;
    color: var(--bg_color);
}

.video-cover {
    @apply rounded-lg;
}

.charge-icon {
    @apply absolute left-12 top-12;
}

.purchase-box {
    background: rgba(38, 38, 38, 1);
    @apply absolute bottom-[80px] z-50 p-10 box-border rounded-lg w-[87%];

    .content-box {
        @apply flex justify-between;
        color: var(--bg_color);

        .desc-box {
            @apply w-2/3 mx-8 flex flex-col justify-between;
        }

        .desc {
            @apply text-base truncate;
            text-align: left;
        }

        .point {
            @apply text-sm mt-auto;
            text-align: left;
        }

        .point-text {
            @apply text-sm;
            color: var(--text_color_03);
            text-align: left;
        }

        img {
            @apply w-[70px] h-[70px];
        }
    }

    .btn-box {
        @apply flex justify-between mt-10;
        color: var(--bg_color);

        .btn-itself {
            @apply h-[42px] w-full flex justify-center items-center rounded-lg;
            background: var(--brand_color);
        }

        .btn-item {
            @apply w-1/2 h-[42px] text-sm text-center rounded-lg pt-4;
            background: var(--brand_color);
        }

        .btn-item-2 {
            @apply w-1/2 h-[42px] text-sm text-center rounded-lg pt-4 mr-10;
            background: var(--card_bg_color_10);
        }

        .cost-point {
            @apply mb-2;

            font-size: 14px;
            font-weight: 600;
        }
    }
}

::v-deep .van-notice-bar {
    @apply py-0;
    height: 25px;
}

::v-deep .van-notice-bar .van-notice-bar__left-icon {
    height: 16px;
}

.payment-item {
    @apply ml-10 text-sm rounded h-[18px] flex items-center p-5 box-border w-auto justify-center whitespace-nowrap;
    background: var(--auxiliary_color_08);
}

.payment-sub {
    @apply text-sm mt-5;
    color: var(--text_color_02);
}

.selected-item {
    @apply rounded-lg;
    border: 1px solid var(--brand_color);
}

.main-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.float-icon {
    bottom: -3px;
    box-sizing: border-box;
    height: 16px;
    padding: .53333vw;
    position: absolute;
    right: -3px;
    width: 16px;
    border-top-left-radius: 50%;
    border: 1px solid white;
    color: white;
    z-index: 11;
}

.album-episodes {
    @apply text-sm mr-2;
    min-width: 42px;
    color: var(--bg_color);
}

.scroll-slide {
    @apply overflow-scroll mx-[-8px];
    height: 72vw;
}

.dynamic-box {
    @apply text-white text-[13px] w-[90%];
}

.retract-text {
    text-align: left;
    display: -webkit-box;
    /* 限制为三行 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
}

.retract-text-1 {
    -webkit-line-clamp: 1;
}

.retract-text-3 {
    -webkit-line-clamp: 3;
}

.albums-info-2 {
    @apply flex w-full justify-between mt-5 items-center h-[42px] rounded-lg px-10 text-base;
    background: var(--auxiliary_color_10);
    color: var(--bg_color);
}
</style>