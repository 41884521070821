import { getUserRelations } from '@/api/trends'
import { merchantCode } from '@/config/index'

/**
 * 获取用户（分享，@用户 关注或好友
 * @returns
 */
export default function useUserRelations() {
  /**
   * 获取好友
   * @param name
   * type 查询类型 0 所有，1 好友，2 群聊 不传默认好友 群聊一起
   * @returns
   */
  function getFriends(name?: string) {
    return getUserRelations({
      merchantCode,
      name,
      // type: 1
    }).then((res) => {
      if (res?.resultCode === 1) {
        return res.data
      } else {
        res?.resultMsg && showToast(res.resultMsg)
      }
    })
  }

  /**
   * 获取群聊
   * @param name
   * @returns
   */
  function getGroups(name?: string) {
    return getUserRelations({
      merchantCode,
      name,
      type: 2,
    }).then((res) => {
      if (res?.resultCode === 1) {
        return res.data
      } else {
        res?.resultMsg && showToast(res.resultMsg)
      }
    })
  }

  /**
   * 获取所有好友、群聊
   * @param name
   * @returns
   */
  function getAll(name?: string) {
    return getUserRelations({
      merchantCode,
      name,
      type: 0,
    }).then((res) => {
      if (res?.resultCode === 1) {
        return res.data
      } else {
        res?.resultMsg && showToast(res.resultMsg)
      }
    })
  }

  return {
    getFriends,
    getGroups,
    getAll,
  }
}
