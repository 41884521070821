import { userFollow } from '@/api/user'
import { merchantCode } from '@/config/index'

/**
 * 用户关注
 * @returns 
 */
export default function useUserFollow() {

  
  const followStatusMap = {
    '-1': '关注',
    '0': '回关',
    '1': '已关注',
    '2': '互相关注'
  }

  /**
   * 用户关注、取消关注
   * @param toUserId 操作的用户
   * @param type true是关注 false是取消关注
   */
  function follow(toUserId: string, type: boolean) {
    return userFollow({
      merchantCode,
      toUserId,
      type: type ? 0 : 1 // 操作类型 0关注 1取消关注
    }).then((res) => {
      res?.resultMsg && showToast(res.resultMsg)
      if (res?.resultCode === 1) {
        return res.data || true
      }
    })
  }

  return {
    followStatusMap,
    follow
  }
}
