<template>
	<router-view v-slot="{ Component, route }">
		<transition name="moveCartoon" appear>
			<div :style="`height:${windowInnerHeight}px`">
				<keep-alive :exclude="keepAliveExcludesList">
					<component v-if="route.meta.keepAlive" :is="Component" :key="route.name" />
				</keep-alive>
				<component v-if="!route.meta.keepAlive" :is="Component" />
			</div>
		</transition>
	</router-view>
	<SharePopup :show="shareStore.showSharePopup" :type="shareStore.shareContentType"
		:trends-info="shareStore.trendsInfo" :isUserInfo="shareStore.isUserInfo" :user-info="shareStore.userInfo"
		@onClose="shareStore.showSharePopup = false" @onItemClick="shareStore.shareTo"
		@onOtherAction="shareStore.otherActionCallback" />
	<ShareConfirmDialog v-if="shareStore.showShareConfirmDialog" :show="shareStore.showShareConfirmDialog"
		:shareConfig="shareStore.shareConfig" :trendsInfo="shareStore.trendsInfo" :user-info="shareStore.userInfo"
		@onClose="shareStore.onShareResult" />
	<SearchV2 :show="shareStore.showSearchPopup" @on-close="shareStore.closeSearch" />
	<love-notify v-if="zimStore.showNotify.show" />
</template>

<script setup>
import { useUserStore } from "@/stores/index";
import { useShareStore } from "@/stores/share";
import useZimStore from "@/stores/zim";
import useVConsole from "@/hook/useVConsole";
import { envIsProd } from '@/config/env'
import useKeepAlive from "./hook/useKeepAlive";
import useLocale from '@/hook/useLocale'
localStorage.setItem('vconsole', '91love')// 开启 VConsole 调试
const hasDevConsole = localStorage.getItem('love_dev_console');
if (!envIsProd || (hasDevConsole && hasDevConsole === 'has')) {
	useVConsole();
}
const userStore = useUserStore();
const shareStore = useShareStore();
const zimStore = useZimStore();
const { keepAliveExcludesList } = useKeepAlive(); const { muteState } = storeToRefs(userStore)

const windowInnerHeight = computed(() => window.innerHeight);

console.log('entry')
const { i18n: { t } } = useLocale()

window.$t = t;

const router = useRouter()
const route = useRoute()

// 虚拟列表那里无法直接使用
window.$router = router
window.$route = route
// 监听网络变化
function updateOnline() {
	if (!navigator.onLine) {
		showToast(t("offline"))
	} else {
		showToast(t("online"))
	}
}
window.addEventListener('online', updateOnline);
window.addEventListener('offline', updateOnline);

// 根据公钥生成私钥
userStore.refreshKeys()
muteState.value = true // 初始化静音状态
// 页面刷新后，如果已经登录状态，需要重新登录 zego
if (userStore.isLogin) {
	userStore.zegoLogin();
}
</script>
