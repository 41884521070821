import { get, post } from '@/utils/httpRequest.ts'

// 查询全球地区
export function getAddress(parentId: number = 0) {
  return get('/globalArea/getAddress?parentId=' + parentId)
}

// 定位地区
export function positioningCountry(loginLogLatitude, loginLogLongitude) {
  return get(
    `/globalArea/positioningCountry?loginLogLatitude=${loginLogLatitude}&loginLogLongitude=${loginLogLongitude}`,
  )
}

// 定位地区
export function ipArea() {
  return get(`/user/ipArea`)
}

// 文件下载
export function downloadFile(data) {
  return get('/common/download', data)
}

// 本地资源通用下载
export function downloadResource(data) {
  return get('/common/download/resource', data)
}

// 文件上传
export function uploadFile(data) {
  return post('/common/upload', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

// 多文件上传
export function uploadFiles(data) {
  return post('/common/uploads', data)
}

// 获取举报类型
export function getReportType() {
  return get('/common/getReportType')
}

// 获取禁言类型
export function getProhibitionType() {
  return get('/common/getProhibitionType')
}

// 谷歌翻译
export function getTranslate(data) {
  return post('/translate/getTranslate', data)
}

// 字段类型
export function getDictByType(type) {
  return get('/common/dictData', { type })
}
