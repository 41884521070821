<template>
	<div class="w-full flex items-center pt-[10px] pb-[10px]">
		<div class="flex items-center w-full">
			<love-avatar :size="avatarSize" :src="getRemoreURL(item.headSculpture || null)" class="flex-shrink-0"
				:userId="item[userIdKey]" :clickAble="clickAble" />
			<div class="flex-1 w-0 text-[13px] text-black ml-[9px]" @click="clickNotice">
				<template v-if="dynamicaStateType == 'dynamicaState'">
					<div class="float-left font-medium"><span class="font-semibold ">{{ item[nameKey] }}</span> {{
						item.noticeTitle }}{{ (item.noticeType == 2 || item.noticeType == 2) ? item.noticeContent : ''
						}}</div>
					<span class="font-normal text-[#6D6D6D] ml-[6px]">
						{{ dayjs(item.noticeTime).locale("zh-cn").fromNow() }}</span>
				</template>
				<template v-else-if="dynamicaStateType == 'userSignature'">
					<div class="font-semibold " :class="{
						'overflow-hidden': nameOverfollowNum == 1,
						'text-ellipsis': nameOverfollowNum == 1,
						'whitespace-nowrap': nameOverfollowNum == 1,
					}" @click="toUserInfo">
						{{ item[nameKey] }}
					</div>
					<div class="text-[12px] text-[#999999]">
						{{ item[nameKey2] }}
<!--            {{t('user.recommend') }}-->
					</div>
					<div class="font-normal text-[11px] text-[#999999]" v-if="item.userSignature">
						userSignature
					</div>
				</template>
			</div>
		</div>
		<!-- 关注按钮 -->
		<div class="ml-[11px] h-[29px] flex items-center" v-if="isShowAttention">
			<template v-if="!isAttention">
				<div class="flex items-center h-full">
					<button class="h-full text-white text-[13px] bg-[#0094F4] rounded-[7px]" :class="{
						'w-[65px]': isShowDeleteBtn || littleBtn,
						'w-[93px]': !isShowDeleteBtn && !littleBtn,
					}" @click.stop="toFollow(item)">
						{{ item.status == -1 ? t("user.follow") : t('user.ReturnFollow') }}
					</button>
				</div>
			</template>
			<button v-else class="w-[93px] h-full text-black text-[13px] bg-[#EFEFEF] rounded-[7px] font-semibold"
				:class="{
					'w-[65px]': littleBtn,
					'w-[93px]': !littleBtn,
				}" @click.stop="toFollow(item)">
				{{ item.status == 1 ? t("user.Followed") : t('user.mutualFollow') }}
			</button>
			<div @click="handleDelete(item)" class="w-[21px] h-full flex items-center justify-center"
				v-if="isShowDeleteBtn && !isAttention">
				<img class="w-[8px] h-[8px]" src="@/assets/image/home/delete_black.png" alt="删除" />
			</div>
		</div>
		<!-- 动态的封面图 -->
		<div class="ml-[11px] w-[43px] h-[43px] rounded-[7px] overflow-hidden flex-shrink-0"
			v-if="item.trendsImg && isShowImg">
			<img class="w-full h-full" :src="getRemoreURL(item.trendsImg)" />
		</div>
		<!-- 选择按钮 -->
		<div v-if="isShowSelect" class="w-[40px] h-full flex justify-end items-center" @click="onSelect(item)">
			<img class="w-[21px] h-[21px]" src="@/assets/image/home/selection_icon.png" v-if="isSelect" />
			<div class="unselect-user" v-else></div>
		</div>
	</div>
</template>
<script setup lang="ts">
import useUserFollow from "@/hook/useUserFollow";
import useLocale from "@/hook/useLocale";
import { getRemoreURL } from "@/utils/index";
import { getTrendsVoInfo } from '@/api/trends'
import dayjs from 'dayjs'
import "dayjs/locale/zh-cn"
import relativeTime from 'dayjs/plugin/relativeTime'
import { useUserStore } from "@/stores/index";

const userStore = useUserStore();

dayjs.extend(relativeTime)
const {
	i18n: { t },
	changeLocale,
} = useLocale();
const data = reactive({
	loading: false,
	show: false,
})
const route = useRoute();
const router = useRouter();
const userFollow = useUserFollow();
const props = defineProps({
	isshowDelete: { type: Boolean, default: false },
	isShowImg: { type: Boolean, default: false },
	item: {
		type: Object,
		default: () => ({}),
	},
	littleBtn: {
		type: Boolean,
		default: false,
	},
	avatarSize: {
		type: Number,
		default: 38,
	},
	dynamicaStateType: {
		type: String,
		default: "userSignature",
	},
	isShowAttentionBtn: { type: Boolean, default: false },
	nameKey: {
		type: String,
		default: "nickname",
	},
	nameKey2: {
		type: String,
		default: "",
	},
	nameOverfollowNum: {
		type: Number,
		default: 1,
	},
	isShowSelect: {
		type: Boolean,
		default: false,
	},
	isSelect: {
		type: Boolean,
		default: false,
	},
	userIdKey: {
		type: String,
		default: "userId",
	},
	clickAble: {
		type: Boolean,
		default: true,
	},
});
const emit = defineEmits(["on-select", "on-delete", "on-update"]);
const onSelect = (row) => {
	emit("on-select", row);
};
/** 点击选项 */
const clickNotice = () => {
	if (props.item.visibleStatus == 1) {
		return showToast(t('v2.cantSee'))
	}
	if (props.item.noticeType == 1) {
		toUserInfo()
	}
	if (props.item.trendsFlag == 1) {
		getTrendsVoInfo({ trendsId: props.item.targetId,
      aesSecretKey: userStore.backendKey }).then(res => {
			const list = []
			list.push(res.data)
			if (props.item.noticeType == '2' || props.item.noticeType == '5' || props.item.noticeType == 6) {
				console.log(props.item)
				router.push({
					path: `/trend-detail/${props.item.targetId}`,
				})
			} else {
        userStore.setTrendsInfo(list)
				router.push({ path: '/trends-list', query: { type: 'search', page: 1, index: 0 } })
			}
		})
	} else if (props.item.trendsFlag == 2) {
		if (props.item.noticeType == '2' || props.item.noticeType == '5') {
			router.push({ path: '/video-detail', query: { trendsId: props.item.targetId, reply: 1 } })
		} else {
			router.push({ path: '/video-detail', query: { trendsId: props.item.targetId } })
		}
	}
}
// 是否展示删除按钮
const isShowDeleteBtn = computed(() => {
	if (props.isShowAttentionBtn) {
		return !props.item.isAttention && props.isshowDelete;
	} else {
		return props.isshowDelete;
	}
});
/** 处理删除 */
const handleDelete = (item) => {
	emit('on-delete', item)
}
// 是否展示关注按钮
const isShowAttention = computed(() => {
	return !props.item.yourself && props.isShowAttentionBtn;
});

const isAttention = computed((item) => {
	return props.item.status === 1 || props.item.status === 2;
});
// 关注
const toFollow = (item) => {
	if (data.loading) return
	data.loading = true
	// console.log(item)
	if (item.status == -1 || item.status == 0) { // 没关注
		userFollow.follow(item.userId, true).then((res) => {
			if (res) {
				showToast(t("toast.followSuccess"));
				data.loading = false
				// 对方未关注 -1 对方已关注:0 我已关注：1 互相关注：2
        emit('on-update', true)
				props.item.status = res.status;
			}
		}).finally(() => {
			closeToast()
		})
	} else if (item.status == 1 || item.status == 2) { // 已关注
		userFollow.follow(item.userId, false).then((res) => {
			if (res) {
				showToast(t("common.success"));
				data.loading = false
				// 对方未关注 -1 对方已关注:0 我已关注：1 互相关注：2
        emit('on-update', true)
				props.item.status = res.status;
			}
		}).finally(() => {
			closeToast()
		})
	}
};

// 用户主页
const toUserInfo = () => {
	const userId = props.item[props.userIdKey];
	if (userId && props.clickAble) {
		if (userStore.userInfo?.userId == userId) {
			router.push({ path: '/mine' })
			return
		}
		router.push(`/user-info/${userId}`);
	}
};
onMounted(() => {
	console.log()
})
</script>
<style lang="postcss" scoped>
.unselect-user {
	@apply w-[21px] h-[21px] border-solid rounded-[50%];

	border: 1px solid var(--icon_color_03);
}
</style>
