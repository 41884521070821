<template>
  <div class="fixed flex flex-col w-screen" :class="{ 'gradient-bg': withBg }" :style="`height:${windowInnerHeight}px`">
    <header class="flex-shrink-0" v-if="hideVal !== '1'">
      <slot name="header">
        <nav-bar :title="title" :showBack="showBack" :leftIcon="leftIcon" :leftText="leftText" :rightText="rightText"
          :rightIcon="rightIcon" :onClickLeft="onBeforeBack" @onClickRightText="onClickRightText"
          :showBorder="showBorder" :rightTextColor="rightTextColor">
          <slot v-if="!title" name="title-text"></slot>
          <template v-if="!rightText" #right>
            <slot name="title-right"></slot>
          </template>
          <template v-if="showRightIcon" #right>
            <slot name="title-right-icon">
              <div class="flex items-center h-full px-10" @click="onClickRightIcon">
                <img :src="getAssetURL(rightIcon)" class="h-3 w-15" />
              </div>
            </slot>
          </template>
          <template v-if="showLanguage" #right>
            <slot name="title-right-icon">
              <div class="language-box">
                <div class="language-text" @click="toggleDropdown">
                  <span>{{ getLang(currentItem) }}</span>
                  <van-icon class="switch-icon" name="arrow-down" />
                </div>
                <div v-if="dropdownVisible" class="dropdown-menu">
                  <div class="dropdown-item" v-for="(item, index) in langList" @click="changeLanguage(item)">
                    {{ item.name }}</div>
                </div>
              </div>
            </slot>
          </template>
        </nav-bar>
      </slot>
    </header>
    <main
        class="relative flex flex-col h-0 overflow-auto"
        :style="showHeight ? 'height: 100%' : 'height: calc(100% - 90px)'"
    >
      <slot></slot>
    </main>
    <footer class="flex-shrink-0">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import { getAssetURL } from '@/utils/index'

import { ref, computed, onMounted } from 'vue'
import { userSetting } from "@/api/user";
import useLocale from "@/hook/useLocale";
import { useUserStore } from "@/stores";

const windowInnerHeight = computed(() => window.innerHeight)

const langList = computed(() => [
  { name: '简体中文', value: 'zh', key: 'setting.chinese' },
  { name: 'English', value: 'en', key: 'setting.english' },
  { name: '繁体中文', value: 'zh-HK', key: 'setting.cantonese' }
])
const props = defineProps({
  title: { type: String, default: '' },
  withBg: { type: Boolean, default: false },
  showHeight: { type: Boolean, default: false },
  showBack: { type: Boolean, default: true },
  leftIcon: { type: String, default: 'common/icon-back.png' },
  leftText: { type: String, default: null },
  onBeforeBack: { type: Function, default: null },
  showRightIcon: { type: Boolean, default: false },
  showLanguage: { type: Boolean, default: false },
  rightIcon: { type: String, default: 'common/icon-more.png' },
  rightText: { type: String, default: null },
  showBorder: { type: Boolean, default: false },
  rightTextColor: { type: String, default: '#000' },
})
const route = useRoute()
const { i18n: { t }, changeLocale } = useLocale()
const emit = defineEmits(['onClickRightText', 'onClickRightIcon'])

const onClickRightText = () => emit('onClickRightText')

const userStore = useUserStore()
const locale = useLocale();
const menuList = ref([])
const dropdownVisible = ref(false)
const currentLanguage = ref('')
const currentItem = ref('')
const hideVal = ref(null)

onMounted(() => {
  const { hideNav } = route.query;
  hideVal.value = hideNav
})
// watch(
//     () => props.datas,
//     (v) => {
//       // console.log(v,'传进图片的之前的数据')
//     }
// )
const toggleDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value
}

/** 返回对应语言 */
const getLang = (v) => {
  for (const item of langList.value) {
    if (item.value == v) {
      return item.name
    }
  }
}
const getLangType = (v) => {
  for (const item of langList.value) {
    if (item.value == v) {
      return item.value
    }
  }
}
// 根据 localStorage 获取当前语言
const getCurrentLanguage = () => {
  const lang = localStorage.getItem('lang') || 'zh'
  return langList.value.find(item => item.value === lang)
}
// 初始化当前语言
const initializeLanguage = () => {
  const currentLang = getCurrentLanguage()
  if (currentLang) {
    currentLanguage.value = currentLang.name
    currentItem.value = currentLang.value
  }
}
const changeLanguage = (v) => {
  currentLanguage.value = v.name
  currentItem.value = v.value
  if (currentLanguage.value == menuList.value[0].name) {
    menuList.value[0].value = v.value
  } else {
    menuList.value[1].value = v.value
  }
  const params = {
    language: menuList.value[0].value,
    translateLanguage: menuList.value[1].value
  }
  console.log('v.value=>',v.value)
  changeLocale(v.value)
  locale.changeLocale(v.value);
  showToast(t('toast.modifySuccess'))
  dropdownVisible.value = false
}
watchEffect(() => {
  menuList.value = [
    { name: t('setting.appLanguageSetting'), value: userStore.language },
    { name: t('setting.translateLanguageSetting'), value: userStore.translateLanguage }
  ]
})
// 初始化语言
initializeLanguage()
const onClickRightIcon = () => emit('onClickRightIcon')
</script>

<style scoped>
.switch-icon {
  @apply ml-5;
  color: var(--icon_color_02);
}

.language-box {
  @apply mt-15;
}

.language-text {
  @apply text-base h-full flex items-center px-10 cursor-pointer;
  color: var(--text_color_01);
}

.dropdown-menu {
  @apply absolute text-center right-5 w-[96px] mt-10 rounded shadow-lg z-10;
  box-shadow: 0px 2px 8px 0px #00000026;
}

.dropdown-item {
  @apply px-4 py-2 cursor-pointer w-full text-base leading-8;
}

.title-right {
  color: var(--brand_color)
}

.gradient-bg {
  background: linear-gradient(to right, #FBF6F6, #EEF6FE);
}
</style>
