<template>
  <div class="video-wrapper" ref="videoWrapper" :class="className">
    <!-- 先给个默认值 免费的，或者已经购买的视频才可以循环播放 -->
    <video controlslist="nodownload" :src="getRemoreURL(src)" :poster="poster" ref="videoEl" :muted="muted"
      preload="true" x5-video-player-type="h5-page" :x5-video-player-fullscreen="false" :webkit-playsinline="true"
      :x5-playsinline="true" :playsinline="true" :fullscreen="false" :autoplay="autoPlay"
      :loop="!state.localItem?.paySwitch || hasBuy" :style="{
        objectFit: item?.resources[0]?.height > item?.resources[0]?.width ? 'cover' : 'contain'
      }">
      <p>您的浏览器不支持 video 标签。</p>
    </video>

    <div class="video-float" :data-parent-id="elInfo.id" :data-index="elInfo.index">
      <slot></slot>

      <!-- 已购买 -->
      <div :class="['payment-box']" :style="paymentboxBottom" v-if="hasBuy">
        <div style="width: 50px;border-radius:4px; color:white;"> {{ t("translate.purchased") }}</div>
      </div>
      <!-- 这里是倒计时节点 -->
      <div :class="['payment-box']" :style="paymentboxBottom" @click.stop="openRecharge"
        v-if="tryPlay || tryPlayOverTime">
        <div class="payment">{{ t("translate.pay") }}</div>
        <div class="try-time" v-if="timeLeft > 0">{{ t("translate.try") }} {{ timeLeft }}s | </div>
        <div class="try-time" v-else>{{ t("translate.finished") }} | </div>
        <div class="purchase" v-if="timeLeft > 0">{{ t("translate.buy") }} <van-icon name="arrow" /></div>
        <div class="purchase" v-else>{{ t("translate.buyfull") }} <van-icon name="arrow" /></div>
      </div>

      <!-- /播放器控制栏 -->
      <div v-show="showProgress" class="progress" :class="progressClass" ref="progressEl" @click="null"
        @touchstart="touchstart" @touchmove="touchmove" @touchend="touchend">
        <div class="time" v-if="state.isMove">
          <span class="currentTime">{{ _duration(state.currentTime) }}</span>
          <span class="duration"> / {{ _duration(state.duration) }}</span>
        </div>
        <template v-if="state.duration > 15 || state.isMove || !isPlaying">
          <div class="bg"></div>
          <div class="progress-line" :style="durationStyle"></div>
          <div class="point"></div>
        </template>
      </div>
      <!-- 底部信息 -->
      <BottomInfo :item="item" :lineNum="lineNum" :tryPlayOverTime="tryPlayOverTime" @toAlbums="toAlbums"
        @changelineNum="changelineNum" @showMarkUser="showMarkUser" @openRecharge="openRecharge">
      </BottomInfo>

      <!-- 右侧点赞等功能 -->
      <RightInfo ref="rightinfo" :item="item" @toFollow="toFollow" @openComment="openComment" @toShare="toShare"
        @getMore="getMore">
      </RightInfo>
      <img v-if="loveimg" :class="userPraise ? 'right love-dbclick' : 'left love-dbclick'" src="./img/loved.svg">
    </div>
  </div>
</template>


<!-- 注意了这里是专门为视频详情页的滑动操作，专门制作的视频组件 
因为此组件使用 vue mount 动态创建，全局的api无法穿透，所以只通过window来传达
甚至有些接口都是单独写的，不能复用之前的 比如点赞等操作

事件不能通过上下文来透传，只能通过属性来透传了
-->
<script setup lang="ts">
import { _duration, _stopPropagation } from './utils'
import bus, { EVENT_KEY } from './utils/bus'
import { SlideItemPlayStatus } from './utils/const_var'
import { findAncestorByClasss } from './utils/dom'
import { sendTrendsTrack } from '@/hook/useEventTracking'
import BottomInfo from './BottomInfo.vue'

const props = defineProps({

  /**
   * 
   */
  lineNum: {
    type: Number,
    default: 1
  },
  /**
   * 数据项
   */
  item: {
    type: Object,
    default: () => {
      return {}
    }
  },

  /**
   * 视频地址，暂时定为一个
   */
  src: {
    type: String,
    default:
      ''
  },
  /**
   * 预览图片
   */
  poster: {
    type: String,
    default:
      ''
  },
  /**
   * 元素信息
   */
  elInfo: {
    type: Object,
    default: () => {
      return {}
    }
  },

  /**
   * 静音
   */
  muted: {
    type: Boolean,
    default: true
  },
  /**
   * 自动播放
   */
  autoPlay: {
    type: Boolean,
    default: () => {
      return true
    }
  },

  /**
   * 显示工具栏
   */
  showProgress: {
    type: Boolean,
    default: true
  }
})

watch(
  () => inject('muted'),
  (newvalue) => {
    console.log('vnew muted', newvalue)
  }
)



/*********方便子组件知道视频播放状态及数据**********/
provide(
  'isPlaying',
  computed(() => isPlaying)
)
provide(
  'muted',
  computed(() => props.muted)
)
provide(
  'elInfo',
  computed(() => props.elInfo)
)
provide(
  'item',
  computed(() => state.localItem)
)
const emit = defineEmits(['click', 'dblclick', 'play', 'pause',
  "openRecharge", "toAlbums", "showMarkUser",
  "toFollow", "openComment", "toShare",
  "getMore"
])

const videoEl = ref<HTMLVideoElement>()
const progressEl = ref<HTMLDivElement>()
const state = reactive({
  loading: false,
  paused: false,

  status: props.autoPlay ? SlideItemPlayStatus.Play : SlideItemPlayStatus.Pause,
  duration: 0,
  step: 0,
  currentTime: -1,
  playX: 0,
  start: { x: 0 },
  last: { x: 0, time: 0 },
  height: 0,
  width: 0,
  isMove: false,
  localItem: props.item,
  progressBarRect: {
    height: 0,
    width: 0
  },
  videoScreenHeight: 0,
  commentVisible: false
})

const durationStyle = computed(() => {
  return { width: state.playX + 'px' }
})
const isPlaying = computed(() => {
  return state.status === SlideItemPlayStatus.Play
})
const className = computed(() => {
  return 'item-' + Object.values(props.elInfo).join('-')
})
const progressClass = computed(() => {
  if (state.isMove) {
    return 'move'
  } else {
    return isPlaying.value ? '' : 'stop'
  }
})

onMounted(() => {
  state.height = document.body.clientHeight
  state.width = document.body.clientWidth
  videoEl.value.currentTime = 0
  const fun = (e) => {
    state.currentTime = Math.ceil(e.target.currentTime)
    state.playX = (state.currentTime - 1) * state.step
  }

  /**
   * 视频加载完成
   */
  videoEl.value.addEventListener('loadedmetadata', () => {
    if (videoEl.value?.videoHeight) {
      state.videoScreenHeight = videoEl.value?.videoHeight / (videoEl.value?.videoWidth / state.width)
      state.duration = videoEl.value.duration
      if (progressEl.value) {
        state.progressBarRect = progressEl.value?.getBoundingClientRect()
        state.step = state.progressBarRect.width / Math.floor(state.duration)
        videoEl.value.addEventListener('timeupdate', fun)
      }
    }


  })
  videoEl.value.addEventListener(
    'waiting',
    () => {
      if (!state.paused) {
        state.loading = true
      }
    },
    false
  )

  videoEl.value.addEventListener(
    'playing',
    () => {
      state.loading = false
    },
    false
  )
  // 监听单击事件
  bus.on(EVENT_KEY.SINGLE_CLICK, event)
  // 监听双击事件
  bus.on(EVENT_KEY.DOUBLE_CLICK, event)

  // 声音
  bus.on(EVENT_KEY.MUTED_CHANGE, event)
  // 是否播放
  bus.on(EVENT_KEY.ITEM_PLAY, event)

  // 更新数据
  bus.on(EVENT_KEY.UPADATE_ITEM, event)

  // 自动播放时
  if (props.autoPlay) {
    videoTryPlay();
  }
})

onUnmounted(() => {
  bus.off(EVENT_KEY.SINGLE_CLICK, event)
  bus.off(EVENT_KEY.DOUBLE_CLICK, event)
  bus.off(EVENT_KEY.MUTED_CHANGE, event)
  bus.off(EVENT_KEY.ITEM_PLAY, event)
  // 更新数据
  bus.off(EVENT_KEY.UPADATE_ITEM, event)
  clearInterval(timer)
})
/**
 * 事件
 *
 */
function event(e) {
  const { event, id, index, type } = e
  // 当前节点事件
  if (type === EVENT_KEY.MUTED_CHANGE) {
    //改变声音
    videoEl.value.muted = event
  } else if (type === EVENT_KEY.ITEM_PLAY) {
    if (props.elInfo.id === id && props.elInfo.index === index) {
      videoEl.value.currentTime = 0
      videoTryPlay()
    }
    else {
      pause()
    }
  }
  else if (type === EVENT_KEY.UPADATE_ITEM) {
    console.log("UPADATE_ITEM", event)
    if (state.localItem.trendsId === event.trendsId) {
      state.localItem = event;
    }
  }
  else {
    // 注意了，单击与双击使用的的原生的事件，目的是为了获取event对象
    const dom = findAncestorByClasss(event.target, 'video-float')
    if (dom) {
      const id = dom.getAttribute('data-parent-id')
      const index = dom.getAttribute('data-index')
      if (props.elInfo.id === id && props.elInfo.index.toString() === index) {
        // 当前节点事件
        if (type === EVENT_KEY.SINGLE_CLICK) {

          if (event.target.className === "video-float") {

            emit('click', {
              event: event,
              videoEl: videoEl,
              item: state.localItem,
              elInfo: props.elInfo
            })
          }

        }
        // 当前节点事件
        if (type === EVENT_KEY.DOUBLE_CLICK) {
          if (event.target.className === "video-float") {
            toPraise(event);// 双击点赞

            emit('dblclick', {
              event: event,
              videoEl: videoEl,
              item: state.localItem,
              elInfo: props.elInfo
            })
          }
        }
      }
    }
  }
}
const urlregs = /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/;
/**
 * 播放操作
 */
function videoTryPlay(currentTime = 0) {
  // 监听播放
  if (state.localItem?.paySwitch) {
    // 是否需要付款
    if (state.localItem?.videoIsPay) {
      // 已经付款
      console.log("已经付款", videoEl.value.src)
      try {
        if (urlregs.test(videoEl.value.src)) {
          play()
        }
        else {
          console.log("视频地址错误")
        }
      }
      catch (e) {
        console.log("e", e)
      }

    }
    else {
      // 没有付款
      console.log("没有付款", videoEl.value.src)
      if (timeLeft.value > 0) {
        try {
          if (urlregs.test(videoEl.value.src)) {
            currentTimeHandler();// 设置倒计时
            play()
          } else {
            console.log("视频地址错误")
          }
        }
        catch (e) {
          console.log("e", e)
        }

      } else {
        if (window.location.href.indexOf("video-detail") > -1) {
          console.log("详情页-没有付款 重新播放", videoEl.value.src)
          try {
            if (urlregs.test(videoEl.value.src)) {
              videoEl.value.currentTime = currentTime || 0;// 如果传了播放时间从播放开始，因为有进度条
              if (videoEl.value.currentTime < state.localItem.lookTime) {
                // 小于试播时间，可以播放
                timeLeft.value = state.localItem.lookTime;// 重新播放、
                currentTimeHandler();// 设置倒计时
                play()
              } else {
                timeLeft.value = 0;
                pause();
              }

            } else {
              console.log("视频地址错误")
            }
          }
          catch (e) {
            console.log("e", e)
          }
        }
        else {
          console.log("非详情页-没有付款 试播结束", videoEl.value.src)
          pause();
        }

      }
    }

  } else {
    // 免费视频
    console.log("免费视频", videoEl.value.src)
    try {
      if (urlregs.test(videoEl.value.src)) {
        play()


      } else {
        console.log("视频地址错误")
      }
    }
    catch (e) {
      console.log("e", e)
    }



  }



}

function play() {
  try {
    sendTrendsTrack(state.localItem as any)
    state.status = SlideItemPlayStatus.Play
    videoEl.value.volume = 1
    const timer = setTimeout(async () => {
      try {
        videoEl.value.style.display = "block";// 设置可见
        const playPromise = await videoEl.value.play()
        clearTimeout(timer);
        if (playPromise === undefined) {
          console.log("播放成功", { trendsId: props.item.trendsId, src: videoEl.value?.src, elinfo: props.elInfo })
          emit('play', { el: videoEl, item: state.localItem, elInfo: props.elInfo })
        }
      } catch (e) {
        console.log("播放失败 ",
          { trendsId: props.item.trendsId, src: videoEl.value?.src, elinfo: props.elInfo },

        )

      }

    }, 300);


  } catch (e) {
    console.log("error", e, videoEl.value?.src)
  }

}
/**
 * 暂停操作
 */
function pause() {
  state.status = SlideItemPlayStatus.Pause
  videoEl.value.pause()
  videoEl.value.style.display = "none";// 设置不可见
  emit('pause', { el: videoEl, item: state.localItem, elInfo: props.elInfo })
}


/*********以下自定义工具栏的事件********/

function touchstart(e) {
  _stopPropagation(e)
  if (!state.localItem?.paySwitch || hasBuy) {
    //免费视频或者已经购买的
    _stopPropagation(e)
    state.start.x = e.touches[0].pageX
    state.last.x = state.playX
    state.last.time = state.currentTime
  }

}

function touchmove(e) {
  _stopPropagation(e)
  if (!state.localItem?.paySwitch || hasBuy) {
    //免费视频或者已经购买的

    state.isMove = true
    pause()
    const dx = e.touches[0].pageX - state.start.x
    state.playX = state.last.x + dx
    state.currentTime = state.last.time + Math.ceil(Math.ceil(dx) / state.step)
    if (state.currentTime <= 0) state.currentTime = 0
    if (state.currentTime >= state.duration) state.currentTime = state.duration
  }

}

function touchend(e) {
  _stopPropagation(e)
  if (!state.localItem?.paySwitch || hasBuy) {
    //免费视频或者已经购买的

    if (isPlaying.value) return
    setTimeout(() => (state.isMove = false), 1000)
    videoEl.value.currentTime = state.currentTime
    videoTryPlay(videoEl.value.currentTime)
  }
}
/*********以上自定义工具栏的事件********/



/************ 下面是视频想着操作****************/


import { useUserStore } from '@/stores/index'
import { getRemoreURL } from '@/utils'

const t = (item) => {
  if ((window as any).$t) {
    return (window as any).$t(item)
  }
  return item;
}
const userStore = useUserStore()

let timer: any;
const currentTimeHandler = () => {
  //播放中,注意了这里的定时器，只有在组件销毁时才清除，中途要监听，为防止从别的方转过来，而没有判断
  timer = setInterval(() => {

    try {
      if (timeLeft.value > 0) {
        if (!videoEl.value?.paused) {
          // 如果别的地方强行停止了，也不处理
          timeLeft.value = state.localItem?.lookTime - parseInt(videoEl.value?.currentTime.toString());
        }
      }
      else {
        videoEl.value?.pause();
      }
    }
    catch (e) {
      console.log("intersectionRatio 44", e)
    }

  }, 800);
}

/**
 * 剩余时间
 */
const timeLeft = ref(state.localItem.lookTime);

// videoIsPay 是否购买
// paySwitch 是否需要付款


/**
 * 是否已经购买
 */
const hasBuy = computed(() => {
  return state.localItem?.paySwitch && state.localItem?.videoIsPay && userStore?.userInfo?.userId !== state.localItem?.userId
})

/**
 * 试播
 */
const tryPlay = computed(() => {

  return state.localItem?.paySwitch && !state.localItem?.videoIsPay && timeLeft.value > 0 && userStore?.userInfo?.userId !== state.localItem?.userId
})




/**
 * 试播结束
 */
const tryPlayOverTime = computed(() => {

  return state.localItem?.paySwitch && !state.localItem?.videoIsPay && timeLeft.value <= 0 && userStore?.userInfo?.userId !== state.localItem?.userId
})

const lineNum = ref(1)
const changelineNum = (value) => {
  lineNum.value = value;
}
const isVideoDetail = window.location.href.indexOf('video-detail') > -1;
const paymentboxBottom = computed(() => {
  if (isVideoDetail) {
    const baseValue = lineNum.value === 1 ? '26vw' : '39vw';
    const compilationOffset = props.item?.compilationsVo ? '12vw' : '0px';
    return {
      bottom: `calc(${baseValue} + ${compilationOffset})`,
    };
  } else {
    return {
      // 
      top: '12vw'
    }
  }


})


/**
 * 打开付款窗口
 */
const openRecharge = () => {

  emit("openRecharge", state.localItem)
}

// 合集
const toAlbums = () => {
  emit("toAlbums", props.item)
}
// 显示标记用户
const showMarkUser = (list) => {
  emit("showMarkUser", list)
}

// 去关注
const toFollow = (item) => {
  emit("toFollow", item)
}


// 打开评论
const openComment = (item) => {
  emit("openComment", item)
}

// 打开分享
const toShare = (item) => {
  emit("toShare", item)
}

// 打开更多,拉黑，隐藏等操作等
const getMore = (item) => {
  emit("getMore", item)
}

const rightinfo = ref(null)
const loveimg = ref(false)

const userPraise = ref(props.item?.userPraise)
/**
 * 双击点赞
 * @param item 
 */
const toPraise = (e) => {
  loveimg.value = true;
  userPraise.value = !userPraise.value;
  setTimeout(() => {
    loveimg.value = false;
  }, 1000)
  rightinfo.value?.toPraise()
}

</script>

<style scoped>
.video-wrapper {
  position: relative;
  font-size: 14px;
  width: 100%;
  height: 100%;
  text-align: center;
  background-image: url("./img/loading.gif");
  background-size: 90px 90px;
  background-position: center center;
  background-repeat: no-repeat;

  video {
    max-width: 100%;
    height: 100%;
    width: 100%;
    transition:
      height,
      margin-top 0.3s;
  }

  .pause-icon {
    width: 60px;
    height: 60px;
    opacity: 0.3;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    color: white;
    z-index: 9999;
    animation: pause-animation 1.1s linear;

    @scale: scale(1.2);

    @keyframes pause-animation {
      0% {
        opacity: 0;
        transform: scale(2);
      }

      10% {
        opacity: 0.3;
        transform: @scale;
      }

      100% {
        transform: @scale;
        opacity: 0.3;
      }
    }
  }

  .video-float {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;

    .progress {
      z-index: 10;

      position: absolute;
      bottom: -1px;
      height: 10px;
      left: calc((100% - 90%) / 2);
      width: 90%;
      display: flex;
      align-items: flex-end;
      margin-bottom: 2px;

      .time {
        position: absolute;
        z-index: 9;
        font-size: 24px;
        bottom: 50px;
        left: 0;
        right: 0;
        color: white;
        text-align: center;

        .duration {
          color: darkgray;
        }
      }

      .bg {
        transition: height 0.3s;
        position: absolute;
        width: 100%;
        height: 2px;
        background: #4f4f4f;
        border-radius: 10px;
      }

      .progress-line {
        transition: height 0.3s;
        height: calc(2px + 0.5px);
        width: 50px;
        border-radius: 10px 0 010px;
        background: #777777;
        z-index: 1;
      }

      .point {
        transition: all 0.2s;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: white;
        z-index: 2;
        transform: translate(-1px, 1px);
      }
    }

    .move {
      .bg {
        height: 10px;
        background: var(--active-main-bg);
      }

      .progress-line {
        height: 10px;
        background: var(--second-text-color);
      }

      .point {
        width: 6px;
        height: 6px;
        background: white;
      }
    }

    .stop {
      .bg {
        height: 4px;
      }

      .progress-line {
        height: 4px;
        background: white;
      }

      .point {
        width: 4px;
        height: 4px;
        background: white;
      }
    }


    .lock-overlay {
      /* 半透明背景色 */
      @apply absolute top-0 left-0 right-0 bottom-0 h-full w-full;
      z-index: 99;
    }


    .payment-box {
      @apply absolute bottom-[200px] left-[12px] rounded flex justify-between text-sm text-center box-border;
      background: var(--card_bg_color_09);
      z-index: 100;
      height: 17px;

      .payment {
        @apply p-1 mr-2;
        width: 32px;
        border-radius: 4px 0 0 4px;
        background: var(--auxiliary_color_08);
      }

      .try-time {
        @apply p-1;
        color: var(--bg_color);

      }

      .purchase {
        @apply p-1;
        color: var(--auxiliary_color_08);

      }
    }


    .lock-icon {
      @apply absolute z-50 w-full text-center h-full flex justify-center items-center flex-col;
      color: var(--bg_color);

      .buy-btn {
        @apply h-[42px] text-base rounded-lg flex items-center justify-center w-8/12 m-auto my-25 w-[270px];
        background: var(--brand_color);
      }

      .replay-btn {
        @apply flex m-auto w-[88px] items-center justify-center h-30 text-base;
        border-radius: 24px;
        background: var(--card_bg_color_10);
        border: 1px solid var(--bg_color);

      }
    }

  }



  .love-dbclick {
    position: absolute;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: calc(40% - 25px);
    z-index: 9999;


    &.left {
      animation: loveLeft 1.1s linear;
    }

    &.right {
      animation: loveRight 1.1s linear;
    }





    @keyframes loveLeft {
      0% {
        opacity: 0;
        transform: scale(2.2) rotate(-10deg);
      }

      10% {
        opacity: 1;
        transform: scale(1) rotate(-10deg);
      }

      15% {
        opacity: 1;
        transform: scale(1.2) rotate(-10deg);
      }

      40% {
        opacity: 1;
        transform: scale(1.2) rotate(-10deg);
      }

      100% {
        transform: translateY(-12rem) scale(2) rotate(-10deg);
        opacity: 0;
      }
    }

    @keyframes loveRight {
      0% {
        opacity: 0;
        transform: scale(2.2) rotate(10deg);
      }

      10% {
        opacity: 1;
        transform: scale(1) rotate(10deg);
      }

      15% {
        opacity: 1;
        transform: scale(1.2) rotate(10deg);
      }

      40% {
        opacity: 1;
        transform: scale(1.2) rotate(10deg);
      }

      100% {
        transform: translateY(-12rem) scale(2) rotate(10deg);
        opacity: 0;
      }
    }
  }


}
</style>
