import { get, post } from '@/utils/httpRequest.ts'

// 用户注册
export function register(params) {
  return post('/user/register', params)
}

// 用户登录
export function login(params) {
  return post('/user/login', params)
}

// 忘记密码
export function resetPwd(params) {
  return post('/user/resetPwd', params)
}

// 校验验证码
export function checkCodes(data) {
  return get('/user/checkShareCode', data)
}
// 注册验证码校验
export function checkVerify(data) {
  return post('/user/register/code', data)
}
// 发送验证码
export function sendCode(data) {
  return post('/user/sendCode', data)
}

// 头像上传
export function uploadAvatar(params) {
  return post('/user/avatar', params)
}

// 获取用户信息
export function getUserInfo() {
  return get('/user/getInfo')
}
// 获取密钥
export function getSecretKeys() {
  return get('/home/get/key')
}
// 用户信息完善
export function improveInformation(params) {
  return post('/user/improveInformation', params)
}

// 谷歌第三方登入处理
export function googleLogin(data) {
  return post('/user/googleLogin', data)
}

// faceBook 第三方登入成功后处理
export function faceBookLogin(data) {
  return post('/user/faceBookLogin', data)
}

// apple 第三方登入成功后处理
export function appleLogin(data) {
  return post('/user/appleLogin', data)
}

// 第三方登入
export function thirdPartyLogin(code: string) {
  return get(`/user/thirdPartyLogin/${code}`)
}

// 第三方登入成功后处理
export function loginSuccess() {
  return get('/user/login-success')
}

// 第三方登入失败后处理
export function loginError() {
  return get('/user/login-error')
}

// 华为短信回调消息
export function callback() {
  return post('/user/callback')
}

/**
 * 用户关注
 * @param data
 * @returns
 */
export function userFollow(data) {
  return post('/user/userFollow', data)
}

/**
 * 登入人点击用户头像进入的用户主页
 * @param params
 * merchantCode 商户编码
 * toUserId 目标用户 id
 * @returns
 */
export function getUserData(params) {
  return get('/user/getUserData', params)
}

/**
 * 用户主页粉丝搜索
 * @param data
 * @returns
 */
export function getUserFans(data) {
  return post('/user/getUserFans', data)
}

/**
 * 用户主页关注搜索
 * @param data
 * @returns
 */
export function getUserFollow(data) {
  return post('/user/getUserFollow', data)
}

/**
 * 获取推荐关注用户
 * @param data
 * @returns
 */
export function getRecommendUser(data) {
  return post('/user/getRecommendUser', data)
}

/**
 * 登入人屏蔽用户
 * @param data
 * @returns
 */
export function userShield(data) {
  return post('/user/userShield', data)
}

/**
 * 用户 拉黑/取消 操作
 * @param data
 * @returns
 */
export function userBlock(data) {
  return post('/user/userBlock', data)
}

/**
 * 用户举报
 * @param data
 * @returns
 */
export function userReport(data) {
  return post('/user/userReport', data)
}

/**
 查询订单详情
 * @param data
 * @returns
 */
export function getOrderDetail(data) {
  return get('/v1/order/detail', data)
}
/**
 查询提现/充值套餐配置
 * @param data
 * @returns
 */
export function getFinanceConfig(data) {
  return get('/v1/order/financeConfig', data)
}
/**
 查询支持的币种主链
 * @param data
 * @returns
 */
export function getChains(data) {
  return get('/v1/order/supportCryptos', data)
}
/**
 通过类型获取简介配置
 * @param data
 * @returns
 */
export function getSetting(data) {
  return get('/v1/introduction/detail', data)
}
/**
 充值购买积分
 * @param data
 * @returns
 */
export function getPoints(data) {
  return post('/v1/order/purchase', data)
}
/**
 兑换积分
 * @param data
 * @returns
 */
export function redeemPoints(data) {
  return post('/v1/order/exchange', data)
}
/**
 付费信息
 * @param data
 * @returns
 */
export function chargeDetail(data) {
  return post('/v1/video/pay/msg', data)
}
/**
 购买付费视频
 * @param data
 * @returns
 */
export function purchaseVideo(data) {
  return post('/video/pay/purchase', data)
}

/**
 创建/编辑合集
 * @param data
 * @returns
 */
export function createAlbum(data) {
  return post('/video/pay/compilations/save', data)
}
/**
 查看合集信息
 * @param data
 * @returns
 */
export function checkAlbum(data) {
  return post('/video/pay/compilations/info', data)
}
/**
 合集内视频推荐
 * @param data
 * @returns
 */
export function AlbumPage(data) {
  return post('/video/pay/compilations/video', data)
}
/**
 删除合集
 * @param data
 * @returns
 */
export function deleteAlbum(data) {
  return post('/video/pay/compilations/delete', data)
}
/**
 付费内容查询
 * @param data
 * @returns
 */
export function AlbumPaidDetail() {
  return post('/video/pay/content/query')
}
/**
 查看该用户下的所有未加入合集的动态
 * @param data
 * @returns
 */
export function otherDetails(data) {
  return post('/video/pay/not/compilations/list', data)
}
/**
 查看合集列表
 * @param data
 * @returns
 */
export function getAllAlbums(data) {
  return get('/video/pay/compilations/list', data)
}
/**
 用户分享和推广数据
 * @param data
 * @returns
 */
export function getInvitation(data) {
  return get('/user/getUserInviteData', data)
}
/**
 * 用户主页搜索该用户动态
 * @param data
 * @returns
 */
export function getTrendsByUser(data) {
  return post('/user/getTrendsByUser', data)
}

/**
 * 用户点赞过的动态搜索
 * @param data
 * @returns
 */
export function getTrendsByUserPraise(data) {
  return post('/user/getTrendsByUserPraise', data)
}
/**
 * 用户收藏过的动态搜索
 * @param data
 * @returns
 */
export function getTrendsByUserCollect(data) {
  return post('user/getTrendsByUserCollect', data)
}

/**
 * 用户主页该用户评论过的动态
 * @param data
 * @returns
 */
export function getTrendsByUserComment(data) {
  return post('/user/getTrendsByUserComment', data)
}

// 获取标签集合
export function getLabelList(data) {
  return post('/user/getLabelList', data)
}

// 用户创建标签
export function userCrateLabel(params) {
  return post('/user/userCrateLabel', params)
}
// 用户创建标签
export function userCheckLabel(params) {
  return post('/user/userCheckLabel', params)
}

// 获取积分明细
export function detailGold(data) {
  return get('/user/getGoldDetail', data)
}

// 获取积分规则列表
export function ruleGold() {
  return get('/user/getGoldRuleList')
}

// 获取每月的总数
export function monthSumGold(data) {
  return get('/user/getGoldSumByMonth', data)
}

// 用户设置
export function userSetting(data) {
  return post('/user/userSetting', data)
}

// 密码修改 xxk-0923
export function updatePwd(data) {
  return post('/user/updatePwd', data)
}

// 用户屏蔽列表 user/getShieldList
export function listShield(data) {
  return post('/user/getShieldList', data)
}
// 用户拉黑列表
export function lisBlockt(data) {
  return post('user/getBlockList', data)
}
// 绑定邮箱手机号
export function bindAccount(data) {
  return post('user/bind', data)
}
// 获取热线电话
export function getCustomerServiceContact(data) {
  return post('user/getCustomerServiceContact', data)
}

// 用户分享
export function userShare(data) {
  return post('/user/userShare', data)
}

// 获取当前用户状态
export function getLoginUserStatus() {
  return post('/user/getLoginUserStatus')
}

// 退出登录
export function logout() {
  return post('/logout')
}

// 用户主页搜索（视频）
export function getVideoTrendsByUser(data) {
  return post('/user/getVideoTrendsByUser', data)
}

// 获取用户收藏
export function getVideoTrendsByUserCollect(data) {
  return post('/user/getVideoTrendsByUserCollect', data)
}

// 用户评论过的动态
export function getVideoTrendsByUserComment(data) {
  return post('/user/getVideoTrendsByUserComment', data)
}

// 用户点赞过视频动态
export function getVideoTrendsByUserPraise(data) {
  return post('/user/getVideoTrendsByUserPraise', data)
}

// 用户链接数据
export function getPageLink(data) {
  return post('/user/getPageLink', data)
}
// 增加链接
export function addLink(data) {
  return post('/user/addLink', data)
}
// 移除链接
export function delLink(data) {
  return post('/user/delLink', data)
}
// 获取用户简介
export function getUserAccountInfo(data) {
  return get('/user/getUserAccountInfo', data)
}
// 获取用户收藏动态  __ v2
export function getUserCollectTrends(data) {
  return post('/user/getTrendsByUserCollect', data)
}
// 获取用户被标记动态  __ v2
export function getUserMarkTrends(data) {
  return post('/user/getUserMarkTrends', data)
}
// 用户移除粉丝  __ v2
export function removeFans(data) {
  return post('/user/removeFans', data)
}
// 用户点赞内容  __ v2
export function getUserPraiseTrends(data) {
  return post('/user/getUserPraiseTrends', data)
}
// 获取用户收藏页 img  __ v2
export function getUserCollectHome(data) {
  return get('/user/getUserCollect', data)
}
// 获取用户音频收藏页 img  __ v2
export function userCollectAudioPage(data) {
  return post('/audioFrequency/userCollectAudioPage', data)
}
// 更换用户账号
export function userEditAccount(data) {
  return post('/user/userEditAccount', data)
}
// 删除用户推荐
export function delRecommendUser(data) {
  return post('/user/delRecommendUser', data)
}
// 三方 api 获取
export function thirdApiLoginUser() {
  return get('common/getGoogleKey')
}
