<template>
  <IconFont :type="name" :width="width" :color="color" :height="height" :style="iconStyle" />
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import { Icon as ArcoIcon } from '@arco-design/web-vue';
import { oss_svg_domain_address } from '@/constants/oss';

// 创建一个动态引入 IconFont 的方法
const IconFont = ArcoIcon.addFromIconFontCn({
  src: oss_svg_domain_address,
});

export default defineComponent({
  name: 'Icon',
  components: {
    IconFont,
  },
  props: {
    style: Object,
    iconType: '', // 替换为你需要的图标类型
    spin: Boolean,
    hover: [Boolean, String],
    name: {
      type: String,
      required: true,
    },
    hasTheme: Boolean,
    isRemoteUrl: Boolean,
    noPointer: Boolean,
    fontSize: [Number, String],
    width: [Number, String],
    height: [Number, String],
    color: String,
    hoverName: String,
    hiddenMarginTop: Boolean,
  },
  setup(props) {
    const iconStyle = computed(() => ({
      color: props.color,
    }));

    return {
      iconStyle,
    };
  }
})
</script>

<style scoped></style>
