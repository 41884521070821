<template>
    <div class="absolute z-110 flex flex-col items-center justify-center right-12 bottom-[100px]" style="z-index: 110;">
        <div class="relative">
            <img v-if="item?.userFriends < 1 && !item?.yourself" src="../../assets/image/video/icon-follow.png"
                class="absolute left-[11px] bottom-[-8px] w-[19.5px] h-[19.5px]"
                @click="toFollow(item, index, data.list)" />
        </div>
        <div class="flex flex-col items-center justify-center mt-15">
            <img v-if="!item?.userPraise" src="../../assets/image/video/icon-like-default.png" class="w-[24px] h-[24px]"
                @click.stop="toPraise" />
            <img v-else src="../../assets/image/home/icon-like-active.png" class="w-[24px] h-[24px]"
                @click.stop="toPraise" />
            <span class="mt-8 text-xs text-white font-w6 pingfang" @click.stop="toLikeList(item)">{{
                item?.praiseCount
                }}</span>
        </div>
        <div class="flex flex-col items-center justify-center mt-15" @click.stop="openComment(item)">
            <img src="../../assets/image/video/icon-comment.png" class="w-[25.5px] h-[25.5px]" />
            <span class="mt-8 text-xs text-white font-w6 pingfang">{{ item?.commentCount }}</span>
        </div>
        <div class="flex flex-col items-center justify-center mt-15" @click.stop="toShare(item)">
            <img src="../../assets/image/video/icon-share.png" class="w-[25px] h-[22px]" />
        </div>
        <div class="flex flex-col items-center justify-center mt-[21px] mb-[5px]" @click.stop="getMore(item)">
            <img src="../../assets/image/v2/more.png" class="w-[25.5px] h-[25.5px]" />
        </div>
        <div @click="toAudio(item.audioVo)" v-if="item?.audioVo?.audioFrequencyHead"
            class="relative mt-15 w-[28px] h-[28px] border-white border-[2px] rounded-[8px] overflow-hidden">
            <img v-if="item?.audioVo?.audioFrequencyHead" :src="getRemoreURL(item?.audioVo?.audioFrequencyHead)"
                class="w-full h-full">
            <img v-else :src="getOssImage('common/default-avatar.png')" class="w-full h-full main-icon" />
            <!-- <img :src="audioIcon" class="w-full h-full float-icon" /> -->
            <Icon name="icon-love_home_music" class="w-full h-full float-icon"></Icon>
        </div>
        <div @click="toAudio(item.audioVo)" v-else class="mt-15 w-[28px] h-[28px] rounded-[8px] overflow-hidden">
            <img :src="getOssImage('common/default-avatar.png')" class="w-full h-full" />
        </div>

    </div>
</template>
<script setup>
import { trendsPraise } from '@/api/trends'
import { merchantCode } from '@/config/index'
import { useUserStore } from '@/stores/index'
import { getOssImage } from "@/constants/oss";
import { getRemoreURL } from '@/utils/index'

const t = window.$t;
const props = defineProps({
    /**
  * 数据项
  */
    item: {
        type: Object,
        default: () => {
            return {}
        }
    },


})

//当前数据项，因为要改变
const citem = ref(props.item)

watch(() => props.item, (newvalue) => {
    citem.value = newvalue;
})

const emit = defineEmits(['toFollow'])
// 前往音频页
const toAudio = (item) => {
    try {
        if (!item) {
            return showToast('暂未获取到音频信息')
        }
        const audioInfo = toRaw(item)
        window.$router.push({ path: '/audio', query: { id: audioInfo.audioFrequencyId }, state: { audioInfo } })
    } catch (e) {
        console.log("error", e)
    }

}

// 未登录的操作，直接跳转到登录页面
const userStore = useUserStore()

const checkLogin = () => {
    if (userStore.isLogin) {
        return true
    } else {
        window.$router.push({
            name: 'login',
            query: {
                redirect: window.$route.fullPath,
                ...window.$route.query
            }
        })
        return false
    }
}

/*********此处不能调用hook中定义的方法会报错******/
/**
  * 动态点赞
  * @param trendsId 动态 id
  * @param praise true:点赞 false:取消点赞
  * @returns
  */
function praiseTrends(trendsId, praise) {
    return trendsPraise({
        merchantCode,
        trendsId,
        aesSecretKey: userStore.backendKey,
        type: praise ? 0 : 1, // 类型，0 点赞，1 取消点赞
    }).then((res) => {
        if (res?.resultCode === 406) {
            userStore.refreshKeys().then(() => {
                setTimeout(() => {
                    // 密钥过期，重新触发页面请求
                    praiseTrends(trendsId, praise)
                }, 1000)
            });
            return
        }

        if (res?.resultCode === 1) {
            return res.data
        }
    })
}


// 点赞
const toPraise = () => {
    if (!checkLogin()) return
    praiseTrends(citem.value?.trendsId, !citem.value?.userPraise)
        .then(res => {
            if (res) {
                citem.value.userPraise = res?.userPraise
                citem.value.praiseCount = res?.praiseCount
            }
        })
}
// 点击点赞，打开点赞列表
const toLikeList = (item) => {
    window.$router.push({
        path: `/like-list/${item.trendsId}`,
    })
}


// 去关注
const toFollow = (item) => {
    emit("toFollow", item)
}


// 打开评论
const openComment = (item) => {
    emit("openComment", item)
}

// 打开分享
const toShare = (item) => {
    emit("toShare", item)
}
// 打开更多,拉黑，隐藏等操作等
const getMore = (item) => {
    emit("getMore", item)
}


defineExpose({
    toPraise
})
</script>

<style scoped></style>