import {
  ZIMConversationDeleteConfig,
  ZIMConversationQueryConfig,
  ZIMEventHandler,
  ZIMGroupAdvancedConfig,
  ZIMGroupInfo,
  ZIMMessage,
  ZIMMessageDeleteConfig,
  ZIMMessageQueryConfig,
  ZIMMessageRevokeConfig,
  ZIMMessageSearchConfig,
  ZIMMessageSendConfig,
  ZIMMessageSendNotification,
  ZIMRoomAdvancedConfig,
  ZIMRoomInfo,
  ZIMUserInfo,
  ZIMUsersInfoQueryConfig,
} from '@/types/zim'
import { ZIMConversationType } from '@/types/enums'
import CryptoJS from 'crypto-js'
import { envIsProd } from '@/config/env'
// import { ZIM } from "zego-zim-web"
const zegoAppId = envIsProd ? 1457735384 : 367825766
const appConfig = {
  appID: zegoAppId,
  serverSecret: '78f3f2ee85399b6ff4a23be05b1e59cd', // ServerSecret - type: 32 byte length string
}

export const avatarPrefix = 'https://storage.zego.im/zim/example/web/assets/'

// @ts-ignore
window.ZIM.create(appConfig)
// @ts-ignore
export const zim = window.ZIM.getInstance()
// @ts-ignore
export const SDKVersion = window.ZIM.getVersion()

console.log('zim version: ' + SDKVersion, zim)

zim.on('error', function (_zim, errorInfo) {
  console.error('code:' + errorInfo.code + ', message: ' + errorInfo.message)
})

/**
 * 生成 token
 * @param userID
 * @param seconds
 * @returns
 */
export function generateToken(userID: string, seconds: number): string {
  if (!userID) throw new Error('generateToken error: params invalid.')

  const time = (Date.now() / 1000) | 0
  const body = {
    app_id: Number(appConfig.appID),
    user_id: String(userID),
    nonce: (Math.random() * 2147483647) | 0,
    ctime: time,
    expire: time + Number(seconds || 7200),
  }

  const key = CryptoJS.enc.Utf8.parse(appConfig.serverSecret)
  let iv = Math.random().toString().substring(2, 18)
  if (iv.length < 16) iv += iv.substring(0, 16 - iv.length)

  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(body), key, {
    iv: CryptoJS.enc.Utf8.parse(iv),
  }).toString()
  const ciphert = Uint8Array.from(
    Array.from(atob(ciphertext)).map((val) => val.charCodeAt(0)),
  )
  const len_ciphert = ciphert.length

  const uint8 = new Uint8Array(8 + 2 + 16 + 2 + len_ciphert)
  // expire: 8
  uint8.set([0, 0, 0, 0])
  uint8.set(new Uint8Array(Int32Array.from([body.expire]).buffer).reverse(), 4)
  // iv length: 2
  uint8[8] = iv.length >> 8
  uint8[9] = iv.length - (uint8[8] << 8)
  // iv: 16
  uint8.set(Uint8Array.from(Array.from(iv).map((val) => val.charCodeAt(0))), 10)
  // ciphertext length: 2
  uint8[26] = len_ciphert >> 8
  uint8[27] = len_ciphert - (uint8[26] << 8)
  // ciphertext
  uint8.set(ciphert, 28)

  const token = `04${btoa(String.fromCharCode(...Array.from(uint8)))}`
  console.log('generateToken', iv.length, body, token)

  return token
}

/**
 * 获取token
 */
export const generateToken04 = generateToken

/**
 * 刷新token
 * @param token
 * @param callback
 */
export function renewToken(token: string, callback: Function) {
  zim
    .renewToken(token)
    .then((data) => {
      callback && callback(data)
    })
    .catch((err) => {
      callback && callback(null, err)
    })
}

/**
 * 登录
 * @param userInfo
 * @param token
 * @param callback
 */
export function login(
  userInfo: ZIMUserInfo,
  token: string,
  callback: Function,
) {
  return zim
    .login(userInfo, token)
    .then((data) => {
      console.log('登录成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('登录失败', err)
      callback && callback(null, err)
    })
}

/**
 * 退出
 * @param callback
 */
export function logout(callback: Function) {
  zim.logout()
  console.log('退出成功')
  callback && callback(null, null)
}

/**
 * 更新用户名
 * @param userName
 * @param callback
 */
export function updateUserName(userName: string, callback: Function) {
  zim
    .updateUserName(userName)
    .then((data) => {
      console.log('更新用户名成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('更新用户名失败', err)
      callback && callback(null, err)
    })
}

/**
 * 更新用户头像
 * @param userAvatarUrl
 * @param callback
 */
export function updateUserAvatarUrl(userAvatarUrl: string, callback: Function) {
  zim
    .updateUserAvatarUrl(userAvatarUrl)
    .then((data) => {
      console.log('更新用户头像成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('更新用户头像失败', err)
      callback && callback(null, err)
    })
}

/**
 * 更新用户扩展数据
 * @param extendedData
 * @param callback
 */
export function updateUserExtendedData(
  extendedData: string,
  callback: Function,
) {
  zim
    .updateUserExtendedData(extendedData)
    .then((data) => {
      console.log('更新用户扩展数据成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('更新用户扩展数据失败', err)
      callback && callback(null, err)
    })
}

/**
 * 查询用户数据
 * @param userIDs
 * @param config
 */
export function queryUsersInfo(
  userIDs: string[],
  config: ZIMUsersInfoQueryConfig,
  callback: Function,
) {
  zim
    .queryUsersInfo(userIDs, config)
    .then((data) => {
      console.log('查询用户数据成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('查询用户数据失败', err)
      callback && callback(null, err)
    })
}

/**
 * 创建聊天室
 * @param roomInfo
 * @param config
 * @param callback
 */
export function createRoom(
  roomInfo: ZIMRoomInfo,
  config: ZIMRoomAdvancedConfig,
  callback: Function,
) {
  zim
    .createRoom(roomInfo, config)
    .then((data) => {
      console.log('创建聊天室成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('创建聊天室失败', err)
      callback && callback(null, err)
    })
}

/**
 * 加入聊天室
 * @param roomID
 * @param callback
 */
export function joinRoom(roomID: string, callback: Function) {
  zim
    .joinRoom(roomID)
    .then((data) => {
      console.log('加入群组成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('加入群组失败', err)
      callback && callback(null, err)
    })
}

/**
 * 创建群组
 * @param groupInfo
 * @param userIDs
 * @param config
 */
export function createGroupAdvanced(
  groupInfo: ZIMGroupInfo,
  userIDs: string[],
  config: ZIMGroupAdvancedConfig,
  callback: Function,
) {
  zim
    .createGroup(groupInfo, userIDs, config)
    .then((data) => {
      console.log('创建群组成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('创建群组失败', err)
      callback && callback(null, err)
    })
}

/**
 * 创建群组
 * @param groupInfo
 * @param userIDs
 * @param callback
 * @param member_size
 */
// @ts-ignore
export function createGroup(
  groupInfo: ZIMGroupInfo,
  userIDs: string[],
  callback: Function,
  member_size: number,
) {
  zim.createGroup(groupInfo, userIDs, callback, member_size)
}

/**
 * 加入群组
 * @param groupID
 * @param callback
 */
export function joinGroup(groupID: string, callback: Function) {
  zim
    .joinGroup(groupID)
    .then((data) => {
      console.log('加入群组成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('加入群组失败', err)
      callback && callback(null, err)
    })
}

/**
 * 离开群组
 * @param groupID
 * @param callback
 */
export function leaveGroup(groupID: string, callback: Function) {
  zim
    .leaveGroup(groupID)
    .then((data) => {
      console.log('离开群组成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('离开群组失败', err)
      callback && callback(null, err)
    })
}

/**
 * 解散群组
 * @param groupID
 * @param callback
 */
export function dismissGroup(groupID: string, callback: Function) {
  zim
    .dismissGroup(groupID)
    .then((data) => {
      console.log('解散群组成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('解散群组失败', err)
      callback && callback(null, err)
    })
}

/**
 * 邀请加入群组
 * @param userIDs
 * @param groupID
 * @param callback
 */
export function inviteUsersIntoGroup(
  userIDs: string[],
  groupID: string,
  callback: Function,
) {
  zim
    .inviteUsersIntoGroup(userIDs, groupID)
    .then((data) => {
      console.log('邀请加入群组成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('邀请加入群组失败', err)
      callback && callback(null, err)
    })
}

/**
 * 踢出群组
 * @param userIDs
 * @param groupID
 * @param callback
 */
export function kickGroupMembers(
  userIDs: string[],
  groupID: string,
  callback: Function,
) {
  zim
    .kickGroupMembers(userIDs, groupID)
    .then((data) => {
      console.log('踢出群组成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('踢出群组失败', err)
      callback && callback(null, err)
    })
}

/**
 * 转让群主
 * @param toUserID
 * @param groupID
 */
export function transferGroupOwner(
  toUserID: string,
  groupID: string,
  callback: Function,
) {
  zim
    .transferGroupOwner(toUserID, groupID)
    .then((data) => {
      console.log('转让群主成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('转让群主失败', err)
      callback && callback(null, err)
    })
}

/**
 * 修改群组名称
 * @param groupName
 * @param groupID
 * @param callback
 */
export function updateGroupName(
  groupName: string,
  groupID: string,
  callback: Function,
) {
  zim
    .updateGroupName(groupName, groupID)
    .then((data) => {
      console.log('修改群组名称成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('修改群组名称失败', err)
      callback && callback(null, err)
    })
}

/**
 * 修改群组头像
 * @param groupAvatarUrl
 * @param groupID
 * @param callback
 */
export function updateGroupAvatarUrl(
  groupAvatarUrl: string,
  groupID: string,
  callback: Function,
) {
  zim
    .updateGroupName(groupAvatarUrl, groupID)
    .then((data) => {
      console.log('修改群组头像成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('修改群组头像失败', err)
      callback && callback(null, err)
    })
}

/**
 * 更新群组公告
 * @param groupNotice
 * @param groupID
 * @param callback
 */
export function updateGroupNotice(
  groupNotice: string,
  groupID: string,
  callback: Function,
) {
  zim
    .updateGroupNotice(groupNotice, groupID)
    .then((data) => {
      console.log('更新群组公告成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('更新群组公告失败', err)
      callback && callback(null, err)
    })
}

/**
 * 设置群成员角色
 * @param role
 * @param forUserID
 * @param groupID
 * @param callback
 */
export function setGroupMemberRole(
  role: number,
  forUserID: string,
  groupID: string,
  callback: Function,
) {
  zim
    .setGroupMemberRole(role, forUserID, groupID)
    .then((data) => {
      console.log('设置群成员角色成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('设置群成员角色失败', err)
      callback && callback(null, err)
    })
}

/**
 * 设置消息免打扰
 * @param status
 * @param conversationID
 * @param conversationType
 * @param callback
 */
export function setNotificationStatus(
  status: number,
  conversationID: string,
  conversationType: number,
  callback: Function,
): void {
  console.log(
    status,
    conversationID,
    conversationType,
    'conversationTypeconversationType',
  )
  let type = ZIMConversationType.Peer
  ZIMConversationType.Peer
  if (conversationType == 0) {
    type = ZIMConversationType.Peer
  } else if (conversationType == 2) {
    type = ZIMConversationType.Group
  }
  zim
    .setConversationNotificationStatus(status, conversationID, type)
    .then((data) => {
      console.log('设置免打扰状态成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('设置免打扰状态失败', err)
      callback && callback(null, err)
    })
}

/**
 * 群组禁言
 * @param groupAttributes
 * @param groupID
 * @param callback
 */
export function setGroupAttributes(
  groupAttributes: Record<string, string>,
  groupID: string,
  callback: Function,
) {
  zim
    .setGroupAttributes(groupAttributes, groupID)
    .then((data) => {
      console.log('群组禁言成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('群组禁言失败', err)
      callback && callback(null, err)
    })
}

/**
 * 拉取会话数据列表
 * @param config
 */
export function queryConversationList(
  config: ZIMConversationQueryConfig,
  callback: Function,
) {
  zim
    .queryConversationList(config)
    .then((data) => {
      console.log('拉取会话数据列表成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('拉取会话数据列表失败', err)
      callback && callback(null, err)
    })
}

/**
 * 查询会话信息
 * @param conversationID
 * @param conversationType
 * @param callback
 */
export function queryConversation(
  conversationID: string,
  conversationType: ZIMConversationType,
  callback: Function,
) {
  zim
    .queryConversation(conversationID, conversationType)
    .then((data) => {
      console.log('查询会话信息成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('查询会话信息失败', err)
      callback && callback(null, err)
    })
}

/**
 * 本地缓存
 * @param message
 * @param conversationID
 * @param conversationType [ZIMConversationType] Peer=(0);Room=(1);Group=(2)
 * @param senderUserID
 * @param callback
 */
export function insertMessageToLocalDB(
  message: ZIMMessage,
  conversationID: string,
  conversationType: ZIMConversationType,
  senderUserID: string,
  callback: Function,
) {
  zim
    .insertMessageToLocalDB(
      message,
      conversationID,
      conversationType,
      senderUserID,
    )
    .then((data) => {
      console.log('消息本地缓存成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('消息本地缓存失败', err)
      callback && callback(null, err)
    })
}

/**
 * 查询历史消息
 * @param conversationID
 * @param conversationType
 * @param config
 * @param callback
 */
export function queryHistoryMessage(
  conversationID: string,
  conversationType: ZIMConversationType,
  config: ZIMMessageQueryConfig,
  callback: Function,
) {
  zim
    .queryHistoryMessage(conversationID, conversationType, config)
    .then((data) => {
      console.log('查询历史消息成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('查询历史消息失败', err)
      callback && callback(null, err)
    })
}

/**
 * 搜索指定会话的本地消息
 * @param conversationID
 * @param conversationType
 * @param config
 * @param callback
 */
export function searchLocalMessages(
  conversationID: string,
  conversationType: ZIMConversationType,
  config,
  callback: Function,
) {
  console.log('searchLocalMessages', config)
  zim
    .searchLocalMessages(conversationID, conversationType, config)
    .then((data) => {
      console.log('搜索指定会话的本地消息成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('搜索指定会话的本地消息失败', err)
      callback && callback(null, err)
    })
}

/**
 * 删除消息
 * @param messageList
 * @param conversationID
 * @param conversationType
 * @param config
 */
export function deleteMessages(
  messageList: ZIMMessage[],
  conversationID: string,
  conversationType: ZIMConversationType,
  config: ZIMMessageDeleteConfig,
  callback: Function,
) {
  zim
    .deleteMessages(messageList, conversationID, conversationType, config)
    .then((data) => {
      console.log('删除消息成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('删除消息失败', err)
      callback && callback(null, err)
    })
}

/**
 * 撤回消息
 * @param message
 * @param config
 * @param callback
 */
export function revokeMessage(
  message: ZIMMessage,
  config: ZIMMessageRevokeConfig,
  callback: Function,
) {
  zim
    .revokeMessage(message, config)
    .then((data) => {
      console.log('撤回消息成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('撤回消息失败', err)
      callback && callback(null, err)
    })
}

/**
 * 删除会话
 * @param conversationID
 * @param conversationType
 * @param config
 */
export function deleteConversation(
  conversationID: string,
  conversationType: ZIMConversationType,
  config: ZIMConversationDeleteConfig,
  callback: Function,
) {
  zim
    .deleteConversation(conversationID, conversationType, config)
    .then((data) => {
      console.log('删除会话成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('删除会话失败', err)
      callback && callback(null, err)
    })
}

/**
 * 删除所有消息
 * @param conversationID
 * @param conversationType
 * @param config
 * @param callback
 */
export function deleteAllMessage(
  conversationID: string,
  conversationType: ZIMConversationType,
  config: ZIMMessageDeleteConfig,
  callback: Function,
) {
  zim
    .deleteAllMessage(conversationID, conversationType, config)
    .then((data) => {
      console.log('删除所有消息成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('删除所有消息失败', err)
      callback && callback(null, err)
    })
}

/**
 * 撤回消息回调
 * @param callback
 */
export function onMessageRevokeReceived(callback: Function) {
  zim.on('messageRevokeReceived', function (zim, data) {
    console.log('撤回消息回调', data)
    callback && callback({ zim, data }, null)
  })
}

/**
 * 消息发送状态回调
 * @param callback
 */
export function onMessageSentStatusChanged(callback: Function) {
  zim.on('messageSentStatusChanged', function (zim, data) {
    console.log('消息发送状态回调', data)
    callback && callback({ zim, data }, null)
  })
}

/**
 * 发送消息
 * @param message
 * @param toConversationID
 * @param conversationType
 * @param config
 * @param notification
 * @param callback
 */
export function sendMessage(
  message: any,
  toConversationID: string,
  conversationType: ZIMConversationType,
  config?: ZIMMessageSendConfig,
  notification?: ZIMMessageSendNotification,
  callback?: Function,
) {
  zim
    .sendMessage(
      message,
      toConversationID,
      conversationType,
      config,
      notification,
    )
    .then((data) => {
      console.log('发送消息成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('发送消息失败', err, message, toConversationID)
      callback && callback(null, err)
    })
}

/**
 * 发送富媒体消息
 * @param message
 * @param toConversationID
 * @param conversationType
 * @param config
 * @param notification
 * @param callback
 */
export function sendMediaMessage(
  message: any,
  toConversationID: string,
  conversationType: ZIMConversationType,
  config?: ZIMMessageSendConfig,
  notification?: ZIMMessageSendNotification,
  callback?: Function,
) {
  zim
    .sendMediaMessage(
      message,
      toConversationID,
      conversationType,
      config,
      notification,
    )
    .then((data) => {
      console.log('发送富媒体消息成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('发送富媒体消息失败', err)
      callback && callback(null, err)
    })
}

/**
 * 单聊消息回调
 * @param callback
 */
export function onReceivePeerMessage(callback: Function) {
  zim.on('receivePeerMessage', function (zim, data) {
    console.log('单聊消息回调', data)
    callback && callback({ zim, data }, null)
  })
}
/** 成员角色变更回调 */
export function onchangeGroupRole(callback: Function) {
  zim.on('groupMemberInfoUpdated', function (zim, data) {
    console.log('成员变更回调', data)
    callback && callback({ zim, data }, null)
  })
}
/**
 * 群组消息回调
 * @param callback
 */
export function onReceiveGroupMessage(callback: Function) {
  zim.on('receiveGroupMessage', function (zim, data) {
    // console.log('群组消息回调', data)
    callback && callback({ zim, data }, null)
  })
}

/**
 * 清除会话消息未读数
 */
export function clearConversationUnreadMessageCount(
  conversationID: string,
  conversationType: ZIMConversationType,
  callback: Function,
) {
  zim
    .clearConversationUnreadMessageCount(conversationID, conversationType)
    .then((data) => {
      console.log('清除会话消息未读数成功', data)
      callback && callback(data, null)
    })
    .catch((err) => {
      console.log('清除会话消息未读数失败', err)
      callback && callback(null, err)
    })
}

// /**
//  * 收到群组消息回调
//  * @param callback
//  */
// export function onReceiveGroupMessage(callback: Function) {
//   zim.on('receiveGroupMessage', function (zim, data) {
//     console.log('收到群组消息回调', data)
//     callback && callback({ zim, data }, null)
//   })
// }

/**
 * 群组名字变更回调
 * @param callback
 */
export function onGroupNameUpdated(callback: Function) {
  zim.on('groupNameUpdated', function (zim, data) {
    console.log('群组名字变更回调', data)
    callback && callback({ zim, data }, null)
  })
}

/**
 * 群组公告变更回调
 * @param callback
 */
export function onGroupNoticeUpdated(callback: Function) {
  zim.on('groupNoticeUpdated', function (zim, data) {
    console.log('群组公告变更回调', data)
    callback && callback({ zim, data }, null)
  })
}

/**
 * 群组头像变更回调
 * @param callback
 */
export function onGroupAvatarUrlUpdated(callback: Function) {
  zim.on('groupAvatarUrlUpdated', function (zim, data) {
    console.log('群组头像变更回调', data)
    callback && callback({ zim, data }, null)
  })
}

/**
 * 群组属性变更回调
 * @param callback
 */
export function onGroupAttributesUpdated(callback: Function) {
  zim.on('groupAttributesUpdated', function (zim, data) {
    console.log('群组属性变更回调', data)
    callback && callback({ zim, data }, null)
  })
}

/**
 * 群组成员状态变更回调
 * @param callback
 */
export function onGroupMemberStateChanged(callback: Function) {
  zim.on('groupMemberStateChanged', function (zim, data) {
    console.log('群组成员状态变更回调', data)
    callback && callback({ zim, data }, null)
  })
}

/**
 * 群组成员信息变更回调
 * @param callback
 */
export function onGroupMemberInfoUpdated(callback: Function) {
  zim.on('groupMemberInfoUpdated', function (zim, data) {
    console.log('群组属性变更回调', data)
    callback && callback({ zim, data }, null)
  })
}

export function on<K extends keyof ZIMEventHandler>(
  type: K,
  listener: ZIMEventHandler[K],
) {
  zim.on(type, listener)
}
