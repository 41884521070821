<template>
	<Popup ref="popup" @on-change="onChange">
		<div class="overflow-hidden bg-white" :class="{
			'rounded-tl-25': !['trends'].includes(type),
			'rounded-tr-25': !['trends'].includes(type),
		}">
			<!-- 是用户主页时的样式 -->
			<div v-if="props.isUserInfo">
				<div class="user-pop">
					<div class="user-pop-item" @click="shoareToPlatform({ value: 'group' })">分享</div>
					<div class="user-pop-item" @click="toShield">不看 TA</div>
					<div class="user-pop-item" @click="toBlack">黑名单</div>
					<div class="user-pop-item" @click="toReport">举报</div>
				</div>
			</div>
			<div v-else-if="['trends'].includes(type)" class="pt-[10px]">
				<div
					class="w-full h-[32px] bg-[#EFEFEF] px-[11px] flex items-center rounded-[9px] text-[#898989] text-[15px] mb-[10px]">
					<img src="@/assets/image/common/icon-search.png" alt="search" class="w-14 h-14 mr-[11.5px]" />
					搜索
					<img class="w-[22px] h-[22px] ml-[auto]" src="@/assets/image/home/build_group_icon.png" alt=""
						@click.stop="buildGroup" />
				</div>
				<div class="w-full h-[340px] overflow-y-auto flex flex-wrap border-t-[1px] border-[#DBDBDB] pt-[10px]">
					<div class="w-1/3 h-[103px] flex flex-col items-center mb-[15px]"
						v-for="(item, index) in data.userList" :key="index">
						<love-avatar :size="65" :src="getRemoreURL(item.headSculpture)" :user-id="item.id"
							class="flex-shrink-0 mx-10" :clickAble="false"
							:isShowSelect="data.selection.includes(item.id)" @click.stop="selectItem(item)" />

						<div
							class="w-[65px] text-center text-black text-[12px] overflow-hidden text-ellipsis whitespace-nowrap mt-[9px]">
							{{ item.nickname }}
						</div>
					</div>
				</div>

				<div v-if="data.selection.length" class="w-full h-[96px] pt-[15px] pr-[15px] pl-[15px] pb-[15px]">
					<input type="text" placeholder="消息内容..." class="text-black text-[14px] mb-[11px] message"
						v-model="data.input" />
					<button @click="submit"
						class="w-full h-[39px] bg-[#0094F4] text-center text-[13px] text-white rounded-[7px]">
						发送
					</button>
				</div>

				<div v-else class="w-full pt-[15px] pr-[15px] pl-[15px] pb-[15px] flex flex-wrap">
					<div class="w-[51px] mr-[23px]" v-for="(item, index) in operationList" :key="index"
						@click="item.callback">
						<img class="w-[51px] h-[51px] mb-[6px]" :src="item.icon" />
						<div class="w-[51px] text-[12px] text-black text-center">{{ item.name }}</div>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="mt-25 mb-[22.5px] text-primary text-lg flex justify-center">分享</div>
				<div class="pt-2 pr-[17.5px] pb-22 pl-[27px]">
					<div v-if="data.userList && data.userList.length" class="mb-20">
						<ul class="flex items-center">
							<li v-for="(item, index) in data.userList.filter((_, i) => i < 4)" :key="index"
								class="flex flex-col items-center justify-center w-1/5 text-sm text-primary"
								@click="shareToUser(item)">
								<love-avatar :src="getRemoreURL(item.headSculpture)" :size="41.5" alt="avatar"
									class="w-[41.5px] h-[41.5px] mb-[11.5px] rounded-1/2" />
								<span class="w-full text-center truncate">{{ item.nickname }}</span>
							</li>
							<li v-if="data.userList.length > 4"
								class="flex items-center justify-center w-1/5 h-full mb-20 text-sm text-primary"
								@click="onClickMore">
								更多
							</li>
						</ul>
					</div>
					<div>
						<ul class="flex">
							<li v-for="(item, index) in sharePlatformList" :key="index"
								class="flex flex-col items-center justify-center w-1/5 text-sm platform-item text-primary"
								@click="shoareToPlatform(item)">
								<img :src="getAssetURL(item.icon)" :alt="item.name" class="w-[42px] h-[42px] mb-11" />
								<span>{{ item.name }}</span>
							</li>
						</ul>
					</div>
					<div v-if="type === 'trends'" class="mt-[29px]">
						<div class="w-[66.9px] h-[55.1px] bg-[#F6F6F6] rounded-10 text-primary text-[11px] flex flex-col justify-center items-center"
							@click="toCopy(trendsInfo?.trendsUrl)">
							<img src="../../assets/image/share/icon-link.png" class="w-[17.5px] h-[16.5px] mb-[8.5px]"
								alt="copy-link" />
							<span>复制链接</span>
						</div>
					</div>
					<div v-else-if="type === 'user'" class="flex mt-[29px]">
						<div class="w-[66.9px] h-[55.1px] bg-[#F6F6F6] rounded-10 text-primary text-[11px] flex flex-col justify-center items-center mr-5"
							@click="toCopy(userInfo?.userUrl)">
							<img src="../../assets/image/user/icon-link.png" class="w-[17.5px] h-[16.5px] mb-8"
								alt="copy-link" />
							<span>复制链接</span>
						</div>
						<div v-if="!isYourself"
							class="w-[66.9px] h-[55.1px] bg-[#F6F6F6] rounded-10 text-primary text-[11px] flex flex-col justify-center items-center mr-5"
							@click="toShield">
							<img src="../../assets/image/user/icon-shield.png" class="w-[18.5px] h-[15px] mb-8"
								alt="shield" />
							<span>不看 TA</span>
						</div>
						<div v-if="!isYourself"
							class="w-[66.9px] h-[55.1px] bg-[#F6F6F6] rounded-10 text-primary text-[11px] flex flex-col justify-center items-center mr-5"
							@click="toBlack">
							<img src="../../assets/image/user/icon-blacklist.png" class="w-[18px] h-[18px] mb-8"
								alt="blacklist" />
							<span>黑名单</span>
						</div>
						<div v-if="!isYourself"
							class="w-[66.9px] h-[55.1px] bg-[#F6F6F6] rounded-10 text-primary text-[11px] flex flex-col justify-center items-center mr-5"
							@click="toReport">
							<img src="../../assets/image/user/icon-report.png" class="w-[15.5px] h-[15.5px] mb-8"
								alt="report" />
							<span>举报</span>
						</div>
					</div>
					<div v-else-if="type === 'video'" class="flex mt-[29px]">
						<div class="w-[66.9px] h-[55.1px] bg-[#F6F6F6] rounded-10 text-primary text-[11px] flex flex-col justify-center items-center mr-5"
							@click="toTranspond">
							<img src="../../assets/image/video/icon-transpond-black.png" class="w-[12.5px] h-16 mb-8"
								alt="transpond" />
							<span>转发</span>
						</div>
						<div class="w-[66.9px] h-[55.1px] bg-[#F6F6F6] rounded-10 text-primary text-[11px] flex flex-col justify-center items-center mr-5"
							@click="toCopy(trendsInfo?.trendsUrl)">
							<img src="../../assets/image/user/icon-link.png" class="w-[17.5px] h-[16.5px] mb-8"
								alt="copy-link" />
							<span>复制链接</span>
						</div>
						<div class="w-[66.9px] h-[55.1px] bg-[#F6F6F6] rounded-10 text-primary text-[11px] flex flex-col justify-center items-center mr-5"
							@click="toShieldTrends">
							<img src="../../assets/image/video/icon-shield.png" class="w-16 h-16 mb-8" alt="shield" />
							<span>屏蔽此条</span>
						</div>
						<div v-if="!yourself"
							class="w-[66.9px] h-[55.1px] bg-[#F6F6F6] rounded-10 text-primary text-[11px] flex flex-col justify-center items-center mr-5"
							@click="toReport">
							<img src="../../assets/image/user/icon-report.png" class="w-[15.5px] h-[15.5px] mb-8"
								alt="report" />
							<span>举报</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Popup>
</template>

<script lang="ts" setup>
/**
 * 分享选项弹窗
 */

import { getRemoreURL, getAssetURL } from "@/utils";
import { ShareType } from "@/types/enums";
import useUserRelations from "@/hook/useUserRelations";
import { showConfirmDialog } from "@/utils/dialog";
import useTrends from "@/hook/useTrends";
import useUserShield from "@/hook/useUserShield";
import useUserBlock from "@/hook/useUserBlock";
import useShare from "@/hook/useShare";
import { useUserStore } from "@/stores/index";
import useLocale from "@/hook/useLocale";
import publish from "@/assets/image/home/publish.png";
import copy_icon from "@/assets/image/home/copy.png";
import copy_success from "@/assets/image/home/copy_success.png";
import share_icon from "@/assets/image/home/share.png";
import download from "@/assets/image/home/download.png";
import { useShareStore } from "@/stores/share";
import { watch } from "vue";

const sharePlatformList = [
	{ name: "私信和群", icon: "share/sixin.png", value: "group" },
];

const userStore = useUserStore();
const {
	i18n: { t },
} = useLocale();
const route = useRoute();
const router = useRouter();
const userRelations = useUserRelations();
const userShield = useUserShield();
const userBlock = useUserBlock();
const trendsHook = useTrends();
const share = useShare();
const shareStore = useShareStore();
const props = defineProps({
	// 是否为用户详情页，是的话样式不同
	isUserInfo: {
		type: Boolean,
		default: false,
	},
	show: {
		type: Boolean,
		default: false,
	},
	type: {
		type: String,
		default: () => "trends",
	},
	trendsInfo: {
		type: Object,
	},
	userInfo: {
		type: Object,
	},
	yourself: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits([
	"onItemClick",
	"onShield",
	"onBlack",
	"onDelete",
	"onOtherAction",
	"onClose",
]);

const data = reactive({
	userList: [],
	keyword: "",
	selection: [],
	input: "",
});
const isCopy = ref(false);

// popup 操作
const onChange = (value) => {
	if (!value) {
		close();
	}
};
const popup = ref(null);

watch(
	() => props.show,
	(val) => {
		popup.value.show = val;
		if (val) {
			isCopy.value = false;
		}
	}
);
const operationList = computed(() => {
	const list = [
		{
			name: t("publish.publishTrends"),
			value: 0,
			icon: publish,
			callback: () => {
				router.push({
					name: "publish",
				});
				close();
			},
		},
		{
			name: t("home_v2.shared"),
			value: 1,
			icon: share_icon,
		},
		{
			name: t("trends.copyLink"),
			value: 2,
			icon: isCopy.value ? copy_success : copy_icon,
			callback: () => {
				toCopy(props.trendsInfo?.trendsUrl);
			},
		},
		{
			name: t("home_v2.download"),
			value: 3,
			icon: download,
		},
	];
	return list;
});
// 建群
const buildGroup = () => {
	// 点击添加朋友
	router.push({
		name: "group-create",
	});
	close();
};
// 动态分享

const submit = () => {
	shareStore.shareTo(data.userList[0], ShareType.friends, false);
	const params = {
		// type: shareStore.shareConfig.type,
		targetId: shareStore.trendsInfo //目标 id，主页为用户 id，动态为动态 id，音频 id ,地点 id
			? shareStore.trendsInfo.trendsId
			: shareStore.userInfo?.userId,
		shareText: data.input, // 分享文本
		subType: shareStore.trendsInfo ? 102 : 101, // 消息类型 101：分享主页；102：分享动态；103，分享音频 104，分享地点
		userId: null,
		roomId: null,
	};
	if (shareStore.shareConfig.type === ShareType.friends) {
		params.userId = shareStore.shareConfig?.userId;
	} else if (shareStore.shareConfig.type === ShareType.group) {
		params.roomId = shareStore.shareConfig.roomId;
	}

	console.log("分享", params);
	// console.log(params)
	return share.shareToZIM(params).then((res) => {
		if (res) {
			emit("onClose", true);
		}
	});
};
// 选择人员
const selectItem = (row) => {
	const index = data.selection.findIndex((val) => val == row.id);
	if (index > -1) {
		data.selection.splice(index, 1);
	} else {
		data.selection.push(row.id);
	}
};
const close = () => {
	emit("onClose");
};

const shareToUser = (item) => {
	emit("onItemClick", toRaw(item), ShareType.friends);
	close();
};

const onClickMore = () => {
	router.push({
		name: "choose-contact",
		query: {
			from: "share",
			type: "user",
		},
	});
	close();
};

// 未登录的操作，直接跳转到登录页面
const checkLogin = () => {
	if (userStore.isLogin) {
		return true;
	} else {
		router.push({
			name: "login",
			query: {
				redirect: route.fullPath,
				...route.query,
			},
		});
		return false;
	}
};

// 是否分享自己的主页
const isYourself = computed(() => {
	return (
		props.yourself || (props.userInfo && props.userInfo?.userId === userStore.userInfo?.userId)
	);
});

// 复制链接
const toCopy = (url) => {
	url && copyTo(url)
};
function copyTo(content) {
	const input = document.createElement("input")
	input.value = content
	document.body.appendChild(input)
	input.select()
	input.setSelectionRange(0, input.value.length), document.execCommand('Copy')
	document.body.removeChild(input)
	showToast(t("toast.copySuccess"))
	isCopy.value = true
}
// 单击非分享对象的其他选项
const onOtherActionClick = (action: string, done?: boolean) => {
	console.log("非分享", action, done);
	emit("onOtherAction", action, done);
};

// 转发
const toTranspond = () => {
	router.push({
		name: "publish",
		query: {
			from: "transpond",
		},
		state: {
			forwardItemInfo: toRaw(props.trendsInfo),
		},
	});
	close();
};

// 屏蔽动态
const toShieldTrends = () => {
	if (!checkLogin()) {
		close();
		return;
	}
	showConfirmDialog({
		content: "确定屏蔽此条动态，屏蔽后此条动态将 不在信息流列表显示。",
	}).then(() => {
		trendsHook.shield(props.trendsInfo.userId, props.trendsInfo.trendsId, true).then((res) => {
			if (res) {
				emit("onOtherAction", "shield-trends", true);
				close();
			}
		});
	});
};

// 屏蔽用户
const toShield = () => {
	if (!checkLogin()) {
		close();
		return;
	}
	showConfirmDialog({
		content: "确定不看 TA 的动态后，屏蔽后信息流将不在显示对方的动态",
	})
		.then(() => {
			userShield.shield(props.userInfo?.userId, true).then((res) => {
				if (res) {
					emit("onShield");
					setTimeout(() => {
						close();
					}, 100);
				}
			});
		})
		.catch(() => {
			close();
		});
};

// 黑名单
const toBlack = () => {
	if (!checkLogin()) {
		close();
		return;
	}
	showConfirmDialog({
		content: `确定将${props.userInfo.nickname}拉黑，拉黑后对方无法搜索到你，也无法给您发私信。`,
	})
		.then(() => {
			userBlock.block(props.userInfo?.userId, true).then((res) => {
				if (res) {
					emit("onBlack");
					onOtherActionClick("black", true);
					setTimeout(() => {
						close();
					}, 100);
				}
			});
		})
		.catch(() => {
			close();
		});
};

// 举报
const toReport = () => {
	if (!checkLogin()) {
		close();
		return;
	}
	const params = {
		name: "report",
		query: null,
	};
	if (props.type === "user") {
		params.query = { userId: props.userInfo?.userId };
	} else if (props.type === "trends" || props.type === "video") {
		params.query = { trendsId: props.trendsInfo?.trendsId };
	}
	close();
	router.push(params);
};

const shoareToPlatform = (item) => {
	if (item.value === "group") {
		if (!checkLogin()) {
			close();
			return;
		}
		router.push({
			name: "choose-contact",
			query: {
				from: "share",
				type: "all",
			},
		});
	} else if (item.value === "facebook") {
		let shareUrl;
		if (props.trendsInfo) {
			shareUrl = props.trendsInfo.trendsUrl;
		} else if (props.userInfo) {
			shareUrl = props.userInfo.userUrl;
		}
		shareUrl && share.shareToFacebook(shareUrl);
	} else if (item.value === "telegram") {
		let shareUrl;
		if (props.trendsInfo) {
			shareUrl = props.trendsInfo.trendsUrl;
		} else if (props.userInfo) {
			shareUrl = props.userInfo.userUrl;
		}
		shareUrl && share.shareToTelegram(shareUrl);
	} else {
		if (!checkLogin()) {
			close();
			return;
		}
		emit("onItemClick", item, ShareType.platform);
	}
	close();
};
// 搜索
// const toSearch = () => {
// 	shareStore.showSearch();
// };
const getUser = () => {
	if (!userStore.isLogin) return;
	userRelations.getFriends().then((res) => {
		if (res) {
			data.userList = res.contacts;
		}
	});
};
const timer = null;
// watchEffect(() => {
// 	if (copied.value) {
// 		showToast(t("toast.copySuccess"));
// 		if (props.type === "trends") {
// 			isCopy.value = true;
// 			if (timer) clearTimeout(timer);
// 			timer = setTimeout(function () {
// 				isCopy.value = false;
// 			}, 3000);
// 			return;
// 		}
// 		close();
// 	}
// });

onMounted(() => {
	getUser();
});
</script>
<style>
.user-pop {
	padding: 12px 24px;
}

.user-pop-item {
	font-size: 14px;
	line-height: 55px;
	height: 55px;
	border-bottom: 1px solid #e8e8e8;
}

.message_v2::placeholder {
	width: 100%;
	height: 32px;
	padding: ;
	color: #999999;
}
</style>
