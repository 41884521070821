import BaseVideo from '@/components/video-slide/BaseVideo.vue'
import bus, { EVENT_KEY } from './bus'
import { h } from 'vue'

export function random(min, max) {
  const minCeiled = Math.ceil(min)
  const maxFloored = Math.floor(max)
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled) // 包含最小值和最大值
}


export function _formatNumber(num) {
  if (!num) return
  if (num > 100000000) {
    return (num / 100000000).toFixed(1) + '亿'
  } else if (num > 10000) {
    return (num / 10000).toFixed(1) + '万'
  } else {
    return num
  }
}

export function _sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}

export function cloneDeep(val) {
  return JSON.parse(JSON.stringify(val))
}

export function _stopPropagation(e: Event) {
  e.stopImmediatePropagation()
  e.stopPropagation()
  e.preventDefault()
}
export function _duration(v) {
  if (!v) return '00:00'
  const m = Math.floor(v / 60)
  // let s = v % 60
  const s = Math.round(v % 60)
  let str: string = ''
  if (m === 0) {
    str = '00'
  } else if (m > 0 && m < 10) {
    str = '0' + m
  } else {
    str = m + ''
  }
  str += ':'
  if (s === 0) {
    str += '00'
  } else if (s > 0 && s < 10) {
    str += '0' + s
  } else {
    str += s
  }
  return str
}

/**
 * 渲染音视频节点 todo 这里的属性要改
 * @param item
 * @param index
 * @param play
 * @param id
 * @returns
 */
export function renderEl({
  id,
  index,
  item,
  src,
  poster,
  muted = true,
  autoPlay = true,
  slots,
  onClick,
  onDblclick,
  onPlay,
  onPause,
  openRecharge,
  toAlbums,
  showMarkUser,
  toFollow,
  openComment,
  toShare,
  getMore
}) {
  let node
  switch (item?.type) {
    case 'img':
      node = (
        <img
          onClick={onClick}
          onDblclick={onDblclick}
          onPlay={onPlay}
          onPause={onPause}
          data-index={index}
          src={item.src}
          data-item={item}
          style="height:100%;"
          alt={''}
        />
      )
      break
    default:
      node = (
        <BaseVideo
          autoPlay={autoPlay}
          muted={muted}
          item={item}
          src={src}
          poster={poster}
          elInfo={{ id, index }}
          onClick={onClick}
          onDblclick={onDblclick}
          onPlay={onPlay}
          onPause={onPause}
          onOpenRecharge={openRecharge}
          onToAlbums={toAlbums}
          onShowMarkUser={showMarkUser}
          onToFollow={toFollow}
          onOpenComment={openComment}
          onToShare={toShare}
          onGetMore={getMore}
        >
          {slots}
        </BaseVideo>
      )
      break
  }
  return node
}

/**
 * 注册指令
 * @param app
 */
export function regiterDirective(app: any) {
  // 使 love 在所有组件中都可用
  app.directive('love', {
    /* ... */

    mounted: function (el: HTMLDivElement) {
      let isDbClick = false
      let dbClickTimer = null
      let clickTimer = null
      let lastClickTime = null
      let isDown = false
      let isMove = false
      const checkTime = 300 // 双击时间
      const dbCheckCancelTime = 500 //取消三次
      /**
       * 判断是单击还是双击
       * @param e
       * @returns
       */
      const check = (e) => {
        if (isDbClick) {
          // 防止三次单击，执行两次双击事件
          dbClickTimer = setTimeout(() => {
            isDbClick = false
            clearTimeout(dbClickTimer)
          }, dbCheckCancelTime)
          return
        }
        const nowTime = new Date().getTime()
        if (nowTime - lastClickTime < checkTime) {
          clearTimeout(clickTimer)
          dbClick(e)
          isDbClick = true
          dbClickTimer = setTimeout(() => {
            isDbClick = false
            clearTimeout(dbClickTimer)
          }, dbCheckCancelTime)
        } else {
          clickTimer = setTimeout(() => {
            click(e)
          }, checkTime)
        }
        lastClickTime = nowTime
      }

      // 单击
      const click = (event) => {
        console.log('click')
        bus.emit(EVENT_KEY.SINGLE_CLICK, { type: EVENT_KEY.SINGLE_CLICK, event: event })
      }
      // 双击
      const dbClick = (event) => {
        console.log('dbClick')
        bus.emit(EVENT_KEY.DOUBLE_CLICK, { type: EVENT_KEY.DOUBLE_CLICK, event: event })
      }

      const up = (e) => {
        if (!isDown) return
        if (!isMove && !(window as any).isMoved) check(e)
        isMove = isDown = false
      }

      el.addEventListener('pointerdown', () => (isDown = true))
      el.addEventListener('pointermove', () => isDown && (isMove = true))
      el.addEventListener('pointerup', up)
    }
  })
}
