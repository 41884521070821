<template>
	<Teleport to="body">
		<van-popup v-model:show="show" round position="bottom" :style="{ height }">
			<div class="main" >
				<div class="gray-top"></div>
				<slot></slot>
			</div>
			<slot name="footer"></slot>
		</van-popup>
	</Teleport>
</template>
<script setup>
	const props = defineProps({
		height: { type: String, default: "" },
    padding: { type: String, default: '12px 16px' }
	});
	const emit = defineEmits(["on-change"]);
	const show = ref(false);
	watch(
		() => show.value,
		(val) => {
			show.value = val;
			emit("on-change", val);
		}
	);
	defineExpose({
		show,
	});
</script>
<style scoped>
	.main {
		padding: v-bind("props.padding");
	}
	.gray-top {
		background-color: #d9d9d9;
		width: 35px;
		height: 4px;
		margin: 0 auto 8px;
		border-radius: 2px;
	}
</style>
