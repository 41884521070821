/**
 * 封装操作 localstorage 本地存储的方法
 */
export const storage = {
  // 存储
  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  // 读取
  get<T>(key: string) {
    const value = localStorage.getItem(key)
    if (value && value !== 'undefined' && value !== 'null') {
      return <T>JSON.parse(value)
    }
    return null
  },
  // 删除
  remove(key: string) {
    localStorage.removeItem(key)
  },
}

/**
 * 封装操作 sessionStorage 本地存储的方法
 */
export const sessionStore = {
  // 存储
  set(key: string, value: any) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  },
  // 读取
  get<T>(key: string) {
    const value = window.sessionStorage.getItem(key)
    if (value && value !== 'undefined' && value !== 'null') {
      return <T>JSON.parse(value)
    }
    return null
  },
  // 删除
  remove(key: string) {
    window.sessionStorage.removeItem(key)
  },
}
