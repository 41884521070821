import { defineStore } from 'pinia'
import { ShareType } from '@/types/enums'

export const useShareStore = defineStore('share', {
  state: () => ({
    showSharePopup: false,
    showShareConfirmDialog: false,
    shareConfig: null,
    trendsInfo: null,
    userInfo: null,
    shareContentType: null, // 动态：trends 用户：user 视频详情：video
    shareResultCallback: null,
    isUserInfo: false,
    otherActionCallback: null,
    showSearchPopup: false, // 搜索
    selectList: [], // 选择的人员
  }),

  actions: {
    showShare(
      type: 'trends' | 'user' | 'video',
      payload,
      callback?: (...args: any[]) => any
    ) {
      this.shareContentType = type
      this.otherActionCallback = callback
      if (type === 'trends') {
        this.trendsInfo = payload
        this.userInfo = null
        this.isUserInfo = false
      } else if (type === 'user') {
        this.userInfo = payload
        this.trendsInfo = null
      } else if (type === 'video') {
        this.trendsInfo = payload
        this.userInfo = null
        this.isUserInfo = false
      }
      this.showSharePopup = true
      this.showShareConfirmDialog = false
    },
    showConfirm(target, callback: (...args: any[]) => any) {
      console.log('showConfirm', target)
      if (!target) return
      const shareConfig = {
        type: null,
        headSculpture: target.headSculpture,
        nickname: target.nickname,
        roomId: null,
        userId: null,
      }

      if (target.type === ShareType.friends) {
        shareConfig.type = ShareType.friends
        shareConfig.userId = target.id
      } else if (target.type === ShareType.group) {
        shareConfig.type = ShareType.group
        shareConfig.roomId = target.roomId
      }
      this.shareConfig = shareConfig
      this.showSharePopup = false
      this.showShareConfirmDialog = true
      this.shareResultCallback = callback
    },
    showSearch(playload, callback?: (...args: any[]) => any) {
      this.showSearchPopup = true
      this.selectList = []
    },
    selectSearch(playload) {
      const index = this.selectList.find((val) => val.id == playload.id)
      if (index > -1) {
        this.selectList.splice(index, 1)
      } else {
        this.selectList.push(playload)
      }
    },
    closeSearch() {
      this.showSearchPopup = false // 搜索
      this.selectList = [] // 选择的人员
    },
    shareTo(item, shareType, isShowConfirmDialog = true) {
      console.log('share to', item, shareType)
      const shareConfig = {
        type: null,
        headSculpture: item.headSculpture,
        nickname: item.nickname,
        roomId: null,
        userId: null,
      }

      if (item.type === ShareType.friends) {
        shareConfig.type = ShareType.friends
        shareConfig.userId = item.id
      } else if (item.type === ShareType.group) {
        shareConfig.type = ShareType.group
        shareConfig.roomId = item.roomId
      }
      this.shareConfig = shareConfig
      if (isShowConfirmDialog) {
        this.showSharePopup = false
        this.showShareConfirmDialog = true
      }
    },
    // 分享结果
    onShareResult(val) {
      if (val) {
        if (this.shareResultCallback) {
          this.shareResultCallback(val)
        }
      }
      this.isUserInfo = false
      this.showShareConfirmDialog = false
    },
    close() {
      this.reset()
    },
    reset() {
      this.shareContentType = null
      this.trendsInfo = null
      this.userInfo = null
      this.shareConfig = null
      this.isUserInfo = false
      this.showSharePopup = false
      this.showShareConfirmDialog = false
      this.shareResultCallback = null
      this.otherActionCallback = null
      this.showSearchPopup = false // 搜索
      this.selectList = [] // 选择的人员
    },
  },
})
