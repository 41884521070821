import { createRouter, createWebHistory } from 'vue-router'
import { storage } from '@/utils/storageUtil'
import { KEY_Authorization } from '@/utils/constant'
import { useUserStore } from '@/stores'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: 'home',
      meta: {
        keepAlive: true,
      },
    },
    {
      path: '/home',
      name: 'home',
      meta: {
        keepAlive: true,
      },
      component: () => import('@/views/home/home.vue'),
    },
    {
      path: '/contact',
      name: 'contact',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/address-book/index.vue'),
    },
    {
      path: '/message',
      name: 'message',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/message/message.vue'),
    },
    {
      path: '/mine',
      name: 'mine',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/mine/mine.vue'),
    },
    {
      path: '/search',
      name: 'search',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/search/index.vue'),
    },
    {
      path: '/search-result',
      name: 'search-result',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/search/result.vue'),
    },
    {
      path: '/publish',
      name: 'publish',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/publish/publish.vue'),
    },
    {
      path: '/topic-detail',
      name: 'topic-detail',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/topic-detail/topic-detail.vue'),
    },
    {
      path: '/trend-detail/:id',
      name: 'trend-detail',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/trend-detail/trend-detail.vue'),
    },
    // 动态详情 - 点赞明细
    {
      path: '/like-list/:id',
      name: 'like-list',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/like-list/like-list.vue'),
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/login/index.vue'),
    },
    {
      path: '/email-login',
      name: 'email-login',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/login/email.vue'),
    },
    {
      path: '/phone-login',
      name: 'phone-login',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/login/phone.vue'),
    },
    {
      path: '/register',
      name: 'register',
      meta: {
        keepAlive: false,
      },
      component: () => import('../views/register/register.vue'),
    },
    {
      path: '/email-register',
      name: 'email-register',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/register/email.vue'),
    },
    {
      path: '/code',
      name: 'code',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/code/index.vue'),
    },
    {
      path: '/email-code',
      name: 'email-code',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/code/emailcode.vue'),
    },
    {
      path: '/create-pwd',
      name: 'create-pwd',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/code/phonecreate.vue'),
    },
    {
      path: '/create-emailpwd',
      name: 'create-emailpwd',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/code/emailcreate.vue'),
    },
    {
      path: '/create-invite',
      name: 'create-invite',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/code/invite.vue'),
    },
    {
      path: '/email-invite',
      name: 'email-invite',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/code/emailinvite.vue'),
    },
    {
      path: '/forgot',
      name: 'forgot',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/forgot-password/index.vue'),
    },
    // 新粉丝通知
    {
      path: '/new-fans',
      name: 'new-fans',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/new-fans/list.vue'),
    },
    // 互动消息（点赞评论）
    {
      path: '/comment-list',
      name: 'comment-list',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/comment-list/index.vue'),
    },
    // 系统通知
    {
      path: '/system-notice',
      name: 'system-notice',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/system-notice/list.vue'),
    },
    // 系统通知详情 (app)
    {
      path: '/app/system-notice-detail/:id',
      name: 'system-notice-detail-in-app',
      component: () => import('../views/system-notice/detailInApp.vue'),
    },
    // 系统通知详情
    {
      path: '/system-notice-detail/:id',
      name: 'system-notice-detail',
      component: () => import('../views/system-notice/detail.vue'),
    },
    // 系统通知设置
    {
      path: '/system-notice-info/:uid',
      name: 'system-notice-info',
      component: () => import('../views/system-notice/systemInfo.vue'),
    },
    // 聊天 (私聊)
    {
      path: '/chat/:uid',
      name: 'chat',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/chat/chat.vue'),
    },
    // 聊天用户信息
    {
      path: '/chat-info/:uid',
      name: 'chat-info',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/chat-info/chat-info.vue'),
    },
    // 群聊
    {
      path: '/group-chat/:gid',
      name: 'group-chat',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/group-chat/group-chat.vue'),
    },
    // 群聊天信息
    {
      path: '/group-chat-info/:gid',
      name: 'group-chat-info',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/group-chat/group-chat-info.vue'),
    },
    // 群管理
    {
      path: '/group-management',
      name: 'group-management',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/group-chat/group-management.vue'),
    },
    // 群公告
    {
      path: '/group-announcement',
      name: 'group-announcement',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/group-chat/group-announcement.vue'),
    },
    // 发布群公告
    {
      path: '/group-announcement-edit',
      name: 'group-announcement-edit',
      // meta: {
      //   keepAlive: true
      // },
      component: () =>
        import('../views/group-chat/group-announcement-edit.vue'),
    },
    // 查看聊天记录
    {
      path: '/chat-history',
      name: 'chat-history',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/chat-history/index.vue'),
    },
    // 建群
    {
      path: '/group-create',
      name: 'group-create',
      meta: {
        keepAlive: false,
      },
      props: (route) => route.query,
      component: () => import('../views/group-chat/group-create.vue'),
    },
    {
      path: '/create',
      name: 'create',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/create/create.vue'),
    },
    // 添加成员
    {
      path: '/group-member-add',
      name: 'group-member-add',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/group-chat/group-member-add.vue'),
    },
    // 积分充值
    {
      path: '/gold-recharge',
      name: 'gold-recharge',
      meta: {
        keepAlive: false,
      },
      component: () => import('../views/my-gold/components/goldrecharge.vue'),
    },
    // 我的积分
    {
      path: '/mypoints',
      name: 'mypoints',
      meta: {
        keepAlive: false,
      },
      component: () => import('../views/my-gold/components/mygold.vue'),
    },
    // 三方支付
    {
      path: '/thirdrecharge',
      name: 'thirdrecharge',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/my-gold/components/thirdrecharge.vue'),
    },
    // 服务条款
    {
      path: '/policy',
      name: 'policy',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/login/policy.vue'),
    },
    // 作品详情
    {
      path: '/workdetail',
      name: 'workdetail',
      meta: {
        keepAlive: false,
      },
      component: () => import('../views/work-detail/index.vue'),
    },
    // 群成员
    {
      path: '/group-members',
      name: 'group-members',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/group-chat/group-members.vue'),
    },
    // 删除群成员
    {
      path: '/group-member-delete',
      name: 'group-member-delete',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/group-chat/group-member-delete.vue'),
    },
    // 设置管理员
    {
      path: '/group-manager',
      name: 'group-manager',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/group-chat/group-manager.vue'),
    },
    // 添加管理员
    {
      path: '/group-manager-add',
      name: 'group-manager-add',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/group-chat/group-manager-add.vue'),
    },
    // 禁言时长
    {
      path: '/mute-time',
      name: 'mute-time',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/group-chat/mute-time.vue'),
    },
    // 禁言列表
    {
      path: '/group-mute-members',
      name: 'group-mute-members',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/group-chat/group-mute-members.vue'),
    },
    // 转让群组
    {
      path: '/group-transfer',
      name: 'group-transfer',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/group-chat/group-transfer.vue'),
    },
    // 修改群昵称
    {
      path: '/group-name-edit',
      name: 'group-name-edit',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/group-chat/group-name-edit.vue'),
    },
    {
      path: '/person-profile',
      name: 'person-profile',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/person-profile/index.vue'),
    },
    {
      path: '/transpond',
      name: 'transpond',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/transpond/index.vue'),
    },
    {
      path: '/choose-contact',
      name: 'choose-contact',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/choose-contact/choose-contact.vue'),
    },
    {
      path: '/choose-country-code',
      name: 'choose-country-code',
      component: () =>
        import('../views/choose-country-code/choose-country-code.vue'),
    },
    {
      path: '/choose-province-code',
      name: 'choose-province-code',
      meta: {
        keepAlive: true,
      },
      component: () =>
        import('../views/choose-province-code/choose-province-code.vue'),
    },
    {
      path: '/choose-city-code',
      name: 'choose-city-code',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/choose-city-code/choose-city-code.vue'),
    },
    {
      path: '/choose-area-code',
      name: 'choose-area-code',
      component: () => import('../views/choose-area-code/choose-area-code.vue'),
    },
    // 视频详情页面
    {
      path: '/video-detail',
      name: 'video-detail',
      meta: {
        keepAlive: false, // 先去掉
      },
      component: () => import('../views/video/detail.vue'),
    },
    // 投票页面
    {
      path: '/vote/:id',
      name: 'vote',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/vote/index.vue'),
    },
    // 查看用户主页
    {
      path: '/user-info/:uid',
      name: 'user-info',
      meta: {
        keepAlive: false,
      },
      component: () => import('../views/user-info/index.vue'),
    },
    // 用户主页动态搜索
    {
      path: '/user-info-search',
      name: 'user-info-search',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/user-info/search.vue'),
    },
    // 举报
    {
      path: '/report',
      name: 'report',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/report/index.vue'),
    },
    // 通讯录
    // {
    //   path: '/address-book',
    //   name: 'address-book',
    //   component: () => import('../views/address-book/index.vue')
    // },
    // 通讯录群聊列表
    {
      path: '/group-list',
      name: 'group-list',
      meta: {
        keepAlive: true,
      },
      // component: () => import('../views/address-book/components/groupList.vue')
      component: () => import('../views/address-book/group-list.vue'),
    },
    // 通讯录标签分组列表
    {
      path: '/label-list',
      name: 'label-list',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/address-book/components/labelList.vue'),
    },
    // 通讯录标签分组详情
    {
      path: '/label-detail',
      name: 'label-detail',
      // meta: {
      //   keepAlive: true
      // },
      component: () =>
        import('../views/address-book/components/labelDetail.vue'),
    },
    // 通讯录添加标签分组
    {
      path: '/add-label',
      name: 'add-label',
      component: () => import('../views/address-book/components/addLabel.vue'),
    },
    // 通讯录删除或添加成员
    {
      path: '/add-or-del',
      name: 'add-or-del',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/address-book/components/addOrDel.vue'),
    },
    // 修改可见范围
    {
      path: '/edit-visible',
      name: 'edit-visible',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/mine/components/editVisible.vue'),
    },
    // 修改可见范围->选择分组、选择联系人
    {
      path: '/select-page',
      name: 'select-page',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/mine/components/selectPage.vue'),
    },
    // 我的资料
    {
      path: '/mine-info',
      name: 'mine-info',
      meta: {
        keepAlive: false,
      },
      component: () => import('../views/mine/components/mineInfo.vue'),
    },
    // 我的资料关注列表、粉丝列表
    {
      path: '/fans-and-follow/:uid',
      name: 'fans-and-follow',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/mine/components/fansAndFollow.vue'),
    },
    // 编辑我的资料
    {
      path: '/edit-info',
      name: 'edit-info',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/mine/components/editInfo.vue'),
    },
    // 我的积分
    {
      path: '/my-gold',
      name: 'my-gold',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/my-gold/index.vue'),
    },
    // 我的积分明细
    {
      path: '/gold-detail',
      name: 'gold-detail',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/my-gold/components/goldDetail.vue'),
    },
    {
      path: '/gold-detail',
      name: 'gold-detail',
      meta: {
        keepAlive: false,
      },
      component: () => import('../views/my-gold/components/goldDetail.vue'),
    },
    {
      path: '/gold-withdraw',
      name: 'gold-withdraw',
      meta: {
        keepAlive: false,
      },
      component: () => import('../views/my-gold/components/goldwithdraw.vue'),
    },
    {
      path: '/withdraw',
      name: 'withdraw',
      // meta: {
      //   keepAlive: false,
      // },
      component: () => import('../views/my-gold/components/withdraw.vue'),
    },
    // 我的积分积分规则
    {
      path: '/gold-rule',
      name: 'gold-rule',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/my-gold/components/goldRule.vue'),
    },
    // 设置
    {
      path: '/setting',
      name: 'setting',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/setting/index.vue'),
    },
    {
      path: '/setting-privacy',
      name: 'setting-privacy',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/setting/settingprivacy.vue'),
    },
    // 隐私设置
    {
      path: '/privacy-setting',
      name: 'privacy-setting',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/setting/components/privacy.vue'),
    },
    // 隐私页面
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('../views/setting/privacy.vue'),
    },
    // 通知设置
    {
      path: '/notice-setting',
      name: 'notice-setting',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/setting/components/notice.vue'),
    },
    // 语言设置
    {
      path: '/language-setting',
      name: 'language-setting',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/setting/components/language.vue'),
    },
    // 已付费内容
    {
      path: '/paid',
      name: 'paid',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/setting/components/paid.vue'),
    },
    // 屏蔽设置
    {
      path: '/block-setting',
      name: 'block-setting',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/setting/components/block.vue'),
    },
    // 黑名单设置
    {
      path: '/blacklist-setting',
      name: 'blacklist-setting',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/setting/components/blacklist.vue'),
    },
    // 账号与安全设置
    {
      path: '/account-manage',
      name: 'account-manage',
      meta: {
        keepAlive: false,
      },
      component: () => import('../views/setting/components/account.vue'),
    },
    // 更改密码界面
    {
      path: '/change-password',
      name: 'change-password',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/setting/components/password.vue'),
    },
    // 更改手机号界面
    {
      path: '/change-email',
      name: 'change-email',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/setting/components/email.vue'),
    },
    // 更改邮箱界面
    {
      path: '/change-phone',
      name: 'change-phone',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/setting/components/phone.vue'),
    },
    // 更改手机获取验证码
    {
      path: '/change-code',
      name: 'change-code',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/setting/components/code.vue'),
    },
    // {
    //   path: '/test',
    //   name: 'test',
    //   component: () => import('../views/test/index.vue')
    // }
    {
      path: '/chat-record-search',
      name: 'chat-record-search',
      component: () => import('../views/message/chatRecordSearch.vue'),
    },
    {
      path: '/attention',
      name: 'attention',
      component: () => import('@/views/attention/index.vue'),
    },
    {
      path: '/collect',
      name: 'collect',
      component: () => import('@/views/collect/index.vue'),
    },
    {
      path: '/notification',
      name: 'notification',
      component: () => import('@/views/notification/index.vue'),
    },
    {
      path: '/userProfile',
      name: 'userProfile',
      props: (route) => route.query,
      component: () => import('@/views/userProfile/index.vue'),
    },
    // 收藏夹
    {
      path: '/favorites',
      name: 'favorites',
      component: () => import('../views/mine/favorites.vue'),
    },
    // 所有动态
    {
      path: '/all-post',
      name: 'all-post',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/mine/allPost.vue'),
    },
    // 你的动态
    {
      path: '/your-activity',
      name: 'your-activity',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/mine/yourActivity.vue'),
    },
    // 点赞内容
    {
      path: '/likes',
      name: 'likes',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/mine/likes.vue'),
    },
    // 评论内容
    {
      path: '/comments',
      name: 'comments',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/mine/comments.vue'),
    },
    // 搜索页面
    {
      path: '/search-record',
      name: 'search-record',
      meta: {
        keepAlive: true,
      },
      component: () => import('../views/search/search.vue'),
    },
    // 添加链接
    {
      path: '/add-link',
      name: 'add-link',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/mine/components/addLink.vue'),
    },
    // 标记用户
    {
      path: '/mark',
      name: 'mark',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/publish/mark.vue'),
    },
    {
      path: '/promotion',
      name: 'promotion',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/mine/promotion.vue'),
    },
    // 音频
    {
      path: '/audio',
      name: 'audio',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/audio/audio.vue'),
    },
    // 扫一扫
    {
      path: '/scan',
      name: 'scan',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/mine/scan.vue'),
    },
    // 音频收藏
    {
      path: '/all-audio',
      name: 'all-audio',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/mine/allAudio.vue'),
    },
    // 地点收藏
    {
      path: '/all-position',
      name: 'all-position',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/mine/allPosition.vue'),
    },
    // 地点详情页
    {
      path: '/position',
      name: 'position',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/position/position.vue'),
    },
    // 话题页
    {
      path: '/topic',
      name: 'topic',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/topic/topic.vue'),
    },
    // 动态列表页
    {
      path: '/trends-list',
      name: 'trends-list',
      meta: {
        keepAlive: false,
      },
      component: () => import('../views/trendsList/trendsList.vue'),
    },
    // 发现用户 推荐用户
    {
      path: '/all-recommend',
      name: 'all-recommend',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/allRecommend/allRecommend.vue'),
    },
    // 曾用账号
    {
      path: '/previously',
      name: 'previously',
      // meta: {
      //   keepAlive: true
      // },
      component: () => import('../views/userProfile/previouslyAccount.vue'),
    },
    // 消息搜索页面
    {
      path: '/message-search',
      name: 'message-search',
      component: () => import('../views/message/messageSearch.vue'),
    },
    // app 下载
    {
      path: '/app-download',
      name: 'app-download',
      component: () => import('../views/app-download/index.vue'),
    },
  ],
  // @ts-ignore
  // scrollBehavior (to, from, scrollBehavior) {
  //   console.log('scrollBehavior', to, from, scrollBehavior)
  //   if (scrollBehavior) {
  //     return scrollBehavior
  //   } else {
  //     return { top: 0 }
  //   }
  // }
})

// 白名单，不需要登录
// 鉴权未登录可查看页面：首页 - 热门，首页 - 搜索页，首页 - 动态详情页、点赞列表页，话题列表页、话题详情页，用户主页、关注列表、粉丝列表
const whiteList = [
  'login',
  'email-login',
  'phone-login',
  'email-code',
  'create-pwd',
  'create-emailpwd',
  'create-invite',
  'email-invite',
  'register',
  'email-register',
  'forgot',
  'code',
  'choose-country-code',
  'home',
  'search',
  'policy',
  'privacy',
  'search-result',
  'trend-detail',
  'video-detail',
  'like-list',
  'topic-detail',
  'user-info',
  'fans-and-follow',
  'system-notice-detail-in-app',
  'app-download',
]

export const singlePages = ['app-download']

router.beforeEach(async (to: any, from, next) => {
  const userStore = useUserStore()
  // 首次进入获取公钥以及生成私钥
  if (!userStore.backendKey) {
    await userStore.getKeys()
    await userStore.refreshKeys()
  }
  if (to.name === from.name) {
    to.meta.refresh = true
  }
  if (whiteList.includes(to.name)) {
    // 已经登录
    if (to.name === 'login' && storage.get(KEY_Authorization)) {
      userStore.getUserConfig()
      next('/home')
    } else {
      next()
    }
  } else {
    // 判断是否已登录
    if (storage.get(KEY_Authorization)) {
      userStore.getUserConfig()
      next()
    } else {
      next('/login')
    }
  }
})

export default router
