import { userBlock } from '@/api/user'
import { merchantCode } from '@/config/index'

/**
 * 用户拉黑
 * @returns 
 */
export default function useUserBlock() {

  /**
   * 用户 拉黑/取消 操作
   * @param toUserId 操作的用户
   * @param type true是拉黑 false是取消拉黑
   * @returns 
   */
  function block(toUserId: string, type: boolean) {
    return userBlock({
      merchantCode,
      toUserId,
      type: type ? 0 : 1 // 操作类型 0拉黑 1取消拉黑
    }).then(res => {
      res?.resultMsg && showToast(res.resultMsg)
      if (res?.resultCode === 1) {
        return res || true
      }
    })
  }

  return {
    block
  }
}
