import { get, post } from '@/utils/httpRequest.ts'

/**
 * 生成token
 * @param data
 * @returns
 */
export function generateToken(data) {
  return get('/im/generateToken', data)
}

/**
 * 创建群聊，群头像、名称
 * @param data
 * @returns
 */
export function createRoom(data) {
  return post('/room/crateRoom', data)
}

/**
 * 创建群聊，用户列表
 * @param data
 * @returns
 */
export function createRoomNoFile(data) {
  return post('/room/crateRoom/noFile', data)
}

/**
 * 查询用户群聊
 */
export function getRoom(data) {
  return post('/room/getRoom', data)
}

/**
 * 添加/移除群成员
 */
export function actionRoomMember(data) {
  return post('/room/actionRoomMember', data)
}

/**
 * 用户退出群聊
 */
export function exitRoom(data) {
  return post('/room/exitRoom', data)
}

/**
 * 获取群管理信息
 */
export function getRoomManagementInfo(data) {
  return post('/room/getRoomManagementInfo', data)
}

/**
 * 获取群聊成员
 */
export function getRoomUser(data) {
  return post('/room/getRoomUser', data)
}

/**
 * 查询用户好友
 * 创建/添加群成员群聊时查询用户好友（互相关注，拉群需要）
 */
export function getUserFriendByName(data) {
  return post('/room/getUserFriendByName', data)
}

/**
 * 获取群聊的基本信息
 * 获取群聊的登入人群聊基本信息 如是否置顶，状态
 */
export function getUserRoomInfo(data) {
  return post('/room/getUserRoomInfo', data)
}

/**
 * 禁言用户
 */
export function prohibitionUser(data) {
  return post('/room/prohibitionUser', data)
}

/**
 * 撤回消息(群聊)
 */
export function recallGroupMsg(data) {
  return post('/room/recallMsg', data)
}

/**
 * 群公告查看
 */
export function viewRoomNotice(data) {
  return post('/room/viewRoomNotice', data)
}

/**
 * 发布群公告
 */
export function releaseRoomNotice(data) {
  return post('/room/releaseRoomNotice', data)
}

/**
 * 群聊聊天记录搜索
 */
export function roomChatSearch(data) {
  return post('/room/roomChatSearch', data)
}

/**
 * 群主解散群聊
 */
export function roomDissolve(data) {
  return post('/room/roomDissolve', data)
}

/**
 * 用户举报群聊
 */
export function roomReport(data) {
  return post('/room/roomReport', data)
}

/**
 * 发送消息保存(群聊)
 */
export function sendRoomMsg(data) {
  return Promise.resolve({})
  // return post('/room/sendRoomMsg/noFile', data)
}

/**
 * 设置管理员
 */
export function setRoomAdmin(data) {
  return post('/room/setRoomAdmin', data)
}

/**
 * 设置群禁言
 */
export function settingRoomProhibition(data) {
  return post('/room/settingRoomProhibition', data)
}

/**
 * 转让群聊
 */
export function transferRoom(data) {
  return post('/room/transferRoom', data)
}

/**
 * 修改群头像
 */
export function updateRoomAvatar(data) {
  return post('/room/updateRoomAvatar', data)
}

/**
 * 修改群昵称
 */
export function updateRoomName(data) {
  const { merchantCode, roomId, name } = data
  return post(
    `/room/updateRoomName?merchantCode=${merchantCode}&roomId=${roomId}&name=${name}`,
    data,
  )
}

/**
 * 清空聊天记录(群聊)
 */
export function userClearRoomChatRecords(data) {
  return post('/room/userClearRoomChatRecords', data)
}

/**
 * 用户设置群聊
 */
export function userSettingRoom(data) {
  return post('/room/userSettingRoom', data)
}

/**
 * 删除单条消息 (群聊)
 */
export function deleteGroupMsg(data) {
  return post('/friend/deleteMag', data)
}

/**
 * 删除单条消息 (私聊)
 */
export function deletePeerMsg(data) {
  return post('/friend/deleteMag', data)
}

/**
 * 单聊聊天记录搜索
 */
export function friendChatSearch(data) {
  return post('/friend/friendChatSearch', data)
}

/**
 * 获取聊天信息
 */
export function getFriendInfo(data) {
  return post('/friend/getFriendInfo', data)
}

/**
 * 获取系统信息聊天信息
 */
export function getSystemChat(data) {
  return post('/friend/getSystemChat', data)
}

/**
 * 撤回消息(私聊)
 */
export function recallPeerMsg(data) {
  return post('/friend/recallMsg', data)
}

/**
 * 发送消息保存(私聊)
 */
export function sendFriendMsg(data) {
  return Promise.resolve({})
  // return post('/friend/sendFriendMsg/noFile', data)
}

/**
 * 设置朋友属性，如置顶聊天等
 */
export function settingFriend(data) {
  return post('/friend/settingFriend', data)
}

/**
 * 获取系统信息聊天信息，系统通知
 */
export function systemChatSetting(data) {
  return post('/friend/systemChatSetting', data)
}

/**
 * 清空聊天记录
 */
export function userClearChatRecords(data) {
  return post('/friend/userClearRoomChatRecords', data)
}

/**
 * 聊天列表接口 (有未读消息数据)
 */
export function getChat(data) {
  return post('/chat/getChat', data)
}

/**
 * 删除聊天窗口
 */
export function delChatWindow(data) {
  return post('/chat/delChatWindow', data)
}

/**
 * 聊天首页搜索
 */
export function chatIndexSearch(data) {
  return post('/chat/chatIndexSearch', data)
}

/**
 * 聊天首页聊天记录搜索
 */
export function chatIndexLogSearch(data) {
  return post('/chat/chatIndexLogSearch', data)
}

/**
 * 聊天列表接口获取聊天消息
 */
export function getChatInfo(data) {
  return post('/chat/getChatInfo', data)
}
