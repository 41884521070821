import { envIsProd } from '@/config/env'

const ossDomain = 'https://markcoin-oss.oss-ap-southeast-1.aliyuncs.com'

/** 公共 Icon OSS 地址 */
const getOssIconAddress = () => {
  const environmentPrefix = envIsProd ? 'prod' : 'dev'
  return `${ossDomain}/91love/icon/${environmentPrefix}`
}

/**
 * 公共 OSS image 地址
 * @param type 文件类型:image/json
 * @param isHasDomain 是否需要域名
 * @returns
 */
const getOssFolderAddress = (type = 'image', isHasDomain = true) => {
  let url = `/91love/${type}/`
  if (isHasDomain) url = `${ossDomain}${url}`
  return url
}

export const oss_domain = ossDomain

/** h5 OSS 公共文件地址 */
export const oss_domain_address = `${ossDomain}/common`

/** h5 OSS 非渐变色 svg 地址 */
export const oss_svg_domain_address = `${getOssIconAddress()}/${`91love_icon.js`}`

/** h5 带商户的 OSS 地址 */
export const oss_domain_address_with_business = `${ossDomain}/`

/** h5 带商户的 OSS json 地址 */
export const oss_json_domain_address = getOssFolderAddress('json')
/** h5 带商户的 OSS json 地址 */
export const oss_image_domain_address = getOssFolderAddress('image')

/** 国家国旗图片 png 地址 */
export const oss_area_code_image_domain_address = `${ossDomain}/common/area_code_img/`

export function getOssImage(path) {
  return `${oss_image_domain_address}${path}`
}
