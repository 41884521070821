<template>
  <div :class="['charge-tag']">
    {{ t('create.payment') }}
  </div>
</template>

<script setup lang="ts">
const t = (window as any).$t;
const props = defineProps({
  inner: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
})
</script>

<style scoped>
.charge-tag {
  @apply rounded box-border h-18 w-[32px] flex items-center justify-center text-xs;
  color: var(--button_text_02);
  background: var(--auxiliary_color_08);
}
</style>
