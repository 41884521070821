import { defineStore } from 'pinia'
import JSEncrypt from 'jsencrypt'
import {
  login,
  logout,
  register,
  getUserInfo,
  getSecretKeys,
  googleLogin,
} from '@/api/user'
import { generateToken } from '@/api/chat'
import { storage } from '@/utils/storageUtil'
import { KEY_Authorization, USER_CONFIG } from '@/utils/constant'
import { merchantCode } from '@/config/index'
import useZimStore from './zim'
import { ZIMErrorCode } from '@/types/enums'

type UserConfig = {
  topicTip: boolean
}

const userDefaultConfig: UserConfig = {
  topicTip: true,
}
function generateRandomKey() {
  const charPool =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const randomPrefixLength = 4
  let randomPrefix = ''

  for (let i = 0; i < randomPrefixLength; i++) {
    const index = Math.floor(Math.random() * charPool.length)
    randomPrefix += charPool[index]
  }

  const timestamp = Math.floor(Date.now() / 1000).toString()

  const randomSuffixSource = randomPrefix + timestamp

  const hashBuffer = new TextEncoder().encode(randomSuffixSource)
  return crypto.subtle.digest('SHA-256', hashBuffer).then((hashArrayBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashArrayBuffer))
    const hashString = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('')
      .substring(0, 18)

    const randomKey = randomPrefix + timestamp + hashString
    return randomKey
  })
}

export const useUserStore = defineStore('user', {
  persist: true,
  state: () => ({
    muteState: false, // 默认声音状态
    isLogin: false,
    isClearAlbum: false, // 是否需要清除合集页面
    homeState: false,
    trendsInfo: false,
    refreshState: false, //密钥是否过期触发重新刷新页面接口请求；
    userInfo: null,
    zegoToken: null,
    invitationLetter: 0,
    intervalId: 0,
    publicKey: null,
    privateKey: null,
    backendKey: null,
    deviceId: undefined,
  }),
  getters: {
    // 用户应用设置语言 zh 中文 en 英语
    language(state) {
      return (
        state.userInfo?.userNoticeSetting?.language ||
        localStorage.getItem('lang') ||
        ''
      )
    },
    // 动态文章翻译设置语言
    translateLanguage(state) {
      return (
        state.userInfo?.userNoticeSetting?.translateLanguage ||
        localStorage.getItem('lang') ||
        ''
      )
    },
  },
  actions: {
    logout() {
      logout()
    },
    loginOut() {
      try {
        this.isLogin = false
        this.userInfo = null
        this.zegoToken = null
        storage.set(KEY_Authorization, null)
        const zimStore = useZimStore()
        zimStore.logout(true)
        this.setInvitationKey(null)
        this.logout()
      } catch (error) {
        console.error('Error during logout:', error)
      }
    },
    _setUserInfo(data) {
      storage.set(KEY_Authorization, data.authorization);
      this.userInfo = data;
      this.isLogin = true;
      this.getUserInfo();
    },
    setLoginState(value: boolean) {
      this.isLogin = value
    },
    setDeviceId(deviceId) {
      this.deviceId = deviceId
    },
    setTrendsInfo(info) {
      this.trendsInfo = info
    },
    setRefreshState() {
      this.refreshState = !this.refreshState
    },
    async register(payload) {
      try {
        const res = await register(payload)
        if (res?.resultCode === 1) {
          storage.set(KEY_Authorization, res.data.authorization)
          this.userInfo = res.data
          this.isLogin = true
          return res.data
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      } catch (error) {
        showToast(error)
      }
    },
    async login(payload) {
      try {
        const res = await login(payload)
        if (res?.resultCode === 1) {
          this._setUserInfo(res.data)
          return res.data
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      } catch (error) {
        showToast(error)
      }
    },
    async googleLogin(payload) {
      try {
        const res = await googleLogin(payload);
        if (res?.resultCode === 1) {
          this._setUserInfo(res.data);
          return res.data;
        } else {
          res?.resultMsg && showToast(res.resultMsg);
        }
      } catch (error) {
        showToast(error);
      }
    },
    async getKeys() {
      generateRandomKey().then((e) => {
        this.privateKey = e
      })
      try {
        const res = await getSecretKeys()
        if (res?.resultCode === 1) {
          this.publicKey = res.data
          // 使用公钥加密对称密钥
          const encrypt = new JSEncrypt()
          encrypt.setPublicKey(this.publicKey)
          this.backendKey = encrypt.encrypt(this.privateKey)
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      } catch (error) {
        showToast(error)
      }
    },
    async refreshKeys() {
      await this.getKeys()
      // generateRandomKey().then((e) => {
      //   this.privateKey = e
        // 使用公钥加密对称密钥
        // const encrypt = new JSEncrypt()
        // encrypt.setPublicKey(this.publicKey)
        // this.backendKey = encrypt.encrypt(this.privateKey)
      // })
    },
    startKeyGeneration() {
      if (this.isLogin) {
        this.refreshKeys() // 初始化时生成一次密钥
        this.intervalId = setInterval(() => {
          this.refreshKeys()
        }, 60000) // 每分钟重新计算一次密钥
      }
    },
    setInvitationKey(val) {
      this.invitationLetter = val
    },
    async getUserInfo() {
      this.getKeys()
      try {
        const res = await getUserInfo()
        if (res?.resultCode === 1) {
          this.userInfo = res.data
          this.getUserConfig(res.data.userId)
          this.generateToken()
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      } catch (error) {
        showToast(error)
      }
    },
    // 仅刷新服务端用户数据
    async refreshUserInfo() {
      try {
        const res = await getUserInfo()
        if (res?.resultCode === 1) {
          this.userInfo = res.data
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      } catch (error) {
        showToast(error)
      }
    },
    updateUserInfo(payload) {
      this.userInfo = payload
    },
    async homestateChange() {
      this.homeState = !this.homeState
    },
    async changeAlbumState() {
      this.isClearAlbum = !this.isClearAlbum
    },
    async reLogin(error){
      if (
        [
          ZIMErrorCode.NetworkModuleCommonError,
          ZIMErrorCode.NetworkModuleTokenExpired,
          ZIMErrorCode.NetworkModuleTokenTimeIsTooShort,
          ZIMErrorCode.NetworkModuleTokenInvalid,
          ZIMErrorCode.NetworkModuleTokenVersionError,
          ZIMErrorCode.NetworkModuleUserIsNotLogged,
          ZIMErrorCode.NetworkModuleUserOffline,
        ].includes(error?.code)
      ) {
        try {
          const res = await generateToken({
            merchantCode,
            userId: this.userInfo?.userId,
          })
          if (res?.resultCode === 1) {
            this.zegoToken = res.data
            this.zegoLogin()
          } else {
            res?.resultMsg && showToast(res.resultMsg)
          }
        } catch (error) {
          console.log('reLogin--error',error);
        }
      }
    },
    async generateToken() {
      console.log('generate')
      try {
        const res = await generateToken({
          merchantCode,
          userId: this.userInfo?.userId,
        })
        if (res?.resultCode === 1) {
          this.zegoToken = res.data
          this.zegoLogin()
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      } catch (error) {
        console.log('generateToken--error',error);
        this.reLogin()
        // showToast(error)
      }
    },
    async zegoLogin() {
      const zimStore = useZimStore()
      zimStore.updateUnReadMsgNum(0)
      return zimStore.login(
        {
          userID: this.userInfo?.userId,
          userName: this.userInfo.nickname,
        },
        this.zegoToken,
      )
    },
    /** 用户设置模块 - 本地存储
     * 用户 id 对应 用户配置
     * */
    getUserConfig(): UserConfig {
      let userConfig: UserConfig = storage.get(
        `${USER_CONFIG}_${this.userInfo?.userId}`,
      )

      if (!userConfig) {
        this.setUserConfig(userDefaultConfig)
        userConfig = userDefaultConfig
      }

      return userConfig
    },
    setUserConfig(config: UserConfig) {
      storage.set(`${USER_CONFIG}_${this.userInfo?.userId}`, config)
    },
  },
})
