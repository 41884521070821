<template>
	<van-popup :show="show" position="bottom">
		<div class="w-full h-[100vh] pt-[7px] pb-[7px]pl-[15px]">
			<love-search-bar
				placeholder="搜索"
				class="m-10 flex-shrink-0"
				:immediately="true"
				:showSuffix="false"
				@onSearch="doSearch"
				@onClean="data.keyword = null"
			>
				<template #suffix>
					<div class="text-[15px] px-[10px] text-black" @click="onBack">取消</div>
				</template>
			</love-search-bar>
		</div>
		<love-list-view
			:refreshAble="true"
			:refreshing="data.refreshing"
			:loading="data.loading"
			:disabled="data.currentListLength < data.pageSize"
			:noMore="data.currentListLength < data.pageSize"
			@refresh="onRefresh"
			@loadMore="onLoadMore"
		>
			<div
				class="w-full h-[61px] flex items-center px-[15px]"
				v-for="(item, index) in list"
				:key="index"
			>
				<UserDynamicaState
					:avatarSize="51"
					:isShowSelect="true"
					:isSelect="isSelect(item)"
					:item="item"
					@on-select="onSelect(item)"
				/>
			</div>
		</love-list-view>
	</van-popup>
</template>

<script lang="ts" setup>
	import { getRemoreURL } from "@/utils/index";
	import useLocale from "@/hook/useLocale";
	import { useUserStore } from "@/stores/index";
	import { useGlobalProperties } from "@/hook/useGlobalProperties";
	import { useShareStore } from "@/stores/share";

	const shareStore = useShareStore();

	const userStore = useUserStore();
	const globalProperties = useGlobalProperties();

	const {
		i18n: { t },
	} = useLocale();
	const router = useRouter();
	const route = useRoute();

	const props = defineProps({
		show: {
			type: Boolean,
			default: false,
		},
	});
	const getSelectIndex = (row) => {
		return data.selectList.findIndex((val) => val.userId == row.userId);
	};
	const isSelect = (row) => {
		return getSelectIndex(row) > -1;
	};
	const onSelect = (row) => {
		const index = getSelectIndex(row);
		if (index > -1) {
			data.selectList.splice(index, 1);
		} else {
			data.selectList.push(row);
		}
	};
	const emit = defineEmits(["on-close"]);
	const onBack = () => {
		// router.back();
		close();
	};
	const close = () => {
		emit("on-close");
	};
	const data = reactive({
		keyword: "",
		page: 1,
		pageSize: 20,
		currentListLength: 0, // 每次分页列表数据长度
		refreshing: false,
		loading: false,
		hasFirstLoaded: false, // 是否首次加载
		likesDynamicList: [],
		scrollTop: 0,
		selectList: shareStore.selectList || [],
	});
	// 下拉刷新
	const onRefresh = () => {
		data.page = 1;
		data.refreshing = true;
		userStore.isLogin && getList();
	};
	// 加载更多
	const onLoadMore = () => {
		data.page++;
		data.loading = true;
		getList();
	};
	const getList = () => {
		const params = {
			merchantCode: globalProperties.merchantCode,
			// countryName: '',
			page: data.page,
			pageSize: data.pageSize,
		};
		// getLikesDynamic(params)
		// 	.then((res) => {
		// 		if (res?.resultCode === 1) {
		// 			data.currentListLength = res.data.pageData?.length;
		// 			if (data.page > 1) {
		// 				data.likesDynamicList = data.likesDynamicList.concat(
		// 					(res.data && res.data.pageData) || []
		// 				);
		// 			} else {
		// 				data.likesDynamicList = (res.data && res.data.pageData) || [];
		// 			}
		// 		} else {
		// 			res?.resultMsg && showToast(res.resultMsg);
		// 		}
		// 	})
		// 	.finally(() => {
		// 		data.loading = false;
		// 		data.refreshing = false;
		// 	});
	};
	watch(
		() => props.show,
		(val) => {
			if (!val) {
				close();
			}
		}
	);
	const list = [];

	const doSearch = () => {};
</script>
