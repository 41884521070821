import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { register } from 'swiper/element/bundle'
import {
  // Toast, Uploader, Popup, DatePicker, Switch,
  Toast,
  showToast,
  showLoadingToast,
} from 'vant'
// import 'lib-flexible'
import 'virtual:svg-icons-register'
import 'vant/lib/index.css'
import './index.css'
import App from './App.vue'
import router from './router'
import i18n from './locals'
// import SvgIcon from './components/SvgIcon/SvgIcon.vue'
import AutoRegister from './components/auto/index'
import { merchantCode } from './config/index'
import * as Sentry from '@sentry/vue'
import { mode } from './config/env'
import { isInWebView } from './utils/browserEnv'
import { installAmap } from './plugins/amap'
import { getDeviceId } from './plugins/deviceID'
import { regiterDirective } from './components/video-slide/utils'

console.log('---isWebView', isInWebView())
// import VueTouch from 'vue-touch'
register()

export const app = createApp(App)
app.config.globalProperties.merchantCode = merchantCode
app.config.globalProperties.$toast = showToast
app.config.globalProperties.$loading = showLoadingToast
const body = document.querySelector('body')
body.setAttribute('arco-theme', 'light')
// app.use(Popup).use(Toast)
//     .use(Uploader)
//     .use(DatePicker)
//     .use(Switch)
app.use(Toast)
app.use(AutoRegister)
installAmap(app)
// app.component('SvgIcon', SvgIcon)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
// 获取设备id
getDeviceId()
Sentry.init({
  app,
  dsn: 'https://4cc26143e4fb9ba970814648aa0bb07b@o4506992819568640.ingest.us.sentry.io/4507581156818944',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: mode,
  release: '1.0.0.0',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
app.use(router)
regiterDirective(app)
app.use(i18n)
app.mount('#app')
