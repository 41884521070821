import { createApp, h } from 'vue'
import i18n from '@/locals'
let $t = i18n.global.t
/**
 * 确认对话框
 */
const ConfirmDialog = {
  props: {
    title: {
      type: String,
      default: $t('toast.tip'),
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: null,
    },
    cancelText: {
      type: String,
      default: $t('toast.cancel'),
    },
    confirmText: {
      type: String,
      default: $t('toast.confirm'),
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    // 是否允许点击蒙层关闭
    cancelOnClickOutside: {
      type: Boolean,
      default: false,
    },
  },
  render(ctx) {
    const { $props, $emit, $attrs } = ctx
    // console.log(ctx)
    return h(
      'div',
      {
        className:
          'z-[2013] fixed top-0 w-screen h-screen flex justify-center items-center',
      },
      [
        h('div', {
          className: 'absolute inset-0 bg-primary bg-opacity-40',
          onClick(e) {
            e.stopPropagation()
            if ($props.cancelOnClickOutside) {
              $attrs.onClickOutside(e)
            }
          },
        }),
        h(
          'div',
          {
            className:
              'z-[2013] absolute w-[80%] bg-white rounded-15 pt-40 pb-24 px-[27px]',
            onClick(e) {
              e.stopPropagation()
            },
          },
          [
            $props.hideTitle
              ? null
              : h(
                  'div',
                  {
                    className:
                      'flex justify-center text-primary text-lg font-w6',
                  },
                  $props.title
                ),
            h(
              'div',
              {
                className:
                  'flex justify-center text-primary text-base mt-20 mb-40 break-words text-center',
              },
              $props.content
            ),
            h(
              'div',
              {
                className: 'flex justify-center',
              },
              $props.showCancelButton
                ? [
                    h(
                      'button',
                      {
                        className:
                          'flex-1 border rounded-[13.25px] border-regular h-30 text-primary',
                        onClick(e) {
                          e.stopPropagation()
                          $attrs.onCancel(e)
                        },
                      },
                      $props.cancelText
                    ),
                    h('div', {
                      className: 'w-10',
                    }),
                    h(
                      'button',
                      {
                        className:
                          'flex-1 rounded-[13.25px] bg-91blue h-30 text-white',
                        onClick(e) {
                          e.stopPropagation()
                          $attrs.onConfirm(e)
                        },
                      },
                      $props.confirmText
                    ),
                  ]
                : [
                    h(
                      'button',
                      {
                        className:
                          'flex-1 rounded-[13.25px] bg-91blue h-30 text-white',
                        onClick(e) {
                          e.stopPropagation()
                          $attrs.onConfirm(e)
                        },
                      },
                      $props.confirmText
                    ),
                  ]
            ),
          ]
        ),
      ]
    )
  },
}

/**
 * 显示确认对话框
 * @param {object} options
 * @returns
 */
export function showConfirmDialog(options) {
  return new Promise((resolve, reject) => {
    const div = document.createElement('div')
    document.body.appendChild(div)
    const app = createApp(ConfirmDialog, {
      ...options,
      onClickOutside(e) {
        hide()
      },
      onCancel(e) {
        hide()
        reject()
      },
      onConfirm(e) {
        hide()
        resolve()
      },
    })
    function hide() {
      app.unmount()
      div.remove()
    }
    app.mount(div)
  })
}
