export function isInWebView(): boolean {
  try {
    const userAgent = navigator.userAgent.toLowerCase();

    // alert(userAgent)
    console.log('----userAgent', userAgent)
    // // 检测 iOS Chrome 浏览器
    if (userAgent.includes('crios')) {
      return false;
    }
  
    if (userAgent.includes('mqqbrowser') || userAgent.includes('quark') || userAgent.includes('edg') || userAgent.includes('fx') || userAgent.includes('chrome') && userAgent.includes('android')) {
      return false;
    }
  
    // 检测 WebView 环境
    return Object.prototype.hasOwnProperty.call(window, 'Android') || Object.prototype.hasOwnProperty.call(window, 'webkit') || userAgent.includes('wv');
  } catch (error) {
    return true
  }
 
}