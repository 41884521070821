import { trendsPaid } from './trends'
import i18n from '@/locals'

export async function deleteTrendsVerifyWrapper(
  { deleteFn, trendsId },
  ...args
): Promise<any> {
  console.log('verify paid', trendsId)
  const { t } = i18n.global

  const paidResult = await trendsPaid(trendsId)
  if (paidResult?.resultCode == 1 && paidResult?.data) {
    showToast(t('contact.cantDelet'))
    return { resultCode: -1 }
  }

  console.log('args', args)
  return deleteFn(...args)
}
