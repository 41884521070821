import { get, post } from '@/utils/httpRequest.ts'

/**
 * 登入人取消/移除屏蔽用户
 * @param data
 * @returns
 */
export function cancelShieldTrends(data) {
  return post('/trends/cancelShieldTrends', data)
}

/**
 * 动态添加评论
 * @param data
 * @returns
 */
export function addTrendsComment(data) {
  return post('/trends/comment/add', data)
}

/**
 * 视频动态是否有过付费？
 */
export function trendsPaid(trendsId: string): Promise<any> {
  return get(`/video/pay/judge?trendsId=${trendsId}`)
}

/**
 * 删除动态信息
 * @param data
 * @returns
 */
export async function deleteTrends(merchantCode: string, trendsId: string) {
  return get(
    `/trends/deleteTrends?merchantCode=${merchantCode}&trendsId=${trendsId}`,
  )
}

/**
 * 获取动态详情
 * @param params
 *  params.merchantCode 商户编码
 *  params.trendsId 动态 id
 * @returns
 */
export function getTrendsDetails(params) {
  return get('/trends/getTrendsDetails', params)
}

/**
 * 获取动态点赞用户
 * @param data
 * @returns
 */
export function getTrendsPraiseUser(data) {
  return post('/trends/getTrendsPraiseUser', data)
}

/**
 * 获取分享用户
 * @param data
 * @returns
 */
export function getUserRelations(data) {
  return post('/trends/getUserRelations', data)
}
/**
 * 获取可标记用户
 * @param data
 * @returns
 */
export function queryMarkUser(data) {
  return post('/trends/queryMarkUser', data)
}

/**
 * 发布动态
 * @param data
 * @returns
 */
export function releaseTrends(data) {
  return post('/trends/releaseTrends', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
/**
 * 发布动态 new
 * @param data
 * @returns
 */
export function newReleaseTrends(data) {
  return post('trends/newReleaseTrends', data)
}

/**
 * 登入人屏蔽动态
 * @param data
 * @returns
 */
export function shieldTrends(data) {
  return post('/trends/shieldTrends', data)
}

/**
 * 收藏动态
 * @param data
 * @returns
 */
export function trendsCollect(data) {
  return post('/trends/trendsCollect', data)
}

/**
 * 转发动态（快转）
 * @param data
 * @returns
 */
export function trendsFastForward(data) {
  return post('/trends/trendsFastForward', data)
}

/**
 * 动态点赞
 * @param data
 * @returns
 */
export function trendsPraise(data) {
  return post('/trends/trendsPraise', data)
}

/**
 * 动态举报
 * @param data
 * @returns
 */
export function trendsReport(data) {
  return post('/trends/trendsReport', data)
}
/**
 * 音频举报
 * @param data
 * @returns
 */
export function audioFrequencyReport(data) {
  return post('/audioFrequency/audioFrequencyReport', data)
}

/**
 * 分享动态
 * @param data
 * @returns
 */
export function trendsShare(data) {
  return post('/trends/trendsShare', data)
}

/**
 * 话题详情搜索
 * @param data
 * @returns
 */
export function trendsSubjectDetails(data) {
  return post('/trends/trendsSubjectDetails', data)
}

/**
 * 获取动态信息 - 首页动态回复 /trends/updateVisible
 * @param data
 * @returns
 */
export function getTrendsInfo(data) {
  return get('/trends/getTrendsInfo', data)
}

/**
 * 修改动态可见范围
 * @param data
 * @returns
 */
export function updateVisible(data) {
  return post('/trends/updateVisible', data)
}
/**
 * 获取动态可见范围
 * @param data
 * @returns
 */
export function getVisible(data) {
  return post('/trends/trendsVisibleDto', data)
}
/**
 * 删除动态评论
 * @param data
 * @returns
 */
export function deleteComment(data) {
  return post('/trends/deleteComment', data)
}
/**
 * 获取视频
 * @param data
 * @returns
 */
export function getVideoPage(data) {
  return post('/trends/getVideoPage', data)
}
/**
 * 根据 ID 获取音频详情
 * @param data
 * @returns
 */
export function getAudioFrequencyDetails(params) {
  return get('/audioFrequency/getAudioFrequencyDetails', params)
}
/**
 * 获取使用改音频的列表
 * @param data
 * @returns
 */
export function audioTrendsPage(data) {
  return post('/audioFrequency/audioTrendsPage', data)
}
/**
 * 用户收藏，取消收藏 音频
 * @param data
 * @returns
 */
export function userCollectAudio(data) {
  return post('/audioFrequency/userCollectAudio', data)
}
/**
 * 音频查询
 * @param data
 * @returns
 */
export function audioFrequencyPage(data) {
  return post('/audioFrequency/audioFrequencyPage', data)
}
/**
 * 地点详情
 * @param data
 * @returns
 */
export function locationDetails(data) {
  return get('/location/locationDetails', data)
}
/**
 * 根据 ID 获取动态详情
 * @param data
 * @returns
 */
export function getTrendsVoInfo(data) {
  return get('/home/getTrendsVoInfo', data)
}
/**
 * 根据 ID 获取动态详情
 * @param data
 * @returns
 */
export function getTrendsPlayCount(data) {
  return get('/trends/getTrendsPlayCount', data)
}
/**
 * 获取使用该地点的动态
 * @param data
 * @returns
 */
export function locationTrendsPage(data) {
  return post('/location/locationTrendsPage', data)
}
/**
 * 用户收藏地点
 * @param data
 * @returns
 */
export function userCollectLocation(data) {
  return post('/location/userCollectLocation', data)
}
/**
 * 获取地点收藏列表
 * @param data
 * @returns
 */
export function getUserCollectLocationPage(data) {
  return post('/location/getUserCollectLocationPage', data)
}
/**
 * 发布动态获取地点列表
 * @param data
 * @returns
 */
export function queryLocation(data) {
  return post('/location/queryLocation', data)
}
/**
 * 批量取消点赞
 * @param data
 * @returns
 */
export function trendsBatchCancelPraise(data) {
  return post('/trends/trendsBatchCancelPraise', data)
}

export function trendsCount(data: { trendsId: string; flag: number }) {
  return post('/trends/trendsCount', data)
}
