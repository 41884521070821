export const awsS3Config = {
  bucket: import.meta.env.VITE_AWS_S3_BUCKET,
  region: import.meta.env.VITE_AWS_S3_REGION,
  accessKeyId: import.meta.env.VITE_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: import.meta.env.VITE_AWS_S3_SECRET_ACCESS_KEY,
}

// console.log('--import.meta.env. awsS3Config', awsS3Config)
export const mode = import.meta.env.MODE
export const envIsProd = import.meta.env.MODE === 'production'
