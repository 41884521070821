<template>
  <div class="nav-bar" :style="style">
    <div class="absolute left-0 h-full">
      <slot name="left">
        <div class="nav-bar__left">
          <div v-if="showBack" @click="gotoBack">
            <img v-if="leftIcon === 'login/icon-close.png'" :src="`${oss_image_domain_address}login/icon-close.png`"
              class="left-close" name="arrow-left" />
            <van-icon v-else-if="leftIcon" class="left-icon" name="arrow-left" />
            <svg-icon v-else name="back-arrow" size="24px"></svg-icon>
            <!-- <span v-if="leftText" class="ml-16 text-lg font-w6">{{ leftText }}</span> -->
            <span v-if="leftText" class="ml-16 font-semibold text-[24px]">{{ leftText }}</span>
          </div>
        </div>
      </slot>
    </div>
    <div class="mx-auto">
      <slot>
        <span class="font-semibold nav-bar__title">{{ title }}</span>
      </slot>
    </div>
    <div class="absolute right-0 h-full">
      <slot name="right">
        <div class="ml-16 nav-bar__right" @click="onClickRightText" :style="{ 'color': rightTextColor }">{{ rightText }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { oss_image_domain_address } from "@/constants/oss";

const router = useRouter()
const props = defineProps({
  title: String,
  showBack: {
    type: Boolean,
    default: true
  },
  onClickLeft: { type: Function, default: null },
  leftIcon: {
    type: String,
    default: 'common/icon-back.png'
  },
  leftText: String,
  rightText: String,
  rightTextColor: {
    type: String,
    default: '#000'
  },
  showBorder: {
    type: Boolean,
    default: false
  },
  borderColor: {
    type: String,
    default: '#E8E8E8'
  }
})

const emit = defineEmits(['onClickRightText'])

const style = computed(() => {
  return {
    borderBottom: props.showBorder ? `1px solid ${props.borderColor}` : 'none'
  }
})

function gotoBack() {
  if (props.onClickLeft instanceof Function) {
    props.onClickLeft()
  } else {
    router.back()
  }
}

function onClickRightText() {
  emit('onClickRightText')
}
</script>

<style scoped>
.nav-bar {
  position: relative;
  display: flex;
  align-items: center;
  height: 44px;
  line-height: 44px;
}

.nav-bar__left {
  align-self: flex-start;
  display: flex;
  align-items: center;
  height: 100%;
}

.left-icon {
  @apply text-xl;
}

.left-close {
  @apply ml-10 w-10;
}

.nav-bar__left>div {
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.nav-bar__title {
  font-size: 16px;
  color: var(--text_color_01);
}

.nav-bar__right {
  margin-right: 10px;
  align-self: flex-end;
  text-align: end;
  font-size: 16px;
  color: var(--text_color_01);
}
</style>
