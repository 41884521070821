import { defineStore } from 'pinia'
import * as zegoUtil from '@/utils/zg_utils'
import {ZIMErrorCode, ZIMGroupMemberInfo, ZIMMessageReceiptInfo} from '@/types/zim'
import {
  GroupRoleType,
  ZIMConversationType,
  ZIMMessageType,
  CustomMessageType,
  CustomSubType,
} from '@/types/enums'
import { merchantCode } from '@/config/index'
import { storage } from '@/utils/storageUtil'
import { KEY_Authorization } from '@/utils/constant'
import {
  createRoom,
  // getRoom,
  getUserRoomInfo,
  getFriendInfo,
  getRoomUser,
  actionRoomMember,
  updateRoomName,
  updateRoomAvatar,
  viewRoomNotice,
  releaseRoomNotice,
  userSettingRoom,
  userClearRoomChatRecords,
  roomDissolve,
  exitRoom,
  getRoomManagementInfo,
  settingRoomProhibition,
  prohibitionUser,
  setRoomAdmin,
  transferRoom,
} from '@/api/chat'
import { getProhibitionType } from '@/api/index'
import { useUserStore } from '@/stores'
import { showConfirmDialog } from '@/utils/dialog'
import i18n from '@/locals'
import { envIsProd } from '@/config/env'

const { t } = i18n.global

// 互动消息用户 ID
export const InteractionUserID = envIsProd
  ? '66b5d245b38be50001c9e55c'
  : '66aa16beb814d20001f6f831'

export const conversationBus = useEventBus('conversation')
conversationBus.reset()

// 互动消息事件管理
export const interactionMsgBus = useEventBus(InteractionUserID)
interactionMsgBus.reset()

const store = defineStore('zimStore', {
  persist: true,
  state: () => ({
    isLogged: false,
    token: '',
    user: {
      userID: '',
      userName: '',
      memberAvatarUrl: '',
    },
    userMap: {} as { [index: string]: ZIMGroupMemberInfo },
    msgReceiptMap: {} as { [index: string]: ZIMMessageReceiptInfo },
    convs: [], // 会话记录
    groupList: [],
    allMemberList: [], // 所有的群成员
    managersList: [], // 群主和管理员
    normalMemberList: [], // 普通群成员
    muteMemberList: [], // 禁言群成员
    totalMemberCount: 0,
    totalUnreadMessageCount: 0,
    convInfo: {
      conversationID: '',
      type: 0,
      unreadMessageCount: 0,
      orderKey: 0,
      notificationStatus: 0,
      lastMessage: null,
      conversationName: '',
      notice: '',
    },
    msgList: [],
    currentRoomId: null,
    // 当前群组基本信息
    currentGroupInfo: {
      imRoomId: null,
      roomName: null,
      roomId: null,
      headSculpture: null,
      roomNum: 0,
      roomStatus: 0,
      roomNotice: false,
      offlineNoPushMsg: 0,
      isOpenTopChat: 0,
      roomRole: 0,
      status: 0,
      prohibitionDuration: null,
      roomNoticeId: null,
      roomNoticeText: null,
    },
    // 当前群管理信息
    currentGroupManagementInfo: {
      headSculpture: null,
      nickname: null,
      imRoomId: null,
      roomId: null,
      roomAdminMaxNum: 0,
      roomAdminNum: 0,
      roomMaxNum: 0,
      roomNum: 0,
      roomStatus: 0,
    },
    notice: {}, // 群公告
    unReadMsgNum: 0, // 未读消息数
    noticeNum: 0, // 未读动态相关通知数
    showNotify: {
      // 显示通知
      show: false,
      message: null,
      isInteract: false, // 是否互动消息
    },
    notDisturbList: [],
  }),

  getters: {
    // 会话列表
    conversationList(state) {
      return state.convs || []
    },
    // 未读消息数
    unreadMessageCount(state) {
      return state.convs.reduce((prev: number, curr: any) => {
        const count =
          curr.unreadMessageCount <= 0 || curr.notificationStatus == 2
            ? 0
            : curr.unreadMessageCount
        return prev + count
      }, 0)
    },
    // 群组基本信息
    groupInfo: (state) => state.currentGroupInfo,
    // 群组管理信息
    groupManagementInfo: (state) => state.currentGroupManagementInfo,
    // 所有群成员，包含群主和管理员
    allGroupMembers: (state) => state.allMemberList,
    // 群主和管理员
    groupManagers: (state) => state.managersList,
    // 普通群成员
    normalMembers: (state) => state.normalMemberList,
    // 禁言群成员
    muteMembers: (state) => state.muteMemberList,
    noticeInfo: (state) => state.notice,
    // 是否群主
    isCreator: (state) =>
      state.currentGroupInfo &&
      state.currentGroupInfo.roomRole === GroupRoleType.creator,
    // 是否群管理员
    isManager: (state) =>
      state.currentGroupInfo &&
      state.currentGroupInfo.roomRole === GroupRoleType.manager,
  },

  actions: {
    // 更新消息未读数
    updateUnReadMsgNum(value) {
      this.unReadMsgNum = value
    },
    // 更新动态通知未读数
    updateUnReadNotice(v) {
      this.noticeNum = v
    },
    addListeners() {
      // 注册监听“令牌即将过期”的回调
      zegoUtil.zim.on('tokenWilExpire', (zim, { second }) => {
        console.log('tokenWillExpire', second)
        // 可以在这里调用 renewToken 接口来更新 token
        // 新 token 生成可以参考上文
        const userStore = useUserStore()
        zim
          .renewToken(userStore.zegoToken)
          .then(function ({ token }) {
            // 更新成功
            console.log('刷新', token)
          })
          .catch(function (err) {
            // 更新失败
            console.log('刷新失败', err)
          })
      })

      // 注册监听“网络连接状态变更”的回调
      zegoUtil.zim.on(
        'connectionStateChanged',
        (_zim, { state, event, extendedData }) => {
          console.log(
            '状态变化 connectionStateChanged',
            state,
            event,
            extendedData,
          )
          // 当长时间网络连接断开导致 SDK 内部登出时，需要重新登录
          if (state == 0 && event != 0 && event != 4) {
            this.login(this.user, this.token)
          }
          if (event === 4) {
            // 被踢下线
            console.log('被踢下线')
            const userStore = useUserStore()
            showConfirmDialog({
              title: t('zim.offlineTitle'),
              content: t('zim.offlineContent1'),
              confirmText: t('zim.reloginText'),
              showCancelButton: false,
            }).then(() => {
              this.logout(false)
              storage.set(KEY_Authorization, null)
              userStore.loginOut()
              location.replace('/login')
            })
          }
        },
      )

      // 注册监听收到单聊通信的消息回调
      zegoUtil.onReceivePeerMessage(({ data }, error) => {
        const userStore = useUserStore()
        console.log(
          '全局监听 单聊 received',
          data,
          error,
          userStore.userInfo,
          userStore.userInfo?.status === 1,
        )
        if (data && data.messageList) {
          data.messageList.forEach((message) => {
            if (message.type === ZIMMessageType.Custom) {
              if (message.subType === CustomSubType.SystemNotice) {
                // 登录时间大于消息发送时间，则表示已解禁 不然每次登录会重新加载远程消息，再次弹出
                if (userStore.userInfo?.loginTime > message.timestamp) {
                  return
                }
                try {
                  const msg = JSON.parse(message.message)
                  if (msg.subType === 1) {
                    showConfirmDialog({
                      title: msg.title || t('zim.offlineTitle'),
                      content: msg.tipComment || msg.noticeComment,
                      showCancelButton: false,
                    }).then(() => {
                      zegoUtil.deleteMessages(
                        [message],
                        message.conversationID,
                        message.conversationType,
                        null,
                        null,
                      )
                      this.logout(false)
                      storage.set(KEY_Authorization, null)
                      userStore.loginOut()
                      location.replace('/')
                    })
                  }
                } catch (error) {
                  console.error(error)
                }
              } else if (message.subType === CustomSubType.NotFollowTip) {
                // 未互关，消息条数限制提示
                try {
                  const msg = JSON.parse(message.message)
                  // 对方的陌生人提示语，我方不能提示
                  if (msg.userId !== userStore.userInfo?.userId) {
                    this.deleteMessage(message) // 删除消息
                  }
                } catch (error) {
                  console.log(error)
                }
              }
            }
          })
        }
      })
      // 注册监听收到会话更新的通知回调
      zegoUtil.zim.on('conversationChanged', (zim, { infoList }) => {
        console.log('全局监听 会话更新', infoList)
        console.log('InteractionUserID', InteractionUserID)
        conversationBus.emit(infoList)
        infoList.forEach(({ conversation }, i) => {
          if (conversation.conversationID === InteractionUserID) {
            this.noticeNum += conversation.unreadMessageCount
          } else {
            this.unReadMsgNum++
          }
          console.log(i, '--->', conversation)
          if (conversation?.type === ZIMConversationType.Peer) {
            // 单聊自定义消息
            if (
              conversation.lastMessage &&
              conversation.lastMessage.type === ZIMMessageType.Custom
            ) {
              if (conversation.lastMessage.subType == 0) {
                zim.clearConversationUnreadMessageCount(
                  conversation.conversationID,
                  ZIMConversationType.Peer,
                ) // 清除会话消息未读数
                zim.deleteConversation(
                  conversation.conversationID,
                  ZIMConversationType.Peer,
                ) // 删除会话
              }
              // 拉黑提示
              if (conversation.lastMessage.subType === CustomSubType.BlackTip) {
                console.log('被拉黑')
                zim.clearConversationUnreadMessageCount(
                  conversation.conversationID,
                  ZIMConversationType.Peer,
                ) // 清除会话消息未读数
                zim.deleteConversation(
                  conversation.conversationID,
                  ZIMConversationType.Peer,
                ) // 删除会话
              } else if (conversation.lastMessage.subType == 0) {
                zim.clearConversationUnreadMessageCount(
                  conversation.conversationID,
                  ZIMConversationType.Peer,
                ) // 清除会话消息未读数
                zim.deleteConversation(
                  conversation.conversationID,
                  ZIMConversationType.Peer,
                ) // 删除会话
              } else if (conversation.conversationID === InteractionUserID) {
                interactionMsgBus.emit(conversation.lastMessage)
                // 互动消息
                // type 类型 1:新粉丝:2:评论:3@我的 4:点赞
                this.showNotifyMessage(conversation.lastMessage, true)
                zim.clearConversationUnreadMessageCount(
                  conversation.conversationID,
                  ZIMConversationType.Peer,
                ) // 清除会话消息未读数
                zim.deleteConversation(
                  conversation.conversationID,
                  ZIMConversationType.Peer,
                ) // 删除会话
              } else if (
                [CustomSubType.ShareLink, CustomSubType.Reply].includes(
                  conversation.lastMessage.subType,
                )
              ) {
                if (conversation.lastMessage.direction === 1) {
                  if (conversation.notificationStatus == 1) {
                    this.showNotifyMessage(conversation.lastMessage, false)
                  }
                }
              }
            } else {
              if (
                conversation.lastMessage &&
                conversation.lastMessage.direction === 1
              ) {
                if (conversation.notificationStatus == 1) {
                  this.showNotifyMessage(conversation.lastMessage, false)
                }
              }
            }
          } else if (conversation?.type === ZIMConversationType.Group) {
            if (
              conversation.lastMessage &&
              conversation.lastMessage.direction === 1
            ) {
              // if (conversation.lastMessage.subType == 0) {
              //   zim.clearConversationUnreadMessageCount(conversation.conversationID, ZIMConversationType.Peer) // 清除会话消息未读数
              //   zim.deleteConversation(conversation.conversationID, ZIMConversationType.Peer) // 删除会话
              // }
              if (conversation.lastMessage.type === ZIMMessageType.Custom) {
                if (
                  [CustomSubType.ShareLink, CustomSubType.Reply].includes(
                    conversation.lastMessage.subType,
                  )
                ) {
                  if (conversation.notificationStatus == 1) {
                    this.showNotifyMessage(conversation.lastMessage, false)
                  }
                }
              } else {
                if (conversation.lastMessage.type >= 0) {
                  if (conversation.notificationStatus == 1) {
                    this.showNotifyMessage(conversation.lastMessage, false)
                  }
                }
              }
            }
          }
        })
      })
    },
    // 显示推送消息
    showNotifyMessage(message, isInteract) {
      console.log(message, 'messagemessagemessage')
      if (message.conversationType == ZIMConversationType.Peer) {
        // 单聊
        getFriendInfo({ merchantCode, toUserId: message.conversationID }).then(
          (res) => {
            console.log(res.data, '单聊单聊')
            if (res.data.offlineNoPushMsg == 0) {
              this.openMessage(message, isInteract)
            }
          },
        )
      } else if (message.conversationType == ZIMConversationType.Group) {
        // 群聊
        getUserRoomInfo({ merchantCode, roomId: message.conversationID }).then(
          (res) => {
            console.log(res.data, '群聊群聊')
            if (res.data.offlineNoPushMsg == 0) {
              this.openMessage(message, isInteract)
            }
          },
        )
      }
    },
    // 打开推送弹窗
    openMessage(message, isInteract) {
      this.showNotify.show = true
      this.showNotify.message = message
      this.showNotify.isInteract = isInteract
    },
    // 删除消息
    deleteMessage(message) {
      zegoUtil.deleteMessages(
        [message],
        message.conversationID,
        message.conversationType,
        null,
        null,
      )
    },
    login(userInfo: any, token: string) {
      this.isLogged = false
      // TODO 判断如果已经登录 zego 了需要退出重新登录
      /**
       * [API.login]:{error:{code:6000111,message:"User has already logged in."},userID:"64ed512eade8751b9cf45048",oldUserID:"6530d2c31f625d36d256a296"}
       */
      return zegoUtil.zim
        .login(userInfo, token)
        .then(() => {
          console.log('zim 登录成功')
          this.isLogged = true
          this.token = token
          this.user = userInfo
          this.getUserInfo()
          //注册 zego 事件监听
          this.addListeners()
          return true
        })
        .catch(on_error)
    },

    logout(isSend = true) {
      this.isLogged = false
      return isSend && zegoUtil.zim.logout()
    },

    getUserInfo() {
      return zegoUtil.zim
        .queryUsersInfo([this.user.userID], { isQueryFromServer: true })
        .then(({ userList }) => {
          // console.log('当前用户信息 ', userList)
          this.user = {
            ...userList[0].baseInfo,
            memberAvatarUrl: userList[0].userAvatarUrl,
          }
          this.userMap[this.user.userID] = { ...this.user }
        })
    },

    // 是否互动消息账号
    isInteractionAccount(userID: string) {
      return InteractionUserID === userID
    },

    setUserMap(ids: string[]) {
      if (!ids || !ids.length) return
      zegoUtil.zim
        .queryUsersInfo(ids, { isQueryFromServer: true })
        .then(({ userList }) => {
          // console.log('同步用户 ', userList)
          userList.forEach((item: any) => {
            this.userMap[item.baseInfo.userID] = {
              ...item.baseInfo,
              memberAvatarUrl: item.userAvatarUrl,
            }
          })
        })
      // console.log('setUserMap', ids, JSON.stringify(this.userMap))
    },
    // 查询会话列表
    queryConversationList() {
      return new Promise((resolve, reject) => {
        zegoUtil.queryConversationList(
          {
            count: 1000,
          },
          (data, error) => {
            if (error) {
              on_error(error)
              reject(error)
            } else {
              console.log('queryConversationList', data)
              this.convs = data.conversationList
              this.convs.sort((a: any, b: any) => b.orderKey - a.orderKey)
              const ids: string[] = []
              data.conversationList.forEach((item: any) => {
                if (item.type === 0) {
                  if (!item.conversationName || !item.conversationAvatarUrl) {
                    ids.push(item.conversationID)
                  } else {
                    this.userMap[item.conversationID] = {
                      ...this.userMap[item.conversationID],
                      userID: item.conversationID,
                      userName: item.conversationName,
                      memberAvatarUrl: item.conversationAvatarUrl,
                    }
                  }
                }
              })
              this.setUserMap(ids)
              resolve(data)
            }
          },
        )
      }).catch(on_error)
    },
    // 清除会话消息未读数
    clearConversationUnreadMessageCount(
      conversationID: string,
      conversationType: ZIMConversationType,
    ) {
      return zegoUtil.zim.clearConversationUnreadMessageCount(
        conversationID,
        conversationType,
      )
    },
    // 获取消息未读总数
    conversationTotalUnreadMessageCountUpdated() {
      zegoUtil.zim.on(
        'conversationTotalUnreadMessageCountUpdated',
        function (_, { totalUnreadMessageCount }) {
          console.log('未读数变化：', totalUnreadMessageCount)
          this.totalUnreadMessageCount = totalUnreadMessageCount
        },
      )
    },
    // 获取群组信息
    getGroupInfo(roomId: string) {
      this.currentRoomId = roomId
      return getUserRoomInfo({
        merchantCode,
        roomId,
      }).then((res) => {
        if (res?.resultCode === 1) {
          this.currentGroupInfo = res.data
          return res.data
        } else {
          // res?.resultMsg && showToast(res.resultMsg)
        }
      })
    },

    updateGroupInfo(value: object) {
      console.log('更新群组信息', value)
      this.currentGroupInfo = {
        ...this.currentGroupInfo,
        ...value,
      }
    },
    // 获取所有群成员 (包含群主)
    getAllMembers(roomId: string) {
      return getRoomUser({
        merchantCode,
        roomId,
        leader: true, // 是否查询群主信息
      }).then((res) => {
        if (res?.resultCode === 1) {
          this.allMemberList = res.data || []
          return res.data
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      })
    },
    // 获取群主和管理员
    getGroupManagers(roomId: string) {
      return getRoomUser({
        merchantCode,
        roomId,
        leader: true, // 是否查询群主信息
        role: GroupRoleType.manager, // 查询管理员成员时有，为空查询所有
      }).then((res) => {
        if (res?.resultCode === 1) {
          this.managersList = res.data || []
          return res.data
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      })
    },
    // 获取普通群成员
    getNormalMembers(roomId: string) {
      return getRoomUser({
        merchantCode,
        roomId,
      }).then((res) => {
        if (res?.resultCode === 1) {
          this.normalMemberList = res.data || []
          return res.data
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      })
    },
    // 获取禁言群成员
    getMuteMembers(roomId: string) {
      return getRoomUser({
        merchantCode,
        roomId,
        status: 1, // 查询禁言用户时有，为空查询所有
      }).then((res) => {
        if (res?.resultCode === 1) {
          this.muteMemberList = res.data || []
          return res.data
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      })
    },
    // 删除群成员
    deleteMembers(roomId: string, userIds: Array<string>) {
      return actionRoomMember({
        merchantCode,
        roomId,
        type: 1, // 操作类型 0 添加，1 删除
        userIds,
      }).then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          this.getNormalMembers(roomId) // 重新获取群成员
          return true
        }
      })
    },
    // 踢出群组
    kickGroupMembers(userIDs: string[], groupID: string) {
      return new Promise((resolve, reject) => {
        zegoUtil.kickGroupMembers(userIDs, groupID, (data: any, error: any) => {
          if (error) {
            reject(error)
          } else {
            resolve(data)
          }
        })
      })
    },
    // 添加群成员
    addMembers(roomId: string, userIds: Array<string>) {
      return actionRoomMember({
        merchantCode,
        roomId,
        type: 0, // 操作类型 0 添加，1 删除
        userIds,
      }).then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          this.getNormalMembers(roomId) // 重新获取群成员
          return true
        }
      })
    },
    // 邀请群成员
    inviteUsersIntoGroup(userIDs: string[], groupID: string) {
      return new Promise((resolve, reject) => {
        zegoUtil.inviteUsersIntoGroup(
          userIDs,
          groupID,
          (data: any, error: any) => {
            if (error) {
              reject(error)
            } else {
              resolve(data)
            }
          },
        )
      })
    },
    // 设置群成员为管理员 管理员（对应枚举值为 2）
    setGroupMemberRole(role: number, forUserID: string, groupID: string) {
      return new Promise((resolve, reject) => {
        zegoUtil.setGroupMemberRole(
          role,
          forUserID,
          groupID,
          (data: any, error: any) => {
            if (error) {
              reject(error)
            } else {
              resolve(data)
            }
          },
        )
      })
    },
    // 即构设置消息免打扰
    zimSetNotDisturb(
      status: number,
      conversationID: string,
      conversationType: ZIMConversationType,
    ) {
      return new Promise((resolve, reject) => {
        zegoUtil.setNotificationStatus(
          status,
          conversationID,
          conversationType,
          (data: any, error: any) => {
            if (error) {
              reject(error)
            } else {
              resolve(data)
            }
          },
        )
      })
    },
    // 修改群名称
    updateGroupName(roomId: string, name: string) {
      return updateRoomName({
        merchantCode,
        roomId,
        name,
      }).then((res) => {
        if (res?.resultCode === 1) {
          this.updateGroupInfo({
            roomName: name,
          })
          return res.data
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      })
    },
    // 修改群头像
    updateGroupAvatar(roomId: string, file: File) {
      const formData = new FormData()
      formData.append('merchantCode', merchantCode)
      formData.append('roomId', roomId)
      formData.append('file', file)
      return updateRoomAvatar(formData).then((res) => {
        if (res?.resultCode === 1) {
          this.updateGroupInfo(res.data)
          return res.data
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      })
    },
    // 群公告查看
    getGroupNotice(roomId: string) {
      return viewRoomNotice({
        merchantCode,
        roomId,
      }).then((res) => {
        if (res?.resultCode === 1) {
          this.notice = res.data
          return res.data
        } else {
          res?.resultMsg && showToast(res.resultMsg)
        }
      })
    },
    // 发布群公告
    releaseGroupNotice(
      roomId: string,
      text: string,
      files?: File[],
      clearNotice?: boolean,
      reuse?: boolean,
    ) {
      const formData = new FormData()
      formData.append('merchantCode', merchantCode)
      formData.append('roomId', roomId)
      formData.append('text', text)
      files?.forEach((file) => {
        formData.append('files', file)
      })
      if (clearNotice) {
        formData.append('clearNotice', 'true')
      }
      if (reuse) {
        formData.append('reuse', 'true')
      }
      return releaseRoomNotice(formData).then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          return true
        }
      })
    },
    // 设置聊天置顶
    setGroupTop(roomId: string, on: boolean) {
      return userSettingRoom({
        merchantCode,
        roomId,
        type: 0, // 设置类型，0 置顶聊天，1 消息免打扰
        off: on ? 1 : 0, // 开关状态 0 关 1 开 默认关
      }).then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          this.getGroupInfo(roomId)
          return true
        }
      })
    },
    // 设置消息免打扰
    setDoNotDisturb(roomId: string, on: boolean) {
      return userSettingRoom({
        merchantCode,
        roomId,
        type: 1, // 设置类型，0 置顶聊天，1 消息免打扰
        off: on ? 1 : 0, // 开关状态 0 关 1 开 默认关
      }).then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          // this.getGroupInfo(roomId)
          return true
        }
      })
    },

    // 清空聊天记录
    clearGroupChatRecords(roomId: string, hideToast: boolean = false) {
      return userClearRoomChatRecords({
        merchantCode,
        roomId,
      }).then((res) => {
        !hideToast && res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          return true
        }
      })
    },
    // 删除指定会话的全部消息
    deleteAllMessage(conversationID: string, type: ZIMConversationType) {
      const config = { isAlsoDeleteServerMessage: true }
      return new Promise((resolve, reject) => {
        zegoUtil.deleteAllMessage(
          conversationID,
          type,
          config,
          (data, error) => {
            if (error) {
              reject(error)
            } else {
              resolve(data)
            }
          },
        )
      })
    },
    // 主动退出群组
    leaveGroup(groupID: string) {
      return new Promise((resolve, reject) => {
        zegoUtil.leaveGroup(groupID, (data, error) => {
          if (error) {
            reject(error)
          } else {
            resolve(data)
          }
        })
      })
    },
    // 用户退出群聊
    exitGroup(roomId: string) {
      return exitRoom({
        merchantCode,
        roomId,
      }).then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          return true
        }
      })
    },
    // 解散群组
    dissolveGroup(roomId: string) {
      return roomDissolve({
        merchantCode,
        roomId,
      }).then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          return true
        }
      })
    },
    // 获取获取群管理信息
    getGroupManagementInfo(roomId: string) {
      return getRoomManagementInfo({
        merchantCode,
        roomId,
      }).then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          this.currentGroupManagementInfo = res.data
          return res.data
        }
      })
    },
    // 设置群禁言
    setGroupProhibition(roomId: string, on: boolean) {
      return settingRoomProhibition({
        merchantCode,
        roomId,
        status: on ? 0 : 1, // 0 禁言 1 取消禁言
      }).then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          // this.getGroupManagementInfo(roomId)
          this.currentGroupManagementInfo = res.data
          return res.data
        }
      })
    },
    // 禁言用户
    setUserProhibition(
      roomId: string,
      toUserId: string,
      on: boolean,
      time?: number,
    ) {
      return prohibitionUser({
        merchantCode,
        roomId,
        toUserId,
        type: on ? 1 : 0, // 0 解除禁言 1 禁言
        time,
      }).then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          return true
        }
      })
    },
    // 获取禁言类型
    getProhibitionType() {
      return getProhibitionType().then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          return res.data
        }
      })
    },
    // 群主管理员设置、删除管理员
    // 对接下即构
    setGroupAdmin(roomId: string, toUserIds: Array<string>, on: boolean) {
      return setRoomAdmin({
        merchantCode,
        rooId: roomId,
        toUserIds,
        type: on ? 0 : 1, // 操作类型 0 添加管理员，1 取消管理员
      }).then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          return res.data || true
        }
      })
    },
    // 转让群主
    transferGroup(roomId: string, toUserId: string) {
      return transferRoom({
        merchantCode,
        roomId,
        toUserId,
      }).then((res) => {
        res?.resultMsg && showToast(res.resultMsg)
        if (res?.resultCode === 1) {
          return res.data || true
        }
      })
    },

    /**
     * 创建群组
     */
    createGroup(groupName: string, userIDs: string[], file?: File) {
      const groupInfo = {
        groupID: '',
        groupName,
        groupAvatarUrl: '',
      }
      const config = {
        groupNotice: '',
        groupAttributes: {},
      }
      return new Promise((resolve, reject) => {
        zegoUtil.createGroupAdvanced(
          groupInfo,
          userIDs,
          config,
          (data, error) => {
            console.log('创建结果', data, error)
            if (error) {
              on_error(error)
              reject(error)
            } else {
              const formData = new FormData()
              formData.append('merchantCode', merchantCode)
              formData.append('name', groupName)
              userIDs.forEach((id, index) => {
                formData.append(`userIds[${index}]`, id)
              })
              if (file) {
                formData.append('file', file)
              }
              if (
                data.groupInfo &&
                data.groupInfo.baseInfo &&
                data.groupInfo.baseInfo.groupID
              ) {
                formData.append('imRoomId', data.groupInfo.baseInfo.groupID)
              }
              createRoom(formData)
                .then((res) => {
                  res?.resultMsg && showToast(res.resultMsg)
                  if (res?.resultCode === 1) {
                    resolve(res.data)
                  } else {
                    reject(res?.resultMsg)
                  }
                })
                .catch((err) => {
                  reject(err)
                })
            }
          },
        )
      })
    },

    // 发送 tip 自定义消息
    sendTipMessage(
      message: string,
      toConversationID: string,
      conversationType: ZIMConversationType,
    ) {
      const customMessage = {
        message,
        type: ZIMMessageType.Custom,
        // subType: CustomMessageType.Tip,
      }
      const config = {
        priority: 1,
      }
      const notification = {
        onMessageAttached: function (_message) {
          // console.log('通知', message)
        },
      }
      return new Promise((resolve, reject) => {
        zegoUtil.sendMessage(
          customMessage,
          toConversationID,
          conversationType,
          config,
          notification,
          (data, error) => {
            // console.log('发送自定义 Tip 消息结果', data, error)
            if (error) {
              reject(error)
            } else {
              resolve(data)
            }
          },
        )
      })
    },
    // 发送分享消息
    sendShareMessage(
      payload: any,
      toConversationID: string,
      conversationType: ZIMConversationType,
    ) {
      const { message, subType } = payload
      const customMessage = {
        message: JSON.stringify(message),
        subType,
        type: ZIMMessageType.Custom,
      }
      const config = {
        priority: 1,
      }
      const notification = {
        onMessageAttached: function (_message) {
          console.log('通知', _message)
        },
      }
      return new Promise((resolve, reject) => {
        zegoUtil.sendMessage(
          customMessage,
          toConversationID,
          conversationType,
          config,
          notification,
          (data, error) => {
            console.log('发送分享消息结果', data, error)
            if (error) {
              // on_error(error)
              reject(error)
            } else {
              resolve(data)
            }
          },
        )
      })
    },
  },
})

const on_error = (error: any) => {
  console.error('on_error', error)
  const zimStore = store()
  const userStore = useUserStore()
  if (error.code === 6000121) {
    if (userStore.zegoToken) {
      //   userStore.zegoLogin()
      zegoUtil.renewToken(userStore.zegoToken, function (data, error) {
        console.log('刷新 token', data, error)
        if (error) {
          zimStore.logout(false)
          storage.set(KEY_Authorization, null)
          userStore.loginOut()
          location.replace('/')
        } else {
          userStore.zegoToken = data
        }
      })
    } else {
      const router = useRouter()
      router.push({
        name: 'login',
      })
    }
  } else if (error.code === 6000111) {
    zimStore.logout(false)
    storage.set(KEY_Authorization, null)
    location.replace('/')
  } else if (error.code === 6000106 || error.code === 6000105) {
    // Token expired
    zegoUtil.renewToken(userStore.zegoToken, function (data, error) {
      console.log('刷新 token', data, error)
      if (error) {
        zimStore.logout(false)
        storage.set(KEY_Authorization, null)
        userStore.loginOut()
        location.replace('/')
      } else {
        userStore.zegoToken = data
      }
    })
  } else if (error.code === 6000104) {
    showToast(t('zim.err_6000104'))
    return error
  }

  showToast(error.message)
  return error
}

export default store
