/**
 * 登录账号类型
 * 0 手机号，1 邮箱
 */
export const enum AccountType {
  mobile,
  email,
}

/**
 * 发送验证码业务类型：
 * 0 注册，1 忘记密码 2 换绑
 */
export const enum BusinessType {
  register,
  forgot,
  modify,
}

/**
 * 性别
 * 0 男 1 女
 */
export const enum Sex {
  man,
  woman,
}

/**
 * 动态类型，标记（1=图文、2=视频、3=投票）
 */
export const enum TrendsType {
  imageAndText = 1,
  video,
  vote,
  adv,
}

/**
 * 转发动态类型 0 快转 1 转发
 */
export const enum ForwardType {
  quick,
  normal,
}

/**
 * 分享类型，0 分享给好友，1 分享给群，2 分享到第三方
 */
export const enum ShareType {
  friends,
  group,
  platform,
}

/**
 * 修改密码类型 0 手机号验证 1 邮箱验证 2 原密码验证
 */
export const enum updatePwdType {
  phone,
  email,
  password,
}
/** 语言类型 zh:中文，en 英语*/
export const enum languageType {
  Chinese = 'zh',
  English = 'en',
}

/**
 * 群角色 1=群主、2=管理员、3=普通成员
 */
export const enum GroupRoleType {
  creator = 1,
  manager,
  member,
}

export const enum ZIMConversationType {
  Peer = 0,
  Room = 1,
  Group = 2,
}

/**
 * 服务端定义的消息类型
 * 1 正常文本 2.图片消息，3 视频消息 4 语音消息 5 表情消息 6 链接消息（内容为自定义消息内容）7 文件消息 8 分享消息（改为 6）9 系统消息
 */
export const enum MessageType {
  Text = 1,
  Image = 2,
  Video = 3,
  Voice = 4,
  Emoji = 5,
  Link = 6,
  File = 7,
  Share = 8,
  System = 9,
  Custom = 200,
}


/**
 * 服务端定义的消息类型
 */
export const enum QuoteType {
  Text = 1, // 转发动态
  Videos = 3, // 转发动态
  Dynamics = 6, // 转发动态
}

/**
 * ZEGO 消息类型
 *
 */
export const enum ZIMMessageType {
  Unknown = 0, // 未知类型
  Text = 1, // 普通文本消息
  Command = 2, // 自定义二进制消息
  Image = 11, // 图片消息，仅支持 ".jpg", ".jpeg", ".png", ".bmp", ".gif", ".tiff" 图片类型
  File = 12, // 文件消息
  Audio = 13, // 音频消息，仅支持 ".mp3" 音频类型
  Video = 14, // 视频消息，仅支持 ".mp4", ".mov" 视频类型
  Revoke = 31, // 撤回消息
  Custom = 200, // 自定义消息
}

/**
 * 服务端发送的自定义消息类型
 */
export const enum CustomSubType {
  Dynamics = 0, // 分享 动态
  GroupMute = 1, // 全体禁言提示 (开启关闭)
  MemberMute = 2, // 普通成员禁言 (需要前端拼接用户昵称，userId，nickname 为被禁言用户 id)
  AddMember = 3, // 邀请成员入群提示
  ChangeGroupMaster = 4, // 更换群主提示 (前端拼接 nickname，userId 为新群主，)
  DisbandGroup = 5, // 解散群
  FreezeGroup = 6, // 冻结群
  KickOutGroup = 7, // 踢出群 (前端拼接 存在 toUserId)
  QuitGroup = 8, // 主动退出群
  RevocationMsg = 9, // 撤回消息 (需要前端拼接 userId，nickname 为撤回者用用户 id)
  BlackTip = 10, // 拉黑信息，发送提示 (登入人 id 为 userId 显示)
  NotFollowTip = 11, // 双方都没关注时提示
  LimitTip = 12, // 已达消息限制时提示 (登入人 id 为 userId 显示)
  CancelBlackTip = 13, // 取消拉黑消息
  ShareLink = 14, // 分享 (链接) 消息
  SystemNotice = 15, // 系统通知
  GroupAnnouncement = 16, // 群公告通知")
  GroupReply = 100, // 回复消息，格式：{ quoteNickname: '', quoteHeadSculpture: '', quoteMessageType: 1, quoteMessage: '', message: '', }
  Reply = 103, // 回复消息，格式：{ quoteNickname: '', quoteHeadSculpture: '', quoteMessageType: 1, quoteMessage: '', message: '', }
}

/**
 * 分享自定义消息类型
 */
export const enum CustomMessageType {
  ShareUserCard = 101, // 分享用户，格式：{ userId: '1233', nickname: 'OWEN', headSculpture: '', trendsCount: 54, fansCount: 5,	labels: ['美食','摄影师','歌王'] }
  ShareTrends = 102, // 分享动态，格式：{userId: '1233', nickname: 'OWEN', headSculpture: '', body: '', subjectList: [], subjectMap: {}, resources: [],commentCount: 1,	praiseCount: 100}
  // Reply = 103, // 回复消息，格式：{ quoteNickname: '', quoteHeadSculpture: '', quoteMessageType: 1, quoteMessage: '', message: '', }
  // Tip = 104, // 提示消息，格式；{ content: '' }
  ShareAudio = 103, // 分享音频
  ShareLocation = 104, // 分享地点
  ShareMsg = 105, // 分享地点
}

/**
 * 用户分享和推广数据
 */
export const enum InviteType {
  inviteShare = 0, // 0-邀请分享，
  userShare = 1, //1 用户主页分享
}

/**
 * 互动消息 type 类型 1:新粉丝:2:评论:3@我的 4:点赞 14：个人主页
 */
export const enum InviteType {
  newfans = 1,
  comment = 2,
  atme = 3,
  prise = 4,
  personal = 14,
}

/**
 * 服务端定义的积分类型
 * 1 发布图文动态 2.删除图文动态，3 发布视频动态 4 删除视频动态 5 发布投票动态 6 删除投票动态 7 邀请好友 8 购买付费 9 作品收益 10 充值 20 提现 21 提现返还
 */
export const enum goldTradeType {
  postText = 1,
  deleteText = 2,
  postVideo = 3,
  deleteVideo = 4,
  postVote = 5,
  deleteVote = 6,
  inviteFriend = 7,
  purchasePay = 8,
  albumIncome = 9,
  rechargeIndex = 10,
  collectionBuy = 16,
  collectionIncome = 17,
  withdrawIndex = 20,
  withdrawBack = 21,
  addPointManual = 30,
  subPointManual = 31,
}

export enum typeRuleEnum {
  // TODO 这一块类型有点混乱，如果有视频加入需要调整
  text = 1,
  video = 2,
  pay_video = 3,
}

export const enum ZIMErrorCode {
  Success = 0,
  Failed = 1,
  CommonModuleParamInvalid = 6000001,
  CommonModuleNotInit = 6000002,
  CommonModuleInvalidAppID = 6000003,
  CommonModuleTriggerSDKFrequencyLimit = 6000004,
  CommonModuleTriggerServerFrequencyLimit = 6000005,
  CommonModuleSwitchServerError = 6000006,
  CommonModuleIMServerError = 6000007,
  CommonModuleUploadLogError = 6000010,
  CommonModuleUserIsNotExist = 6000011,
  CommonModuleUserInfoQueriedLimit = 6000012,
  CommonModuleUnsupportedRequest = 6000013,
  CommonModuleUnactivatedIMServer = 6000014,
  CommonModuleExceedDAULimit = 6000015,
  CommonModuleExceedMAULimit = 6000016,
  NetworkModuleCommonError = 6000101,
  NetworkModuleServerError = 6000102,
  NetworkModuleTokenInvalid = 6000103,
  NetworkModuleNetworkError = 6000104,
  NetworkModuleNetworkOvertime = 6000105,
  NetworkModuleTokenExpired = 6000106,
  NetworkModuleTokenVersionError = 6000107,
  NetworkModuleTokenTimeIsTooShort = 6000108,
  NetworkModuleTokenTimeIsTooLong = 6000109,
  NetworkModuleUserHasAlreadyLogged = 6000111,
  NetworkModuleUserIsNotLogged = 6000121,
  NetworkModuleUserOffline = 6000123,
  ConversationModuleCommonError = 6000601,
  ConversationModuleServerError = 6000602,
  ConversationModuleConversationDoesNotExist = 6000603,
  ConversationModuleTheNumberOfPinnedConversationsHasReachedLimit = 6000604,
  MessageModuleCommonError = 6000201,
  MessageModuleServerError = 6000202,
  MessageModuleSendMessageFailed = 6000203,
  MessageModuleTargetDoesNotExist = 6000204,
  MessageModuleFileNotExist = 6000211,
  MessageModuleFileServerError = 6000212,
  MessageModuleFileTypeUnsupported = 6000213,
  MessageModuleFileSizeInvalid = 6000214,
  MessageModuleFileDurationInvalid = 6000215,
  MessageModuleAuditRejected = 6000221,
  MessageModuleAuditFailed = 6000222,
  MessageModuleAuditCustomSentRejected = 6000230,
  MessageModuleCallError = 6000270,
  MessageModuleCancelCallError = 6000271,
  MessageModuleCallServerError = 6000272,
  MessageModuleIsNotInvitor = 6000273,
  MessageModuleIsNotInvitee = 6000274,
  MessageModuleCallAlreadyExists = 6000275,
  MessageModuleCallDoesNotExist = 6000276,
  MessageModuleReceiptReadError = 6000277,
  MessageModuleMessageExceedsRevokeTime = 6000278,
  MessageModuleMessageHasBeenRevoked = 6000279,
  RoomModuleCommonError = 6000301,
  RoomModuleServerError = 6000302,
  RoomModuleCreateRoomError = 6000303,
  RoomModuleJoinRoomError = 6000304,
  RoomModuleLeaveRoomError = 6000306,
  RoomModuleTheRoomMemberQueryFailed = 6000310,
  RoomModuleTheRoomMembersQueryFailedCompletely = 6000311,
  RoomModuleUserIsNotInTheRoom = 6000321,
  RoomModuleTheRoomDoesNotExist = 6000322,
  RoomModuleTheRoomAlreadyExists = 6000323,
  RoomModuleTheNumberOfExistingRoomsHasReachedLimit = 6000324,
  RoomModuleTheNumberOfJoinedRoomsHasReachedLimit = 6000325,
  RoomModuleRoomAttributesCommonError = 6000330,
  RoomModuleRoomAttributesOperationFailedCompletely = 6000331,
  RoomModuleRoomAttributesQueryFailed = 6000333,
  RoomModuleTheNumberOfRoomAttributesExceedsLimit = 6000334,
  RoomModuleTheLengthOfRoomAttributeKeyExceedsLimit = 6000335,
  RoomModuleTheLengthOfRoomAttributeValueExceedsLimit = 6000336,
  RoomModuleTheTotalLengthOfRoomAttributesValueExceedsLimit = 6000337,
  RoomModuleRoomMemberAttributesCommonError = 6000350,
  RoomModuleTheTotalLengthOfRoomMemberAttributesExceedsLimit = 6000351,
  RoomModuleTheLengthOfRoomMemberAttributesKeyExceedsLimit = 6000352,
  RoomModuleTheLengthOfRoomMemberAttributesValueExceedsLimit = 6000353,
  RoomModuleTheMemberNumberOfRoomMemberAttributesExceedsLimit = 6000357,
  GroupModuleCommonError = 6000501,
  GroupModuleServerError = 6000502,
  GroupModuleCreateGroupError = 6000503,
  GroupModuleDismissGroupError = 6000504,
  GroupModuleJoinGroupError = 6000505,
  GroupModuleLeaveGroupError = 6000506,
  GroupModuleKickoutGroupMemberError = 6000507,
  GroupModuleInviteUserIntoGroupError = 6000508,
  GroupModuleTransferOwnerError = 6000509,
  GroupModuleUpdateGroupInfoError = 6000510,
  GroupModuleQueryGroupInfoError = 6000511,
  GroupModuleGroupAttributesOperationFailed = 6000512,
  GroupModuleGroupAttributesQueryFailed = 6000513,
  GroupModuleUpdateGroupMemberInfoError = 6000514,
  GroupModuleQueryGroupMemberInfoError = 6000515,
  GroupModuleQueryGroupListError = 6000516,
  GroupModuleQueryGroupMemberListError = 6000517,
  GroupModuleUserIsNotInTheGroup = 6000521,
  GroupModuleMemberIsAlreadyInTheGroup = 6000522,
  GroupModuleGroupDoesNotExist = 6000523,
  GroupModuleGroupAlreadyExists = 6000524,
  GroupModuleGroupMemberHasReachedLimit = 6000525,
  GroupModuleGroupAttributeDoesNotExist = 6000526,
  GroupModuleTheNumberOfGroupAttributesExceedsLimit = 6000531,
  GroupModuleTheLengthOfGroupAttributeKeyExceedsLimit = 6000532,
  GroupModuleTheLengthOfGroupAttributeValueExceedsLimit = 6000533,
  GroupModuleTheTotalLengthOfGroupAttributesValueExceedsLimit = 6000534,
  GroupModuleNoCorrespondingOperationAuthority = 6000541
}
