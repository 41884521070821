// 高德地图加载
import VueAMap, { initAMapApiLoader } from '@vuemap/vue-amap'
import '@vuemap/vue-amap/dist/style.css'
import { App } from 'vue'

initAMapApiLoader({
  key: 'cb94e44083a5890877edaaddac0ef795',
  securityJsCode: '8cdb3dabd25da57d48a7789c7777b4fb', // 新版key需要配合安全密钥使用
  //Loca:{
  //  version: '2.0.0'
  //} // 如果需要使用loca组件库，需要加载Loca
})

export function installAmap(app: App<Element>) {
  app.use(VueAMap)
}
