import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { reactive } from 'vue'
import router from '../router'
import { showToast } from 'vant'
import { merchantCode } from '@/config'
import { useUserStore } from '@/stores/index'
import { storage } from '@/utils/storageUtil'
import { KEY_Authorization } from '@/utils/constant'
import { useGlobalProperties } from '@/hook/useGlobalProperties'
// import { renewToken } from '@/utils/zg_utils'

// 定义请求响应数据结构
interface ResponseData<T> {
  currentTime: number
  data: T
  resultCode: number
  resultMsg: string
}

// 定义默认请求配置
const defaultConfig: AxiosRequestConfig = {
  // baseURL: 'http://190.92.202.235:82/',
  baseURL: import.meta.env.VITE_BASE_URL,
  timeout: 300000,
}

// 创建 axios 实例
const instance: AxiosInstance = axios.create(defaultConfig)

// 请求拦截器
instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const Authorization = storage.get(KEY_Authorization)
    // console.log('request 拦截器', config, Authorization)
    if (Authorization) {
      config.headers['Authorization'] = Authorization as string
    }
    // 接口返回数据国际化
    const lang = localStorage.getItem('lang')
    config.headers['Accept-Language'] = lang || 'zh'
    config.headers['X-Merchant'] = merchantCode
    return config
  },
  (error: any) => {
    return Promise.reject(error)
  },
)

// 响应拦截器
instance.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    const userStore = useUserStore()
    // console.log('response 拦截器', response)
    if (response.data && response.data.resultCode === 401) {
      if (
        response.request.responseURL.indexOf('video/pay/compilations/info') <=
        -1
      ) {
        response.data.resultMsg && showToast(response.data.resultMsg)
      }

      storage.set(KEY_Authorization, null)
      userStore.setLoginState(false)
      userStore.loginOut()
      // 过期就过期嘛，不跳转
      //location.replace('/')
      return Promise.reject(response.data.resultMsg)
    }
    return response.data
  },
  (error: any) => {
    return Promise.reject(error)
  },
)

// 封装请求方法
export function request<T>(
  config: AxiosRequestConfig,
): Promise<ResponseData<T>> {
  return instance
    .request<ResponseData<T>>(config)
    .then((response: AxiosResponse<ResponseData<T>>) => {
      // console.log('response', response)
      return response as unknown as ResponseData<T>
    })
    .catch((error: any) => {
      return Promise.reject(error)
    })
}

export function get<T>(
  url: string,
  params?: any,
  options?: object,
): Promise<ResponseData<T>> {
  return request<T>({
    url,
    method: 'GET',
    params,
    ...options,
  })
}

export function post<T>(
  url: string,
  data: any,
  options?: object,
): Promise<ResponseData<T>> {
  return request<T>({
    url,
    method: 'POST',
    data,
    ...options,
  })
}

// 使用响应式对象保存 loading 状态
export const loading = reactive({ status: false })

// 导出 request 和 loading 对象
export default { request, get, post, loading }
