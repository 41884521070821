import { userShield } from '@/api/user'
import { merchantCode } from '@/config/index'

/**
 * 登入人屏蔽用户
 */
export default function useUserShield() {

  /**
   * 屏蔽用户
   * @param toUserId 操作的用户
   * @param type true 是屏蔽 false 是移除
   */
  function shield(toUserId: string, type: boolean) {
    return userShield({
      merchantCode,
      toUserId,
      type: type ? 0 : 1 // 0 是屏蔽 1 是移除
    }).then(res => {
      res?.resultMsg && showToast(res.resultMsg)
      if (res?.resultCode === 1) {
        return res.data || true
      }
    })
  }

  return {
    shield
  }
}
